import { Subject } from "rxjs";
import apiResponse from "../../../../services/apiResponse";
import ClassificationRepository from "../Repositories/ClassificationRepository";

class ClassificationBloc {
  classificationChannel = new Subject();
  repository = new ClassificationRepository();

  constructor() {
    this.classificationChannel.next({ status: apiResponse.INITIAL });
  }

  updateClassification = async (query) => {
    this.classificationChannel.next({ status: apiResponse.LOADING });
    await this.repository
      .updateClassification(query)
      .then((result) => {
        this.classificationChannel.next({
          status: apiResponse.COMPLETED,
          data: result,
        });
      })
      .catch((error) => {
        this.classificationChannel.next({ status: apiResponse.ERROR, data: error });
      });
  };
}

export default ClassificationBloc;
