import React, { Fragment, Component } from "react";
import { withRouter } from "react-router";
import {
  Col,
  Container,
  Row,
  Spinner,
  Card,
  CardBody,
  Button,
} from "reactstrap";
import IndexBloc from "../Blocs/IndexBloc";
import { Pie } from "react-chartjs-2";
import apiResponse from "../../../../../services/apiResponse";
import Select from "react-select";

class IndexView extends Component {
  indexBloc = new IndexBloc();

  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.state = {
      data: null,
      loading: false,
      diskOption: [],
      selectedGraphId: "",
      hoveredDesc: false,
      errorMessage: null,
    };
  }

  loadData() {
    this.setState({ loading: true });

    this.indexBloc.getAllSpaceUtilizationChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          let response = result.data.response;
          let errormessage = result.data.message;
          const diskOptions = Array.isArray(response)
            ? Array.from(
                new Set(
                  response.flatMap((item) =>
                    item.graphs.map((graph) => graph.name)
                  )
                )
              ).map((name) => ({ value: name, label: name }))
            : [];

          const { selectedHost } = this.props;
          const hostId = selectedHost.value;

          const specificHost = Array.isArray(response)
            ? response.find((item) => item.hostid === hostId)
            : null;

          const graphNames =
            specificHost && Array.isArray(specificHost.graphs)
              ? specificHost.graphs.map((graph) => graph.name)
              : [];

          const filteredDiskOptions = diskOptions.filter((option) =>
            graphNames.includes(option.label)
          );

          const firstFilteredDiskOption =
            filteredDiskOptions.length > 0 ? filteredDiskOptions[0] : null;

          this.setState({
            data: response,
            diskOption: filteredDiskOptions,
            selectedGraphId: firstFilteredDiskOption,
            loading: false,
            errorMessage: errormessage,
          });
          break;
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            errorMessage: "Failed to load data. Please try again.",
          });
          break;
        default:
          break;
      }
    });

    this.indexBloc.fetchAllGraphSpaceUtilizationAll({});
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    const { selectedHost } = this.props;
    if (prevProps.selectedHost.value !== selectedHost.value) {
      this.loadData();
    }
  }

  handleGraphIdChange = (selectedOption) => {
    this.setState({ selectedGraphId: selectedOption });
  };

  createChart() {
    const { selectedGraphId } = this.state;
    const { selectedHost } = this.props;

    const defaultChartData = {
      labels: [],
      datasets: [],
    };

    if (!selectedHost) {
      return defaultChartData;
    }

    const hostId = selectedHost.value;

    const specificHost = Array.isArray(this.state.data)
      ? this.state.data.find((item) => item.hostid === hostId)
      : null;

    const graphs =
      specificHost && Array.isArray(specificHost.graphs)
        ? specificHost.graphs
        : [];

    const getGraphIdFromSelected = (selected) => {
      if (!selected || !selected.value) return null;

      const foundGraph = graphs.find((graph) => graph.name === selected.value);
      return foundGraph ? foundGraph.graphid : null;
    };

    const graphId = getGraphIdFromSelected(selectedGraphId);

    const graphItems =
      specificHost && Array.isArray(specificHost.graphs)
        ? specificHost.graphs
            .filter((graph) => graph.graphid === graphId)
            .flatMap((graph) => graph.items)
        : [];

    // let availableSpace = 0;
    let usedSpace = 0;
    let totalSpace = 0;

    // let availableLabel = "";
    let usedLabel = "";
    let totalLabel = "";

    // let availableColor = "";
    let usedColor = "";
    let totalColor = "";

    graphItems.forEach((item) => {
      switch (item.name) {
        case "/: Used space":
          usedSpace = parseFloat(item.last);
          usedLabel = `${item.name} (GB)`;
          usedColor = "rgba(0, 131, 0, 0.8)";
          break;
        case "/: Total space":
          totalSpace = parseFloat(item.last);
          totalLabel = `${item.name} (GB)`;
          totalColor = "rgba(176, 0, 0, 0.8)";
          break;
        case "/boot: Used space":
          usedSpace = parseFloat(item.last);
          usedLabel = `${item.name} (GB)`;
          usedColor = "rgba(176, 0, 0, 0.8)";
          break;
        case "/boot: Total space":
          totalSpace = parseFloat(item.last);
          totalLabel = `${item.name} (GB)`;
          totalColor = "rgba(0, 131, 0, 0.8)";
          break;
        // case "FS [/]: Space: Available":
        //   availableSpace = parseFloat(item.last);
        //   availableLabel = `${item.name} (GB)`;
        //   availableColor = "rgba(0, 131, 0, 0.8)";
        //   break;
        // case "FS [/]: Space: Used":
        //   usedSpace = parseFloat(item.last);
        //   usedLabel = `${item.name} (GB)`;
        //   usedColor = "rgba(176, 0, 0, 0.8)";
        //   break;
        // case "FS [/]: Space: Total":
        //   totalSpace = parseFloat(item.last);
        //   totalLabel = `${item.name} (GB)`;
        //   totalColor = "rgba(0, 0, 0, 0.53)";
        //   break;
        // case "FS [/boot]: Space: Available":
        //   availableSpace = parseFloat(item.last);
        //   availableLabel = `${item.name} (GB)`;
        //   availableColor = "rgba(0, 131, 0, 0.8)";
        //   break;
        // case "FS [/boot]: Space: Used":
        //   usedSpace = parseFloat(item.last);
        //   usedLabel = `${item.name} (GB)`;
        //   usedColor = "rgba(176, 0, 0, 0.8)";
        //   break;
        // case "FS [/boot]: Space: Total":
        //   totalSpace = parseFloat(item.last);
        //   totalLabel = `${item.name} (GB)`;
        //   totalColor = "rgba(0, 0, 0, 0.53)";
        //   break;
        // case "FS [DATA(D:)]: Space: Available":
        //   availableSpace = parseFloat(item.last);
        //   availableLabel = `${item.name} (GB)`;
        //   availableColor = "rgba(0, 131, 0, 0.8)";
        //   break;
        // case "FS [DATA(D:)]: Space: Used":
        //   usedSpace = parseFloat(item.last);
        //   usedLabel = `${item.name} (GB)`;
        //   usedColor = "rgba(176, 0, 0, 0.8)";
        //   break;
        // case "FS [DATA(D:)]: Space: Total":
        //   totalSpace = parseFloat(item.last);
        //   totalLabel = `${item.name} (GB)`;
        //   totalColor = "rgba(0, 0, 0, 0.53)";
        //   break;
        // case "FS [OS(C:)]: Space: Available":
        //   availableSpace = parseFloat(item.last);
        //   availableLabel = `${item.name} (GB)`;
        //   availableColor = "rgba(0, 131, 0, 0.8)";
        //   break;
        // case "FS [OS(C:)]: Space: Used":
        //   usedSpace = parseFloat(item.last);
        //   usedLabel = `${item.name} (GB)`;
        //   usedColor = "rgba(176, 0, 0, 0.8)";
        //   break;
        // case "FS [OS(C:)]: Space: Total":
        //   totalSpace = parseFloat(item.last);
        //   totalLabel = `${item.name} (GB)`;
        //   totalColor = "rgba(0, 0, 0, 0.53)";
        //   break;
        // case "FS [(C:)]: Space: Available":
        //   availableSpace = parseFloat(item.last);
        //   availableLabel = `${item.name} (GB)`;
        //   availableColor = "rgba(0, 131, 0, 0.8)";
        //   break;
        // case "FS [(C:)]: Space: Used":
        //   usedSpace = parseFloat(item.last);
        //   usedLabel = `${item.name} (GB)`;
        //   usedColor = "rgba(176, 0, 0, 0.8)";
        //   break;
        // case "FS [(C:)]: Space: Total":
        //   totalSpace = parseFloat(item.last);
        //   totalLabel = `${item.name} (GB)`;
        //   totalColor = "rgba(0, 0, 0, 0.53)";
        //   break;
        default:
          totalSpace = parseFloat(item.last);
          totalLabel = `${item.name} (GB)`;
          totalColor = "rgba(0, 0, 0, 0.53)";
          break;
      }
    });

    return {
      // labels: [availableLabel, usedLabel, totalLabel],
      labels: [usedLabel, totalLabel],
      datasets: [
        {
          // data: [availableSpace, usedSpace, totalSpace],
          // backgroundColor: [availableColor, usedColor, totalColor],
          // hoverBackgroundColor: [availableColor, usedColor, totalColor],
          data: [usedSpace, totalSpace],
          backgroundColor: [usedColor, totalColor],
          hoverBackgroundColor: [usedColor, totalColor],
        },
      ],
    };
  }

  renderDiskInfo = () => {
    const { selectedGraphId } = this.state;
    const { selectedHost } = this.props;

    const hostId = selectedHost.value;

    const specificHost = Array.isArray(this.state.data)
      ? this.state.data.find((item) => item.hostid === hostId)
      : null;

    const graphs =
      specificHost && Array.isArray(specificHost.graphs)
        ? specificHost.graphs
        : [];

    const getGraphIdFromSelected = (selected) => {
      if (!selected || !selected.value) return null;

      const foundGraph = graphs.find((graph) => graph.name === selected.value);
      return foundGraph ? foundGraph.graphid : null;
    };

    const graphId = getGraphIdFromSelected(selectedGraphId);

    const graphItems =
      specificHost && Array.isArray(specificHost.graphs)
        ? specificHost.graphs
            .filter((graph) => graph.graphid === graphId)
            .flatMap((graph) => graph.items)
        : [];

    if (graphItems.length === 0) {
      return <div>No Space information available for the selected host.</div>;
    }

    return (
      <div>
        <div>
          <div style={{ fontWeight: "bold", fontSize: "20px" }}>
            Informasi{" "}
            <span
              className="uil-info-circle"
              onMouseEnter={() => this.setState({ hoveredDesc: true })}
              onMouseLeave={() => this.setState({ hoveredDesc: false })}
              style={{ cursor: "pointer" }}
            />
          </div>
          <br />
        </div>
        {this.state.hoveredDesc && (
          <div
            style={{
              position: "absolute",
              background: "#f4f4f4",
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "10px",
              zIndex: 1000,
              width: "900px",
              boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
            }}
          >
            {this.renderDescDescriptions()}
          </div>
        )}

        {graphItems.map((item, index) => (
          <div key={index}>
            <strong>{item.name}</strong> : <strong>Nilai:</strong>{" "}
            {parseFloat(item.last) !== null
              ? `${parseFloat(item.last)} GB`
              : "0.00 GB"}
          </div>
        ))}
      </div>
    );
  };

  renderDescDescriptions = () => {
    const { selectedGraphId } = this.state;
    const { selectedHost } = this.props;

    const hostId = selectedHost.value;

    const specificHost = Array.isArray(this.state.data)
      ? this.state.data.find((item) => item.hostid === hostId)
      : null;

    const graphs =
      specificHost && Array.isArray(specificHost.graphs)
        ? specificHost.graphs
        : [];

    const getGraphIdFromSelected = (selected) => {
      if (!selected || !selected.value) return null;

      const foundGraph = graphs.find((graph) => graph.name === selected.value);
      return foundGraph ? foundGraph.graphid : null;
    };

    const graphId = getGraphIdFromSelected(selectedGraphId);

    const graphItems =
      specificHost && Array.isArray(specificHost.graphs)
        ? specificHost.graphs
            .filter((graph) => graph.graphid === graphId)
            .flatMap((graph) => graph.items)
        : [];

    const desc = [
      {
        description:
          "Jumlah kapasitas penyimpanan atau ruang disk yang saat ini masih kosong dan belum terpakai.",
      },
      {
        description:
          "Jumlah kapasitas penyimpanan atau ruang disk yang sudah digunakan oleh file, aplikasi, dan data sistem.",
      },
      {
        description:
          "Kapasitas penyimpanan keseluruhan atau ukuran total dari disk atau sistem penyimpanan.",
      },
    ];

    return (
      <div>
        {graphItems.map((item, index) => (
          <div key={index}>
            <strong>{item.name}:</strong> {desc[index]?.description}
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { loading, errorMessage } = this.state;
    return (
      <Fragment>
        <Container fluid>
          {loading ? (
            <div className="text-center">
              <div>
                <Spinner
                  style={{
                    width: "3rem",
                    height: "3rem",
                    borderWidth: "0.4rem",
                  }}
                  color="dark"
                />
              </div>
              <div>
                <span style={{ fontWeight: "bold" }}>Mengambil Data ...</span>
              </div>
            </div>
          ) : errorMessage ? (
            <Card className="text-center">
              <br />
              <CardBody>
                <h5 className="text-dark">{errorMessage}</h5>
                <br />
                <Button color="danger" onClick={this.loadData}>
                  Muat Ulang
                </Button>
              </CardBody>
            </Card>
          ) : (
            <div>
              <Row>
                <Col md="3">
                  <label>Pilih Disk : </label>
                  <br />
                  <Select
                    options={this.state.diskOption}
                    placeholder="Pilih Penyimpanan"
                    onChange={this.handleGraphIdChange}
                    value={this.state.selectedGraphId}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col className="col-12">
                  <Pie data={this.createChart()} />
                </Col>
              </Row>
              <Row>
                <hr />
                <Col className="col-12">{this.renderDiskInfo()}</Col>
              </Row>
            </div>
          )}
        </Container>
      </Fragment>
    );
  }
}

export default withRouter(IndexView);
