import { AvCheckbox, AvCheckboxGroup, AvForm, AvField } from "availity-reactstrap-validation";
import React, { Component, Fragment } from 'react';
import { Link, Redirect } from "react-router-dom";
import {
  Button, Card, CardBody, CardSubtitle, CardTitle, Col, Container,
  Row
} from "reactstrap";
import { ConfirmAlert } from '../../../helpers/ui/alert';
import apiResponse from '../../../services/apiResponse';
import CreateBloc from './Blocs/CreateBloc';

class IndexView extends Component {

  createBloc = new CreateBloc();

  constructor(props) {
    super(props)
    this.state = {
      dataForm: {},
    }
  }

  handleServiceChange = (e) => {
    this.setState({
      dataForm: {
        ...this.state.dataForm,
        ["helpdesk_service_name"]: e.value,
      },
    });
  };

  submitData = async () => {
    await this.createBloc.fetchCreate(this.state.dataForm)
  }

  handlerOnSubmit = (e, values) => {
    e.preventDefault()
    this.setState({
      dataForm: values,
      confirm: true
    })
  }

  confirmResponse = (response) => {
    let forState = {}
    switch (response) {
      case 'cancel':
        forState = {
          confirm: false
        }
        break;
      case 'confirm':
        forState = {
          loading: true,
          confirm: false
        }
        this.submitData()
        break;
      case 'success':
        forState = {
          success: false,
          redirect: true
        }
        break;
      case 'failed':
        forState = {
          failed: false
        }
        break;
      default:
    }
    this.setState(forState)
  }

  componentDidMount() {
    this.createBloc.createChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState({
            loading: false,
            success: true,
          });
          break
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            failed: true,
          });
          break
        default:
          break
      }
    })
  }

  componentWillUnmount() {
    this.createBloc.createChannel.unsubscribe();
  }

  render() {
    return (
      <Fragment>
        <Container fluid>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>Buat Data Master Layanan</CardTitle>
                  <CardSubtitle className="mb-3">
                    Buat data master layanan baru dengan mengisi form di bawah ini
                  </CardSubtitle>
                  <hr />
                  <AvForm
                    className="needs-validation"
                    onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                  >
                    <Row className="mb-3">
                      <label
                        htmlFor="fielCode"
                        className="col-md-3 col-form-label"
                      >
                        Nama Layanan
                      </label>
                      <Col md={9}>
                        <AvField
                          name="helpdesk_service_name"
                          placeholder="Nama Layanan"
                          errorMessage="Masukkan nama layanan"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="helpdesk_service_name"
                          onChange={this.handleServiceChange}
                        />
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col md="3"></Col>
                      <Col md="9">
                        <div className="form-check form-switch mb-3">
                          <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                            <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                          </AvCheckboxGroup>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md="3"></Col>
                      <Col md="9">
                        <Link to="/admin/helpdesk_service.html">
                          <Button color="danger"><i className="uil-arrow-left"></i> Kembali</Button>
                        </Link>
                        {' '}
                        <Button color="success" type="submit">
                          <i className="uil-arrow-up-right"></i> Simpan
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan menyimpan data ini?"

          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"

          successTitle="Berhasil!"
          successInfo="Data berhasil disimpan"

          failedTitle="Gagal!"
          failedInfo="Data gagal disimpan"

          showConfirm={this.state.confirm}
          showLoading={this.state.loading}
          showSuccess={this.state.success}
          showFailed={this.state.failed}

          response={this.confirmResponse}
        />
        {this.state.redirect ?
          <Redirect to='/admin/helpdesk_service.html' />
          : null}
      </Fragment>
    )
  }

}

export default IndexView