import { Subject } from "rxjs";
import apiResponse from "../../../../services/apiResponse";
import UpdateRepository from "../Repositories/UpdateRepository";

class UpdateBloc {
  updateChannel = new Subject();
  detailChannel = new Subject();
  servicesChannel = new Subject();
  repository = new UpdateRepository();

  constructor() {
    this.updateChannel.next({ status: apiResponse.INITIAL });
    this.detailChannel.next({ status: apiResponse.INITIAL });
    this.servicesChannel.next({ status: apiResponse.INITIAL });
  }

  fetchUpdate = async (query) => {
    this.updateChannel.next({ status: apiResponse.LOADING });
    await this.repository
      .fetchUpdate(query)
      .then((result) => {
        this.updateChannel.next({
          status: apiResponse.COMPLETED,
          data: result,
        });
      })
      .catch((error) => {
        this.updateChannel.next({ status: apiResponse.ERROR, data: error });
      });
  };

  fetchDetail = async (query) => {
    await this.repository
      .fetchDetail(query)
      .then((result) => {
        this.detailChannel.next({
          status: apiResponse.COMPLETED,
          data: result,
        });
      })
      .catch((error) => {
        this.detailChannel.next({ status: apiResponse.ERROR, data: error });
      });
  };

  fetchServices = async (query, cancelToken) => {
    this.servicesChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchServices(query, cancelToken)
        .then((result) => {
          this.servicesChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.servicesChannel.next({ status: apiResponse.ERROR, data: error });
        });
    } catch (error) {}
  };
}

export default UpdateBloc;
