import {
  apiRequest,
  ticketUpdateClassification,
} from "../../../../services/adapters/base";

class ClassificationRepository {
  updateClassification = async (query) => {
    return await apiRequest(
      "post",
      ticketUpdateClassification,
      {
        body: query,
      },
      true
    );
  };
}

export default ClassificationRepository;
