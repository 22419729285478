import React, { Component, Fragment } from "react";
import { config } from "../../../../../helpers/config_global";
import MKIDatatable from "../../../../../modules/mki-datatable/lib";
import { cancelToken } from "../../../../../services/adapters/base";
import apiResponse from "../../../../../services/apiResponse";
import DataTableBloc from "../Blocs/DataTableBloc";
import "../style.scss";
import { Alert } from "reactstrap";

const formatDateTime = (timestamp) => {
  const optionsDate = { year: "numeric", month: "long", day: "numeric" };
  const date = new Date(timestamp);
  const formattedDate = date.toLocaleDateString("id-ID", optionsDate);
  const hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${formattedDate} - ${hours}:${minutes}`;
};

class TableComponent extends Component {
  dataTableBloc = new DataTableBloc();

  source;
  historyTable = "historyConditionSystemCategory";
  defaultOrder = "hostid";
  defaultSize = 10;
  defaultSort = "asc";
  configDatatable = {
    ...config("datatable"),
    sort: {
      column: this.defaultOrder,
      order: this.defaultSort,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      tooltip: false,
      detailId: null,
      updateId: null,
      deleteId: null,
      notulenUuid: null,
      defaultSize: 10,
      rows: [],
      totalRow: 0,
      data: [],
      alerts: [],
      blinkAlerts: true,
      currentAlertIndex: 0,
      hoveredRow: null,
      loadingTable: true,
      filter: {
        filter_value: "",
        page_number: 1,
        page_size: this.defaultSize,
        sort_order: {
          column: this.defaultOrder,
          order: this.defaultSort,
        },
      },
    };
  }
  toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

  loadData = async () => {
    /* history Datatable */
    sessionStorage.setItem(
      this.historyTable,
      JSON.stringify(this.state.filter)
    );

    /* kirim ke server */
    let offset =
      (this.state.filter.page_number - 1) * this.state.filter.page_size;
    if (this.state.defaultSize !== this.state.filter.page_size) {
      this.setState({
        defaultSize: this.state.filter.page_size,
      });
      offset = 0;
      this.configDatatable.page_number = 1;
    }
    const query = {
      search: this.state.filter.filter_value,
      limit: this.state.filter.page_size,
      offset: offset,
      order: this.state.filter.sort_order.column,
      sort: this.state.filter.sort_order.order,
      status: 1,
    };
    await this.dataTableBloc.fetchAllHosts(query, this.source.token);
  };

  setTokenAPI = () => {
    if (typeof this.source != typeof undefined) {
      this.source.cancel();
    }
    this.source = cancelToken();
    this.loadData();
  };

  confirmResponse = (response) => {
    let forState = {};
    switch (response) {
      case "confirm":
        forState = {
          loading: true,
          confirm: false,
        };
        this.deleteService();
        break;
      case "success":
        forState = {
          success: false,
          redirect: true,
        };
        break;
      case "failed":
        forState = {
          failed: false,
        };
        break;
      case "cancel":
        forState = {
          confirm: false,
        };
        break;
      default:
    }
    this.setState(forState);
  };

  componentDidMount() {
    this.setTokenAPI();

    this.dataTableBloc.getAllHostsChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.INITIAL:
          this.setState({
            loadingTable: false,
            alerts: [],
          });
          break;
        case apiResponse.LOADING:
          this.setState({
            loadingTable: true,
          });
          break;
        case apiResponse.COMPLETED:
          const response = result.data.response;
          const alerts = response.flatMap((host) =>
            host.problems.map((problem) => ({
              name: host.name,
              description: problem.description,
              timestamp: problem.timestamp,
              priority: problem.priority,
            }))
          );

          this.setState({ alerts }, () => {
            this.displayAlertsOneByOne();
          });

          const rows = response.map((host) => {
            return {
              namaServer: host.name,
              deskripsiMasalah:
                host.problems.length > 0 ? host.problems[0].description : "-",
              statusPrioritas:
                host.problems.length > 0 ? host.problems[0].priority : "N/A",
              timestampPrioritas:
                host.problems.length > 0 ? host.problems[0].timestamp : "-",
            };
          });
          this.setState({
            data: rows,
            alerts: alerts,
            loadingTable: false,
            blinkAlerts: true,
          });

          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });
  }

  componentWillUnmount() {
    this.dataTableBloc.getAllHostsChannel.unsubscribe();
  }

  handleMouseEnter = (index, statusPrioritas) => {
    this.setState({ hoveredRow: index, hoveredStatus: statusPrioritas });
  };

  handleMouseLeave = () => {
    this.setState({ hoveredRow: null, hoveredStatus: null });
  };

  renderPriorityDescriptions = () => {
    const { hoveredStatus } = this.state;

    const priorities = [
      {
        level: "Kritis",
        description:
          "Masalah sangat serius yang berdampak besar dan membutuhkan tindakan segera.",
        color: "#ff0000",
      },
      {
        level: "Tinggi",
        description:
          "Masalah penting yang perlu segera diselesaikan untuk mencegah dampak buruk lebih lanjut.",
        color: "#ff5600",
      },
      {
        level: "Sedang",
        description:
          "Masalah yang tidak terlalu mendesak, tapi tetap perlu ditangani dalam waktu yang wajar.",
        color: "#ff8e00",
      },
      {
        level: "Peringatan",
        description:
          "Masalah potensial yang mungkin terjadi jika tidak diambil tindakan pencegahan.",
        color: "#ffc700",
      },
      {
        level: "Informasi",
        description:
          "Hanya untuk memberi tahu, tidak memerlukan tindakan langsung.",
        color: "#007aff",
      },
      {
        level: "Belum Diklasifikasikan",
        description: "Masalah yang belum dipastikan tingkat prioritasnya.",
        color: "#acadac",
      },
      {
        level: "Aman",
        description: "Tidak ada masalah yang teridentifikasi.",
        color: "#1bb81e",
      },
    ];

    if (hoveredStatus !== null) {
      const priority = priorities.find(
        (p) => p.level === this.getPriorityLevel(hoveredStatus)
      );
      if (priority) {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              marginBottom: "10px",
              justifyContent: "flex-start",
            }}
          >
            <div
              className="badge"
              style={{
                backgroundColor: priority.color,
                padding: 5,
                marginRight: "10px",
                color: "white",
              }}
            >
              <span style={{ fontSize: 12 }}>{priority.level}</span>
            </div>
            <div>
              <p style={{ margin: 0 }}>: {priority.description}</p>
            </div>
          </div>
        );
      }
    }
    return null; // Return null if no hovered status
  };

  getPriorityLevel = (statusPrioritas) => {
    switch (statusPrioritas) {
      case "5":
        return "Kritis";
      case "4":
        return "Tinggi";
      case "3":
        return "Sedang";
      case "2":
        return "Peringatan";
      case "1":
        return "Informasi";
      case "0":
        return "Belum Diklasifikasikan";
      default:
        return "Aman";
    }
  };

  columns = [
    {
      key: "namaServer",
      text: "Nama Server",
      className: "namaServer",
      sortable: true,
    },
    {
      key: "deskripsiMasalah",
      text: "Deskripsi Masalah",
      className: "deskripsiMasalah",
      sortable: false,
    },
    {
      key: "timestampPrioritas",
      text: "Tanggal Masalah",
      className: "timestampPrioritas",
      sortable: false,
      cell: (record) => {
        if (record.timestampPrioritas === "-") {
          return "-";
        }
        return formatDateTime(record.timestampPrioritas);
      },
    },
    {
      key: "statusPrioritas",
      text: "Status Prioritas",
      className: "statusPrioritas",
      align: "center",
      sortable: false,
      cell: (record) => {
        if (record.statusPrioritas === "5") {
          return (
            <div
              className="badge"
              style={{
                backgroundColor: "#ff0000",
                padding: 5,
                marginBottom: "5px",
                marginRight: "5px",
                color: "white",
              }}
            >
              <span style={{ fontSize: 12 }}>Kritis</span>
            </div>
          );
        } else if (record.statusPrioritas === "4") {
          return (
            <div
              className="badge"
              style={{
                backgroundColor: "#ff5600",
                padding: 5,
                marginBottom: "5px",
                marginRight: "5px",
                color: "white",
              }}
            >
              <span style={{ fontSize: 12 }}>Tinggi</span>
            </div>
          );
        } else if (record.statusPrioritas === "3") {
          return (
            <div
              className="badge"
              style={{
                backgroundColor: "#ff8e00",
                padding: 5,
                marginBottom: "5px",
                marginRight: "5px",
                color: "white",
              }}
            >
              <span style={{ fontSize: 12 }}>Sedang</span>
            </div>
          );
        } else if (record.statusPrioritas === "2") {
          return (
            <div
              className="badge"
              style={{
                backgroundColor: "#ffc700",
                padding: 5,
                marginBottom: "5px",
                marginRight: "5px",
                color: "white",
              }}
            >
              <span style={{ fontSize: 12 }}>Peringatan</span>
            </div>
          );
        } else if (record.statusPrioritas === "1") {
          return (
            <div
              className="badge"
              style={{
                backgroundColor: "#007aff",
                padding: 5,
                marginBottom: "5px",
                marginRight: "5px",
                color: "white",
              }}
            >
              <span style={{ fontSize: 12 }}>Informasi</span>
            </div>
          );
        } else if (record.statusPrioritas === "0") {
          return (
            <div
              className="badge"
              style={{
                backgroundColor: "#acadac",
                padding: 5,
                marginBottom: "5px",
                marginRight: "5px",
                color: "white",
              }}
            >
              <span style={{ fontSize: 12 }}>Belum Diklasifikasikan</span>
            </div>
          );
        } else {
          return (
            <div
              className="badge"
              style={{
                backgroundColor: "#1bb81e",
                padding: 5,
                marginBottom: "5px",
                marginRight: "5px",
                color: "white",
              }}
            >
              <span style={{ fontSize: 12 }}>Aman</span>
            </div>
          );
        }
      },
    },
    {
      text: "Info",
      align: "center",
      sortable: false,
      cell: (record, index) => {
        return (
          <div
            onMouseEnter={() =>
              this.handleMouseEnter(index, record.statusPrioritas)
            }
            onMouseLeave={this.handleMouseLeave}
            style={{ position: "relative" }}
          >
            <span
              className="uil-info-circle"
              style={{ fontSize: "20px" }}
            ></span>
            {this.state.hoveredRow === index && (
              <div
                style={{
                  position: "absolute",
                  backgroundColor: "#f4f4f4",
                  border: "1px solid #ccc",
                  borderRadius: "8px",
                  padding: "10px",
                  zIndex: 1,
                  width: "680px",
                  left: "-690px",
                  top: "0",
                }}
              >
                {/* <div>
                  <p style={{ fontWeight: "bold" }}>Informasi :</p>
                </div> */}
                {this.renderPriorityDescriptions()}
              </div>
            )}
          </div>
        );
      },
    },
  ];

  // {
  //   key: "action",
  //   text: "Action",
  //   className: "action",
  //   width: 150,
  //   sortable: false,
  //   align: "center",
  //   cell: (record) => {
  //     const thisId = record.hostid;
  //     return (
  //       <Fragment>
  //         <span>
  //           <button
  //             className="btn btn-primary btn-sm"
  //             id={"detail-" + thisId}
  //             onClick={() => {
  //               this.setState({
  //                 detailId: record.hostid,
  //               });
  //             }}
  //             style={{
  //               marginRight: "2px",
  //               marginBottom: "2px",
  //               padding: "2px 5px",
  //             }}
  //           >
  //             <i className="uil-align-left"></i>
  //           </button>
  //           <Tooltip
  //             style={{
  //               marginBottom: 2,
  //             }}
  //             placement="top"
  //             isOpen={this.state["detail" + thisId]}
  //             target={"detail-" + thisId}
  //             toggle={() => {
  //               this.setState({
  //                 ["detail" + thisId]: !this.state["detail" + thisId],
  //               });
  //             }}
  //           >
  //             Detail
  //           </Tooltip>
  //         </span>
  //       </Fragment>
  //     );
  //   },
  // },

  getAlertColor(priority) {
    switch (priority) {
      case "5":
      case "4":
      case "3":
        return "danger";
      case "2":
        return "warning";
      case "1":
        return "primary";
      case "0":
        return "secondary";
      default:
        return "success";
    }
  }

  displayAlertsOneByOne() {
    const { alerts } = this.state;
    alerts.forEach((alert, index) => {
      setTimeout(() => {
        this.setState({ currentAlertIndex: index + 1 });
      }, index * 300);
    });
  }

  render() {
    return (
      <>
        {this.state.alerts
          .slice(0, this.state.currentAlertIndex)
          .map((alert, index) => (
            <Alert
              key={index}
              className={this.state.blinkAlerts ? "blink" : ""}
              color={this.getAlertColor(alert.priority)}
            >
              <strong className="uil-exclamation-triangle">
                {" "}
                {alert.name}
              </strong>{" "}
              : {alert.description} (Tanggal :{" "}
              <strong>{formatDateTime(alert.timestamp)}</strong>)
            </Alert>
          ))}
        <MKIDatatable
          className={this.configDatatable.tableClass}
          config={this.configDatatable}
          records={this.state.data}
          columns={this.columns}
          minWidth={1000}
          dynamic={false}
          total_record={this.state.totalRow}
          initial={this.state.filter}
          onChange={(filter) => {
            this.setState(
              {
                loadingTable: true,
                filter: filter,
              },
              function () {
                this.setTokenAPI();
              }
            );
          }}
          loading={this.state.loadingTable}
        />
      </>
    );
  }
}

export default TableComponent;
