import { Fragment, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container, Row
} from "reactstrap";
import { accessRole } from "../../../helpers/access_role";
import TableComponent from "./Components/TableComponent";
import "./style.scss";

const IndexView = (props) => {
  const activeRef = useRef();
  const inactiveRef = useRef();

  const [deviceCount, setDeviceCount] = useState(0);
  
  function setCountCb(res, type) {
    setDeviceCount(res);
  }
  
  const renderTableView = () => {
    return (
      <>
        <TableComponent
          cb={setCountCb}
          ref={activeRef}
        />
      </>
    );
  };


  const renderView = () => {
    return renderTableView();
  };

  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <CardTitle>Master Device</CardTitle>
                <CardSubtitle className="mb-3">Data - data Device</CardSubtitle>
                <hr />
                {renderView()}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default IndexView;
