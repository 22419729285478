import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import SidebarContent from "./SidebarContent";

// import logoSm from "../../assets/images/logo-sm.png";
// import logoDark from "../../assets/images/logo-dark.png";
// import logoLight from "../../assets/images/logo-light.png";
import logoFull from "../../assets/images/logo-full-side.png";

const Sidebar = (props) => {
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/" className="logo logo-dark">
            {/* <span className="logo-sm" style={{ marginLeft: -9 }}>
              <img src={logoSm} alt="" height="26" />
            </span>
            <span className="logo-lg">
              <img src={logoDark} alt="" height="40" />
            </span> */}
            <span className="logo-full-side" style={{ marginLeft: -15 }}>
              <img src={logoFull} alt="" height="130" />
            </span>
          </Link>

          <Link to="/" className="logo logo-light">
            {/* <span className="logo-sm" style={{ marginLeft: -9 }}>
              <img src={logoSm} alt="" height="26" />
            </span>
            <span className="logo-lg">
              <img src={logoLight} alt="" height="40" />
            </span> */}
            <span className="logo-full-side" style={{ marginLeft: -15 }}>
              <img src={logoFull} alt="" height="130" />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100" style={{ marginTop: 35 }}>
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = (state) => {
  return {
    layout: state.Layout,
  };
};
export default connect(mapStatetoProps, {})(withRouter(Sidebar));
