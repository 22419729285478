import React, { Component, Fragment } from "react";
import { config } from "../../../../../helpers/config_global";
import MKIDatatable from "../../../../../modules/mki-datatable/lib";
import { cancelToken } from "../../../../../services/adapters/base";
import apiResponse from "../../../../../services/apiResponse";
import DataTableBloc from "../Blocs/DataTableBloc";
import { Tooltip, Modal, Alert } from "reactstrap";
import DetailLog from "../Components/DetailLogComponent";

// const formatDateTime = (timestamp) => {
//   const optionsDate = { year: "numeric", month: "long", day: "numeric" };
//   const date = new Date(timestamp);
//   const formattedDate = date.toLocaleDateString("id-ID", optionsDate);
//   const hours = date.getHours();
//   const minutes = String(date.getMinutes()).padStart(2, "0");
//   return `${formattedDate} - ${hours}:${minutes}`;
// };

class TableComponent extends Component {
  dataTableBloc = new DataTableBloc();

  source;
  historyTable = "historySchedulerCategory";
  defaultOrder = "cron_job_id";
  defaultSize = 10;
  defaultSort = "asc";
  configDatatable = {
    ...config("datatable"),
    sort: {
      column: this.defaultOrder,
      order: this.defaultSort,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      tooltip: false,
      detailId: null,
      updateId: null,
      deleteId: null,
      notulenUuid: null,
      defaultSize: 10,
      rows: [],
      totalRow: 0,
      data: [],
      showDetail: false,
      currentAlertIndex: 0,
      hoveredRow: null,
      loadingTable: true,
      filter: {
        filter_value: "",
        page_number: 1,
        page_size: this.defaultSize,
        sort_order: {
          column: this.defaultOrder,
          order: this.defaultSort,
        },
      },
    };
  }
  toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

  loadData = async () => {
    /* history Datatable */
    sessionStorage.setItem(
      this.historyTable,
      JSON.stringify(this.state.filter)
    );

    /* kirim ke server */
    let offset =
      (this.state.filter.page_number - 1) * this.state.filter.page_size;
    if (this.state.defaultSize !== this.state.filter.page_size) {
      this.setState({
        defaultSize: this.state.filter.page_size,
      });
      offset = 0;
      this.configDatatable.page_number = 1;
    }
    const query = {
      search: this.state.filter.filter_value,
      limit: this.state.filter.page_size,
      offset: offset,
      order: this.state.filter.sort_order.column,
      sort: this.state.filter.sort_order.order,
      status: 1,
    };
    await this.dataTableBloc.fetchAllCronJob(query, this.source.token);
  };

  setTokenAPI = () => {
    if (typeof this.source != typeof undefined) {
      this.source.cancel();
    }
    this.source = cancelToken();
    this.loadData();
  };

  confirmResponse = (response) => {
    let forState = {};
    switch (response) {
      case "confirm":
        forState = {
          loading: true,
          confirm: false,
        };
        this.deleteService();
        break;
      case "success":
        forState = {
          success: false,
          redirect: true,
        };
        break;
      case "failed":
        forState = {
          failed: false,
        };
        break;
      case "cancel":
        forState = {
          confirm: false,
        };
        break;
      default:
    }
    this.setState(forState);
  };

  componentDidMount() {
    this.setTokenAPI();

    this.dataTableBloc.getAllCronJobChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.INITIAL:
          this.setState({
            loadingTable: false,
            alerts: [],
          });
          break;
        case apiResponse.LOADING:
          this.setState({
            loadingTable: true,
          });
          break;
        case apiResponse.COMPLETED:
          const response = result.data.response;
          const rows = response.map((item) => {
            return {
              namaCronJob: item.job.cron_job_name,
              createCronJob: item.job.created,
              updateCronJob: item.job.updated,
              successCronJob: item.job.total_successes,
              failureCronJob: item.job.total_failures,
              cronJobId: item.job.cron_job_id,
              statusCronJob: item.job.status,
            };
          });

          this.setState({
            data: rows,
            loadingTable: false,
          });

          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });
  }

  componentWillUnmount() {
    this.dataTableBloc.getAllCronJobChannel.unsubscribe();
  }

  columns = [
    {
      key: "namaCronJob",
      text: "Nama Cron Job",
      className: "namaCronJob",
      sortable: true,
    },
    {
      key: "successCronJob",
      text: "Total Sukses",
      className: "successCronJob",
      sortable: true,
    },
    {
      key: "failureCronJob",
      text: "Total Gagal",
      className: "failureCronJob",
      sortable: true,
    },
    {
      key: "statusCronJob",
      text: "Status",
      className: "statusCronJob",
      sortable: true,
      cell: (record) => {
        if (record.statusCronJob === 1) {
          return (
            <div
              className="badge"
              style={{
                backgroundColor: "#06D001",
                padding: 5,
                marginBottom: "5px",
                marginRight: "5px",
                color: "white",
              }}
            >
              <span style={{ fontSize: 12 }}>Running</span>
            </div>
          );
        } else if (record.statusCronJob === 0) {
          return (
            <div
              className="badge"
              style={{
                backgroundColor: "#ff0000",
                padding: 5,
                marginBottom: "5px",
                marginRight: "5px",
                color: "white",
              }}
            >
              <span style={{ fontSize: 12 }}>Not Running</span>
            </div>
          );
        }
      },
    },
    {
      key: "action",
      text: "Action Log",
      className: "action",
      width: 150,
      sortable: false,
      align: "center",
      cell: (record) => {
        const thisId = record.cronJobId;
        return (
          <Fragment>
            <span>
              <button
                className="btn btn-primary btn-sm"
                id={"detail-" + thisId}
                onClick={() => {
                  this.setState({
                    detailId: record.cronJobId,
                    showDetail: true,
                  });
                }}
                style={{
                  marginRight: "2px",
                  padding: "2px 5px",
                }}
              >
                <i className="uil-align-left"></i>
              </button>
              <Tooltip
                style={{
                  marginBottom: 2,
                }}
                placement="top"
                isOpen={this.state["detail" + thisId]}
                target={"detail-" + thisId}
                toggle={() => {
                  this.setState({
                    ["detail" + thisId]: !this.state["detail" + thisId],
                  });
                }}
              >
                Detail Logs
              </Tooltip>
            </span>{" "}
          </Fragment>
        );
      },
    },
  ];

  render() {
    return (
      <>
        <Alert color="primary">
          <div style={{ fontWeight: "bold", fontSize: "18px" }}>Informasi</div>
          <br />
          <ul style={{ fontSize: "15px", paddingLeft: "20px" }}>
            <li>
              <strong>Total Sukses : </strong>
              Jumlah total dari eksekusi cron job yang berhasil dilakukan.
              Artinya, cron job tersebut telah dijadwalkan dan dijalankan sesuai
              dengan waktu yang ditentukan, dan tidak ada kesalahan yang terjadi
              selama proses tersebut.
            </li>
            <li>
              <strong>Total Gagal : </strong>
              Jumlah total dari eksekusi cron job yang gagal. Dalam hal ini,
              cron job mungkin telah dijadwalkan untuk dijalankan tetapi
              mengalami kesalahan atau masalah yang menyebabkan eksekusi tidak
              berhasil.
            </li>
          </ul>
        </Alert>
        <MKIDatatable
          className={this.configDatatable.tableClass}
          config={this.configDatatable}
          records={this.state.data}
          columns={this.columns}
          minWidth={1000}
          dynamic={false}
          total_record={this.state.totalRow}
          initial={this.state.filter}
          onChange={(filter) => {
            this.setState(
              {
                loadingTable: true,
                filter: filter,
              },
              function () {
                this.setTokenAPI();
              }
            );
          }}
          loading={this.state.loadingTable}
        />
        {this.state.detailId !== null ? (
          <Modal
            size="xl"
            isOpen={this.state.showDetail}
            toggle={() => {
              this.setState({
                showDetail: !this.state.showDetail,
              });
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myLargeModalLabelx">
                Detail Logs
              </h5>
              <button
                onClick={() => {
                  this.setState({
                    showDetail: false,
                  });
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <DetailLog id={this.state.detailId} />
            </div>
          </Modal>
        ) : null}
      </>
    );
  }
}

export default TableComponent;
