import React, { Fragment, Component } from "react";
import { withRouter } from "react-router";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import IndexBloc from "./Blocs/IndexBloc";
import Select from "react-select";
import apiResponse from "../../../../services/apiResponse";
import { Alert } from "reactstrap";
//CPU USAGE
import IndexMonitoringNowComponent from "./Components/CpuUsage/IndexMonitoringNowComponent";
import IndexMonitoringCpu5minComponent from "./Components/CpuUsage/IndexMonitoring30MinuteComponent";
import IndexMonitoringCpu1HourComponent from "./Components/CpuUsage/IndexMonitoring1HourComponent";
import IndexMonitoringCpu6HourComponent from "./Components/CpuUsage/IndexMonitoring6HourComponent";
import IndexMonitoringCpu1DayComponent from "./Components/CpuUsage/IndexMonitoring1DayComponent";
import IndexMonitoringCpu1WeekComponent from "./Components/CpuUsage/IndexMonitoring1WeekComponent";
import IndexMonitoringCpu1MonthComponent from "./Components/CpuUsage/IndexMonitoring1MonthComponent";
import IndexMonitoringCpu6MonthComponent from "./Components/CpuUsage/IndexMonitoring6MonthComponent";
import IndexMonitoringCpu1YearComponent from "./Components/CpuUsage/IndexMonitoring1YearComponent";

//SISTEM LOAD
import IndexMonitoring5MinuteComponent from "./Components/SystemLoad/IndexMonitoring5MinuteComponent";
import IndexMonitoringLoad30MinComponent from "./Components/SystemLoad/IndexMonitoring30MinComponent";
import IndexMonitoringLoad1HourComponent from "./Components/SystemLoad/IndexMonitoring1HourComponent";
import IndexMonitoringLoad6HourComponent from "./Components/SystemLoad/IndexMonitoring6HourComponent";
import IndexMonitoringLoad1DayComponent from "./Components/SystemLoad/IndexMonitoring1DayComponent";
import IndexMonitoringLoad1WeekComponent from "./Components/SystemLoad/IndexMonitoring1WeekComponent";
import IndexMonitoringLoad1MonthComponent from "./Components/SystemLoad/IndexMonitoring1MonthComponent";
import IndexMonitoringLoad6MonthComponent from "./Components/SystemLoad/IndexMonitoring6MonthComponent";
import IndexMonitoringLoad1YearComponent from "./Components/SystemLoad/IndexMonitoring1YearComponent";

//MEMORY USAGE
import IndexMemoryUsage5MinuteComponent from "./Components/MemoryUsage/IndexMonitoring5MinuteComponent";
import IndexMemoryUsage30MinuteComponent from "./Components/MemoryUsage/IndexMonitoring30MinuteComponent";
import IndexMemoryUsage1HourComponent from "./Components/MemoryUsage/IndexMonitoring1HourComponent";
import IndexMemoryUsage6HourComponent from "./Components/MemoryUsage/IndexMonitoring6HourComponent";
import IndexMemoryUsage1DayComponent from "./Components/MemoryUsage/IndexMonitoring1DayComponent";
import IndexMemoryUsage1WeekComponent from "./Components/MemoryUsage/IndexMonitoring1WeekComponent";
import IndexMemoryUsage1MonthComponent from "./Components/MemoryUsage/IndexMonitoring1MonthComponent";
import IndexMemoryUsage6MonthComponent from "./Components/MemoryUsage/IndexMonitoring6MonthComponent";
import IndexMemoryUsage1YearComponent from "./Components/MemoryUsage/IndexMonitoring1YearComponent";

//MEMORY UTILIZATION
import IndexMemoryUtilization5MinuteComponent from "./Components/MemoryUtilization/IndexMonitoring5MinuteComponent";
import IndexMemoryUtilization30MinuteComponent from "./Components/MemoryUtilization/IndexMonitoring30MinComponent";
import IndexMemoryUtilization1HourComponent from "./Components/MemoryUtilization/IndexMonitoring1HourComponent";
import IndexMemoryUtilization6HourComponent from "./Components/MemoryUtilization/IndexMonitoring6HourComponent";
import IndexMemoryUtilization1DayComponent from "./Components/MemoryUtilization/IndexMonitoring1DayComponent";
import IndexMemoryUtilization1WeekComponent from "./Components/MemoryUtilization/IndexMonitoring1WeekComponent";
import IndexMemoryUtilization1MonthComponent from "./Components/MemoryUtilization/IndexMonitoring1MonthComponent";
import IndexMemoryUtilization6MonthComponent from "./Components/MemoryUtilization/IndexMonitoring6MonthComponent";
import IndexMemoryUtilization1YearComponent from "./Components/MemoryUtilization/IndexMonitoring1YearComponent";

//DISK UTILIZATION
import IndexDiskUtilization5MinuteComponent from "./Components/DiskUtilization/IndexMonitoring5MinuteComponent";
import IndexDiskUtilization30MinuteComponent from "./Components/DiskUtilization/IndexMonitoring30MinComponent";
import IndexDiskUtilization1HourComponent from "./Components/DiskUtilization/IndexMonitoring1HourComponent";
import IndexDiskUtilization6HourComponent from "./Components/DiskUtilization/IndexMonitoring6HourComponent";
import IndexDiskUtilization1DayComponent from "./Components/DiskUtilization/IndexMonitoring1DayComponent";
import IndexDiskUtilization1WeekComponent from "./Components/DiskUtilization/IndexMonitoring1WeekComponent";
import IndexDiskUtilization1MonthComponent from "./Components/DiskUtilization/IndexMonitoring1MonthComponent";
import IndexDiskUtilization6MonthComponent from "./Components/DiskUtilization/IndexMonitoring6MonthComponent";
import IndexDiskUtilization1YearComponent from "./Components/DiskUtilization/IndexMonitoring1YearComponent";

class IndexView extends Component {
  indexBloc = new IndexBloc();

  constructor(props) {
    super(props);
    this.state = {
      detail: null,
      listNameHost: [],
      listNamePeriod: [],
      activeTab: "1",
      selectedHost: null,
      selectedPeriod: null,
      isLoading: true,
    };

    this.activeRef = React.createRef();
    this.inactiveRef = React.createRef();
  }

  reloadActiveRef = () => this.activeRef.current.setTokenAPI();
  reloadInactiveRef = () => this.inactiveRef.current.setTokenAPI();

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  handleServerChange = (selectedOption) => {
    this.setState({ selectedHost: selectedOption });
  };

  handlePeriodChange = (selectedOption) => {
    this.setState({ selectedPeriod: selectedOption });
  };

  componentDidMount() {
    this.indexBloc.getallHostChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          let response = result.data.response;
          // console.log(response);
          const hostOptions = response.map((item) => ({
            value: item.hostid,
            label: item.name,
          }));

          // const defaultHost = hostOptions.find(
          //   (option) => option.label === "server-rancher-master"
          // );

          const defaultHost = hostOptions.length > 0 ? hostOptions[0] : null;
          this.setState({
            listNameHost: hostOptions,
            selectedHost: defaultHost || null,
            isLoading: false,
          });
          break;
        case apiResponse.ERROR:
          this.setState({ isLoading: false });
          break;
        default:
          break;
      }
    });

    this.indexBloc.getAllPeriodChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          let response = result.data.response;
          // console.log(response);

          const periodOptions = response.name.map((item) => ({
            value: item,
            label: item,
          }));

          const defaultPeriod = periodOptions.find(
            (option) => option.label === "5 Menit Terakhir"
          );

          this.setState({
            listNamePeriod: periodOptions,
            selectedPeriod: defaultPeriod || null,
            isLoading: false,
          });
          break;
        case apiResponse.ERROR:
          this.setState({ isLoading: false });
          break;
        default:
          break;
      }
    });

    this.indexBloc.fetchAllHosts({});
    this.indexBloc.fetchAllPeriods({});
  }

  render() {
    const { selectedPeriod, isLoading } = this.state;
    const renderTableView = () => {
      return (
        <>
          <Nav tabs>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: this.state.activeTab === "1",
                })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-home"></i>
                </span>
                <span className="d-none d-sm-block">
                  <span className="uil-cloud-block"></span> Penggunaan CPU
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: this.state.activeTab === "2",
                })}
                onClick={() => {
                  this.toggle("2");
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-home"></i>
                </span>
                <span className="d-none d-sm-block">
                  <span className="uil-monitor-heart-rate"></span> Beban Sistem
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: this.state.activeTab === "3",
                })}
                onClick={() => {
                  this.toggle("3");
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-home"></i>
                </span>
                <span className="d-none d-sm-block">
                  <span className="uil-servers"></span> Penggunaan Memori
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: this.state.activeTab === "4",
                })}
                onClick={() => {
                  this.toggle("4");
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-home"></i>
                </span>
                <span className="d-none d-sm-block">
                  <span className="uil-folder-info"></span> Pemanfaatan Memori
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: this.state.activeTab === "5",
                })}
                onClick={() => {
                  this.toggle("5");
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-home"></i>
                </span>
                <span className="d-none d-sm-block">
                  <span className="uil-cloud-computing"></span> Pemanfaatan Disk
                </span>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent
            activeTab={this.state.activeTab}
            className="p-3 text-muted"
          >
            {/* CPU USAGE */}
            {selectedPeriod && selectedPeriod.value === "5 Menit Terakhir" && (
              <TabPane tabId="1">
                <IndexMonitoringNowComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "30 Menit Terakhir" && (
              <TabPane tabId="1">
                <IndexMonitoringCpu5minComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "1 Jam Terakhir" && (
              <TabPane tabId="1">
                <IndexMonitoringCpu1HourComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "6 Jam Terakhir" && (
              <TabPane tabId="1">
                <IndexMonitoringCpu6HourComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "1 Hari Terakhir" && (
              <TabPane tabId="1">
                <IndexMonitoringCpu1DayComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "1 Minggu Terakhir" && (
              <TabPane tabId="1">
                <IndexMonitoringCpu1WeekComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "1 Bulan Terakhir" && (
              <TabPane tabId="1">
                <IndexMonitoringCpu1MonthComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "6 Bulan Terakhir" && (
              <TabPane tabId="1">
                <IndexMonitoringCpu6MonthComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "1 Tahun Terakhir" && (
              <TabPane tabId="1">
                <IndexMonitoringCpu1YearComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {/* SYSTEM LOAD */}
            {selectedPeriod && selectedPeriod.value === "5 Menit Terakhir" && (
              <TabPane tabId="2">
                <IndexMonitoring5MinuteComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "30 Menit Terakhir" && (
              <TabPane tabId="2">
                <IndexMonitoringLoad30MinComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "1 Jam Terakhir" && (
              <TabPane tabId="2">
                <IndexMonitoringLoad1HourComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "6 Jam Terakhir" && (
              <TabPane tabId="2">
                <IndexMonitoringLoad6HourComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "1 Hari Terakhir" && (
              <TabPane tabId="2">
                <IndexMonitoringLoad1DayComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "1 Minggu Terakhir" && (
              <TabPane tabId="2">
                <IndexMonitoringLoad1WeekComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "1 Bulan Terakhir" && (
              <TabPane tabId="2">
                <IndexMonitoringLoad1MonthComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "6 Bulan Terakhir" && (
              <TabPane tabId="2">
                <IndexMonitoringLoad6MonthComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "1 Tahun Terakhir" && (
              <TabPane tabId="2">
                <IndexMonitoringLoad1YearComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {/* MEMORY USAGE */}
            {selectedPeriod && selectedPeriod.value === "5 Menit Terakhir" && (
              <TabPane tabId="3">
                <IndexMemoryUsage5MinuteComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "30 Menit Terakhir" && (
              <TabPane tabId="3">
                <IndexMemoryUsage30MinuteComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "1 Jam Terakhir" && (
              <TabPane tabId="3">
                <IndexMemoryUsage1HourComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "6 Jam Terakhir" && (
              <TabPane tabId="3">
                <IndexMemoryUsage6HourComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "1 Hari Terakhir" && (
              <TabPane tabId="3">
                <IndexMemoryUsage1DayComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "1 Minggu Terakhir" && (
              <TabPane tabId="3">
                <IndexMemoryUsage1WeekComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "1 Bulan Terakhir" && (
              <TabPane tabId="3">
                <IndexMemoryUsage1MonthComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "6 Bulan Terakhir" && (
              <TabPane tabId="3">
                <IndexMemoryUsage6MonthComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "1 Tahun Terakhir" && (
              <TabPane tabId="3">
                <IndexMemoryUsage1YearComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {/* MEMORY UTILIZATION */}
            {selectedPeriod && selectedPeriod.value === "5 Menit Terakhir" && (
              <TabPane tabId="4">
                <IndexMemoryUtilization5MinuteComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "30 Menit Terakhir" && (
              <TabPane tabId="4">
                <IndexMemoryUtilization30MinuteComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "1 Jam Terakhir" && (
              <TabPane tabId="4">
                <IndexMemoryUtilization1HourComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "6 Jam Terakhir" && (
              <TabPane tabId="4">
                <IndexMemoryUtilization6HourComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "1 Hari Terakhir" && (
              <TabPane tabId="4">
                <IndexMemoryUtilization1DayComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "1 Minggu Terakhir" && (
              <TabPane tabId="4">
                <IndexMemoryUtilization1WeekComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "1 Bulan Terakhir" && (
              <TabPane tabId="4">
                <IndexMemoryUtilization1MonthComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "6 Bulan Terakhir" && (
              <TabPane tabId="4">
                <IndexMemoryUtilization6MonthComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "1 Tahun Terakhir" && (
              <TabPane tabId="4">
                <IndexMemoryUtilization1YearComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {/* DISK UTILIZATION */}
            {selectedPeriod && selectedPeriod.value === "5 Menit Terakhir" && (
              <TabPane tabId="5">
                <IndexDiskUtilization5MinuteComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "30 Menit Terakhir" && (
              <TabPane tabId="5">
                <IndexDiskUtilization30MinuteComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "1 Jam Terakhir" && (
              <TabPane tabId="5">
                <IndexDiskUtilization1HourComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "6 Jam Terakhir" && (
              <TabPane tabId="5">
                <IndexDiskUtilization6HourComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "1 Hari Terakhir" && (
              <TabPane tabId="5">
                <IndexDiskUtilization1DayComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "1 Minggu Terakhir" && (
              <TabPane tabId="5">
                <IndexDiskUtilization1WeekComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "1 Bulan Terakhir" && (
              <TabPane tabId="5">
                <IndexDiskUtilization1MonthComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "6 Bulan Terakhir" && (
              <TabPane tabId="5">
                <IndexDiskUtilization6MonthComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "1 Tahun Terakhir" && (
              <TabPane tabId="5">
                <IndexDiskUtilization1YearComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}
          </TabContent>
        </>
      );
    };

    const renderView = () => {
      if (isLoading) {
        return (
          <div className="text-center">
            <div>
              <Spinner
                style={{ width: "3rem", height: "3rem", borderWidth: "0.4rem" }}
                color="dark"
              />
            </div>
            <div>
              <span style={{ fontWeight: "bold" }}>Mengambil Data ...</span>
            </div>
          </div>
        );
      } else {
        return renderTableView();
      }
    };
    return (
      <Fragment>
        <Container fluid>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Alert color="info">
                    <CardTitle>Kinerja Sistem</CardTitle>
                    <CardSubtitle>
                      Memantau kinerja sistem dengan tujuan untuk memastikan
                      semua komponen berjalan dengan baik dan mendeteksi potensi
                      masalah sebelum menjadi kritis.
                    </CardSubtitle>
                  </Alert>
                  <hr />
                  <Row>
                    <Col md="3">
                      <label>Pilih Host : </label>
                      <br />
                      <Select
                        options={this.state.listNameHost}
                        placeholder="Pilih Host"
                        onChange={this.handleServerChange}
                        value={this.state.selectedHost}
                      />
                    </Col>

                    <Col md="3">
                      <label>Pilih Periode Waktu : </label>
                      <br />
                      <Select
                        options={this.state.listNamePeriod}
                        placeholder="Pilih Periode Waktu"
                        onChange={this.handlePeriodChange}
                        value={this.state.selectedPeriod}
                      />
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Card>
                      <CardBody>{renderView()}</CardBody>
                    </Card>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

//export default IndexView
export default withRouter(IndexView);
