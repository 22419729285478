import { Subject } from "rxjs";
import apiResponse from "../../../../../services/apiResponse";
import IndexRepository from "../Repositories/IndexRepository";

class IndexBloc {
  getallHostChannel = new Subject();
  getAllSpaceUtilizationChannel = new Subject();
  repository = new IndexRepository();

  constructor() {
    this.getallHostChannel.next({ status: apiResponse.INITIAL });
    this.getAllSpaceUtilizationChannel.next({ status: apiResponse.INITIAL });
  }

  fetchAllHosts = async (query) => {
    this.getallHostChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchAllHosts(query)
        .then((result) => {
          this.getallHostChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getallHostChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchAllGraphSpaceUtilizationAll = async (query) => {
    this.getAllSpaceUtilizationChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchAllGraphSpaceUtilizationAll(query)
        .then((result) => {
          this.getAllSpaceUtilizationChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getAllSpaceUtilizationChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };
}

export default IndexBloc;
