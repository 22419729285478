import { apiRequest, guideCategoryCreate } from "../../../../services/adapters/base";

class CreateRepository {
  fetchCreate = async (query) => {
    return await apiRequest(
      "post",
      guideCategoryCreate,
      {
        body: query,
      },
      true
    );
  };
}

export default CreateRepository;
