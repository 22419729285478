import { apiRequest, helpdeskPicCreate, userList, helpdeskServiceList } from "../../../../services/adapters/base";

class CreateRepository {
  fetchCreate = async (query) => {
    return await apiRequest(
      "post",
      helpdeskPicCreate,
      {
        body: query,
      },
      true
    );
  };

  fetchUsers = async (query, cancelToken) => {
    return await apiRequest(
      "get",
      userList,
      {
        cancelToken: cancelToken,
        params: query,
      },
      true
    );
  };

  fetchServices = async (query, cancelToken) => {
    return await apiRequest(
      "get",
      helpdeskServiceList,
      {
        cancelToken: cancelToken,
        params: query,
      },
      true
    );
  };
}

export default CreateRepository;
