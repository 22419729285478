import classnames from "classnames";
import { Fragment, useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Label
} from "reactstrap";
import { accessRole } from "../../../helpers/access_role";
import TableActiveComponent from "./Components/TableActiveComponent";
import TableInactiveComponent from "./Components/TableInactiveComponent";
import TableActivePegawaiComponent from "./Components/TableActivePegawaiComponent";
import TableInactivePegawaiComponent from "./Components/TableInactivePegawaiComponent";
import moment from 'moment'
import DatePicker from "../../../helpers/ui/DatePicker"
import "./style.scss";

var reformatOptions = function (raw, label, value) {
  return raw.map(function (data) {
    var newObj = {};
    newObj["label"] = data[label];
    newObj["value"] = data[value];

    return newObj;
  });
};


const IndexView = (props) => {
  const [activeTab, setactiveTab] = useState("1");
  const [openTicketsCount, setOpenTicketsCount] = useState(0);
  const [closeTicketsCount, setCloseTicketsCount] = useState(0);
  const activeRef = useRef();
  const inactiveRef = useRef();
  const [filter, setFilter] = useState()
  const [helpdeskStartDate, setHelpdeskStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [helpdeskEndDate, setHelpdeskEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  let userData = JSON.parse(localStorage.getItem("userdata"));
  let roleId = JSON.parse(localStorage.getItem("currentRoleId"));

  const reloadActiveRef = () => activeRef.current.setTokenAPI();
  const reloadInactiveRef = () => inactiveRef.current.setTokenAPI();

  const exportActiveRef = () => activeRef.current.exportExcel()
  const exportInactiveRef = () => inactiveRef.current.exportExcel()

  useEffect(() => {
    const intervalId = setInterval(() => {
      reloadActiveRef();
      reloadInactiveRef();
    }, 25000);
    return () => clearInterval(intervalId);
}, []);

  function toggle(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  }

  function setCountCb(res, type) {
    if (type === "open") {
      setOpenTicketsCount(res);
    } else {
      setCloseTicketsCount(res);
    }
  }

  const handleButtonSearch = () => {
    if (helpdeskStartDate && helpdeskEndDate) {
      setFilter({
        'helpdeskStartDate': helpdeskStartDate,
        'helpdeskEndDate': helpdeskEndDate,
      })
      setTimeout(() => {
        reloadActiveRef()
        reloadInactiveRef()
      }, 300);
    }
  }

  const handleButtonExport = () => {
    activeTab == "1" ? exportActiveRef() : exportInactiveRef()
  }

  const renderTableView = () => {
    return (
      <>
        <Nav tabs>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "1",
              })}
              onClick={() => {
                toggle("1");
              }}
            >
              <span className="d-block d-sm-none">
                <i className="fas fa-home"></i>
              </span>
              <span className="d-none d-sm-block">
                <span>Open tickets </span>
                <div className="custom-badge bg-success">
                  {openTicketsCount}
                </div>
              </span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "2",
              })}
              onClick={() => {
                toggle("2");
              }}
            >
              <span className="d-block d-sm-none">
                <i className="far fa-envelope"></i>
              </span>
              <span className="d-none d-sm-block">
                <span>Closed tickets </span>
                <div className="custom-badge bg-purple">
                  {closeTicketsCount}
                </div>
              </span>
            </NavLink>
          </NavItem>
        </Nav>
        {roleId == 1 ? (
          <TabContent activeTab={activeTab} className="p-3 text-muted">
            <TabPane tabId="1">
              <TableActivePegawaiComponent
                cb={setCountCb}
                ref={activeRef}
                handleInactiveSuccess={reloadInactiveRef}
                handleActiveSuccess={reloadActiveRef}
                filter={filter}
              />
            </TabPane>
            <TabPane tabId="2">
              <TableInactivePegawaiComponent cb={setCountCb} ref={inactiveRef} filter={filter}/>
            </TabPane>
          </TabContent>
        ) : (
          <TabContent activeTab={activeTab} className="p-3 text-muted">
            <TabPane tabId="1">
              <TableActiveComponent
                cb={setCountCb}
                ref={activeRef}
                handleInactiveSuccess={reloadInactiveRef}
                handleActiveSuccess={reloadActiveRef}
                filter={filter}
              />
            </TabPane>
            <TabPane tabId="2">
              <TableInactiveComponent cb={setCountCb} ref={inactiveRef} filter={filter} />
            </TabPane>
          </TabContent>
        )}
      </>
    );
  };

  const renderView = () => {
    return renderTableView();
  };

  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                {/* <div className="float-end">
                  <div className="d-flex" style={{ columnGap: "1rem" }}>
                    {accessRole("create") && roleId == 1 ? (
                      <Link to="helpdesk.html?create">
                        <Button color="primary">Buat Tiket</Button>
                      </Link>
                    ) : null}
                  </div>
                </div> */}
                <CardTitle>Tiket</CardTitle>
                <CardSubtitle className="mb-3">Data - data tiket</CardSubtitle>
                <hr />
                
                  <div>
                    <Row className="mb-3">
                      <Label className="col-md-3 col-form-label">
                        Filter Tanggal Mulai
                      </Label>
                      <Col md="3">
                        <DatePicker
                          name="start_date"
                          isWeekday={false}
                          dateFormat="dd/MM/yyyy"
                          placeholder="Tanggal Mulai"
                          isAvForm={false}
                          onChange={val => setHelpdeskStartDate(moment(val).format('YYYY-MM-DD'))}
                          defaultValue={helpdeskStartDate}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Label className="col-md-3 col-form-label">
                        Filter Tanggal Selesai
                      </Label>
                      <Col md="3">
                        <DatePicker
                          name="end_date"
                          isWeekday={false}
                          dateFormat="dd/MM/yyyy"
                          placeholder="Tanggal Selesai"
                          isAvForm={false}
                          onChange={val => setHelpdeskEndDate(moment(val).format('YYYY-MM-DD'))}
                          defaultValue={helpdeskEndDate}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md="3"></Col>
                      <Col md="10">
                        <Button
                          color="primary"
                          type="button"
                          onClick={() => {
                            handleButtonSearch()
                          }}
                        >
                          <i className="uil-search-alt"></i> Cari
                        </Button>
                        {roleId == 2 ? (
                          <Button
                            className="ms-1"
                            color="success"
                            type="button"
                            onClick={() => {
                              handleButtonExport()
                            }}
                          >
                            <i className="uil-download-alt"></i> Unduh Excel
                          </Button>
                        ) : null }
                      </Col>
                    </Row>
                    <hr />
                  </div>
                
                {renderView()}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default IndexView;
