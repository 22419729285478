import React from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const Menus = (props) => {
  const notifMenu =
    typeof props.notificationMenu !== "undefined" ? props.notificationMenu : {};
  const menus = props.data;
  return <MenuList data={menus} notifMenu={notifMenu} root={true} />;
};

const MenuList = (props) => {
  const menus = props.data;
  return (
    <>
      {menus.map((menu) => {
        if (parseInt(menu.id) !== 12) {
          const arrowMenu = props.root
            ? menu.child.length > 0
              ? "has-arrow waves-effect"
              : "waves-effect"
            : menu.child.length > 0
            ? "has-arrow"
            : "";
          const linkMenu = menu.child.length > 0 ? "/" : menu.link;
          const iconMenu = menu.icon === null ? "" : menu.icon;

          return (
            <li key={menu.id}>
              <Link to={linkMenu} className={arrowMenu}>
                {iconMenu !== "" ? <i className={iconMenu}></i> : <></>}
                {menu.title}
                {Object.size(props.notifMenu) &&
                typeof props.notifMenu[menu.id] !== "undefined" &&
                props.notifMenu[menu.id] > 0 ? (
                  <>
                    <span
                      className={
                        "badge rounded-pill " +
                        (props.root ? "bg-danger" : "bg-warning") +
                        " float-end"
                      }
                    >
                      {props.notifMenu[menu.id] > 9
                        ? "9+"
                        : props.notifMenu[menu.id]}
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </Link>
              {menu.child.length > 0 ? (
                <ul className="sub-menu">
                  <MenuList
                    data={menu.child}
                    notifMenu={props.notifMenu}
                    root={false}
                  />
                </ul>
              ) : (
                <></>
              )}
            </li>
          );
        } else {
          return null;
        }
      })}
    </>
  );
};

Object.size = function (obj) {
  var size = 0,
    key;
  for (key in obj) {
    if (obj.hasOwnProperty(key)) size++;
  }
  return size;
};

Menus.propTypes = {
  notificationMenu: PropTypes.any,
};
const mapStatetoProps = (state) => {
  return {
    ...state.Notification,
  };
};

export default withRouter(connect(mapStatetoProps, {})(Menus));

/* const Menus = (props) => {
    const menus = props.data
    return (
        <>
            {
                menus.map(menu => {
                    const arrowMenu = menu.child.length > 0 ? 'has-arrow waves-effect' : 'waves-effect'
                    const linkMenu = menu.child.length > 0 ? '/' : menu.link
                
                    return (
                        <li key={menu.id}>
                            <Link to={linkMenu} className={arrowMenu}>
                                <i className={menu.icon}></i>
                                <span>{menu.title}</span>
                            </Link>
                            { menu.child.length > 0 ? <SubMenu data={menu.child} /> : <></> }
                        </li>
                    )
                })
            }
        </>
    )
}

const SubMenu = (props) => {
    const menus = props.data
    return (
        <>
            <ul className="sub-menu">
                 {
                    menus.map(menu => {
                        const arrowMenu = menu.child.length > 0 ? 'has-arrow' : ''
                        const linkMenu = menu.child.length > 0 ? '/' : menu.link
                    
                        return (
                            <li key={menu.id}>
                                <Link to={linkMenu} className={arrowMenu}>
                                    {menu.title}
                                </Link>
                                { menu.child.length > 0 ? <SubMenu data={menu.child} /> : <></> }
                            </li>
                        )
                    })
                }
            </ul>
        </>
    )
} */

//export default Menus

