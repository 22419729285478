import React, { useState, Fragment, Component } from 'react'
import Shimmer from "react-shimmer-effect";
import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle,
    Button
} from "reactstrap"
import { Link, useHistory, useParams } from "react-router-dom"
import { withRouter } from "react-router";

import apiResponse from '../../../services/apiResponse'

import DetailBloc from './Blocs/DetailBloc'

class IndexView extends Component {

    detailBloc = new DetailBloc()

    constructor(props) {
        super(props)
        this.state = {}
    }

    componentDidMount() {
        this.detailBloc.detailChannel.subscribe((result) => {
            switch (result.status) {
                case apiResponse.COMPLETED:
                    if(result.data.status) {
                        let response = result.data.response
                        this.setState({
                            detail: response
                        })
                    }
                    break
                case apiResponse.ERROR:
                    
                    break
                default:
                    break
            }
        })
        const queryParams = new URLSearchParams(this.props.location.search)
        this.detailBloc.fetchDetail({uuid: queryParams.get('uuid') })
    }


    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle>Detail Sample</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Keterangan menu-menu. Lorem ipsum dolor sit amet...
                                    </CardSubtitle>
                                    <hr />
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Kode
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.sample_code !== "" ? this.state.detail.sample_code : '-'}</>
                                                : <Shimmer><div style={{width: 68, height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Judul
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.sample_title !== "" ? this.state.detail.sample_title : '-'}</>
                                                : <Shimmer><div style={{width: '40%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <label className="col-md-3">
                                            Keterangan
                                        </label>
                                        <Col md="9">
                                            {
                                                this.state.detail ?
                                                    <>{this.state.detail.sample_description !== "" ? this.state.detail.sample_description : '-'}</>
                                                :
                                                    <Shimmer><div style={{width: '80%', height: 15}}></div></Shimmer>
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md="3"></Col>
                                        <Col md="9">
                                            <Link to="/sample.html" className="waves-effect">
                                                <Button color="danger"><i className="uil-arrow-left"></i> Kembali</Button>
                                            </Link>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

//export default IndexView
export default withRouter(IndexView);