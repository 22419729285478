import { apiRequest, ticketCreate } from "../../../../services/adapters/base";

class CreateRepository {
  fetchCreate = async (query) => {
    return await apiRequest(
      "post",
      ticketCreate,
      {
        body: query,
      },
      true
    );
  };
}

export default CreateRepository;
