import classnames from "classnames";
import React, { Fragment, useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import TableComponent from "./Components/DataTableComponent";
import { Alert } from "reactstrap";

const IndexView = (props) => {
  const activeRef = useRef();
  const inactiveRef = useRef();
  const [activeTab, setactiveTab] = useState("1");

  // const reloadActiveRef = () => activeRef.current.setTokenAPI();
  const reloadInactiveRef = () => inactiveRef.current.setTokenAPI();

  function toggle(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  }

  const renderTableView = () => {
    return (
      <>
        <Nav tabs>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "1",
              })}
              onClick={() => {
                toggle("1");
              }}
            >
              <span className="d-block d-sm-none">
                <i className="fas fa-home"></i>
              </span>
              <span className="d-none d-sm-block">
                <span className="uil-cloud-block"></span> List Cron Job
              </span>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="p-3 text-muted">
          <TabPane tabId="1">
            <TableComponent
              ref={activeRef}
              handleInactiveSuccess={reloadInactiveRef}
            />
          </TabPane>
        </TabContent>
      </>
    );
  };

  const renderView = () => {
    return renderTableView();
  };

  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="float-end"></div>
                <Alert color="info">
                  <CardTitle>Scheduler</CardTitle>
                  <CardSubtitle>
                    Memungkinkan untuk memantau eksekusi cron job dengan mudah,
                    mengawasi kondisi secara real-time, menerima pemberitahuan
                    tentang detail status, serta mendeteksi potensi masalah
                    dengan cepat.
                  </CardSubtitle>
                </Alert>
                <hr />
                {renderView()}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default IndexView;
