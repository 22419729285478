import React, { Fragment, Component } from "react";
import { withRouter } from "react-router";
import {
  Col,
  Container,
  Row,
  Spinner,
  Card,
  CardBody,
  Button,
} from "reactstrap";
import IndexBloc from "../../Blocs/IndexBloc";
import { Line } from "react-chartjs-2";
import apiResponse from "../../../../../../services/apiResponse";

class IndexView extends Component {
  indexBloc = new IndexBloc();

  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.state = {
      data: null,
      loading: false,
      hoveredDesc: false,
      errorMessage: null,
    };
  }

  // componentDidMount() {
  //   this.setState({ loading: true });

  //   this.indexBloc.getGraphCpuUsage5MinuteChannel.subscribe((result) => {
  //     switch (result.status) {
  //       case apiResponse.COMPLETED:
  //         let response = result.data.response;
  //         let errormessage = result.data.message;
  //         // console.log(response);
  //         this.setState({
  //           data: response,
  //           loading: false,
  //           errorMessage: errormessage,
  //         });
  //         break;
  //       case apiResponse.ERROR:
  //         this.setState({
  //           loading: false,
  //         });
  //         break;
  //       default:
  //         break;
  //     }
  //   });

  //   this.indexBloc.fetchGraphCpuUsage5Minute({});
  // }
  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    this.setState({ loading: true, errorMessage: null });

    this.indexBloc.getGraphCpuUsage1WeekChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          let response = result.data.response;
          let errormessage = result.data.message;
          this.setState({
            data: response,
            loading: false,
            errorMessage: errormessage,
          });
          break;
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            errorMessage: "Failed to load data. Please try again.",
          });
          break;
        default:
          break;
      }
    });

    this.indexBloc.fetchGraphCpuUsage1Week({});
  };

  createChart = () => {
    const { selectedHost } = this.props;
    const { data } = this.state;

    const defaultChartData = {
      labels: [],
      datasets: [],
    };

    if (!selectedHost) {
      return defaultChartData;
    }

    const value = selectedHost.value;

    const specificHost = Array.isArray(data)
      ? data.find((item) => item.host.hostid === value)
      : null;

    const graphId = specificHost ? specificHost.graph.graphid : null;

    const specificGraph = Array.isArray(this.state.data)
      ? this.state.data.find((item) => item.graph.graphid === graphId)
      : null;

    const items =
      specificGraph && Array.isArray(specificGraph.graph.items)
        ? specificGraph.graph.items
        : [];

    const datasets = items.map((item, index) => {
      const dataPoints = item.values.map((value) => parseFloat(value.last));

      return {
        label: item.name,
        data: dataPoints,
        borderColor: this.getColorForItem(index),
        backgroundColor: this.getColorForItem(index),
        fill: false,
        tension: 0.1,
      };
    });

    const labels =
      items.length > 0 && items[0].values
        ? items[0].values.map((value) => value.timestamp)
        : [];

    return {
      labels: labels,
      datasets: datasets,
    };
  };

  // getColorForItem = (name) => {
  //   switch (name) {
  //     case "CPU system time":
  //       return "rgba(0, 130, 0, 0.8)";
  //     case "CPU user time":
  //       return "rgba(214, 68, 0, 0.8)";
  //     case "CPU nice time":
  //       return "rgba(0, 238, 0, 0.8)";
  //     case "CPU iowait time":
  //       return "rgba(255, 176, 0, 0.8)";
  //     case "CPU steal time":
  //       return "rgba(255, 24, 169, 0.8)";
  //     case "CPU interrupt time":
  //       return "rgba(137, 8, 241, 0.8)";
  //     case "CPU softirq time":
  //       return "rgba(85, 103, 0, 0.8)";
  //     case "CPU guest time":
  //       return "rgba(87, 0, 0, 0.8)";
  //     default:
  //       return "rgba(245, 152, 145, 0.8)";
  //   }
  // };

  getColorForItem = (index) => {
    const colors = [
      "rgba(0, 130, 0, 0.8)",
      "rgba(214, 68, 0, 0.8)",
      "rgba(0, 238, 0, 0.8)",
      "rgba(255, 176, 0, 0.8)",
      "rgba(255, 24, 169, 0.8)",
      "rgba(137, 8, 241, 0.8)",
      "rgba(85, 103, 0, 0.8)",
      "rgba(87, 0, 0, 0.8)",
      "rgba(24, 200, 255, 0.8)",
      "rgba(200, 100, 150, 0.8)",
      "rgba(75, 192, 192, 0.8)",
    ];
    return colors[index % colors.length];
  };

  renderCpuInfo = () => {
    const { selectedHost } = this.props;
    const { data } = this.state;

    if (!selectedHost) {
      return <div>Tidak ada host yang dipilih.</div>;
    }

    const value = selectedHost.value;

    const specificHost = Array.isArray(data)
      ? data.find((item) => item.host.hostid === value)
      : null;

    const graphId = specificHost ? specificHost.graph.graphid : null;

    const specificGraph = Array.isArray(this.state.data)
      ? this.state.data.find((item) => item.graph.graphid === graphId)
      : null;

    const items =
      specificGraph && Array.isArray(specificGraph.graph.items)
        ? specificGraph.graph.items
        : [];

    if (items.length === 0) {
      return <div>Tidak ada informasi tersedia untuk host yang dipilih.</div>;
    }

    return (
      <div>
        <div>
          <div style={{ fontWeight: "bold", fontSize: "20px" }}>
            Informasi{" "}
            <span
              className="uil-info-circle"
              onMouseEnter={() => this.setState({ hoveredDesc: true })}
              onMouseLeave={() => this.setState({ hoveredDesc: false })}
              style={{ cursor: "pointer" }}
            />
          </div>
          <br />
        </div>
        {this.state.hoveredDesc && (
          <div
            style={{
              position: "absolute",
              background: "#f4f4f4",
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "10px",
              zIndex: 1000,
              width: "900px",
              boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
            }}
          >
            {this.renderDescDescriptions()}
          </div>
        )}

        {items.map((item, index) => (
          <div key={index}>
            <strong>{item.name}</strong> : <strong>Last:</strong>{" "}
            {item.last !== null ? item.last : "0.000"}% <strong>|</strong>{" "}
            <strong>Min:</strong> {item.min !== null ? item.min : "0.000"}%{" "}
            <strong>|</strong> <strong>Average:</strong>{" "}
            {item.average !== null ? item.average : "0.000"}% <strong>|</strong>{" "}
            <strong>Max:</strong> {item.max !== null ? item.max : "0.000"}%
          </div>
        ))}
      </div>
    );
  };

  renderDescDescriptions = () => {
    const desc = [
      {
        level: "CPU system time",
        description:
          "Menunjukkan jumlah waktu yang dihabiskan CPU untuk menjalankan proses tingkat sistem (kernel). Proses ini merupakan bagian dari sistem operasi, bukan aplikasi pengguna.",
      },
      {
        level: "CPU user time",
        description:
          "Waktu yang dihabiskan CPU untuk menjalankan aplikasi atau proses tingkat pengguna.",
      },
      {
        level: "CPU steal time",
        description:
          "Metrik ini relevan dalam lingkungan virtualisasi dan menunjukkan waktu di mana CPU menunggu sumber daya dari mesin virtual lain.",
      },
      {
        level: "CPU softirq time",
        description:
          "Waktu yang dihabiskan CPU untuk menangani interrupt soft (peristiwa yang tidak segera memerlukan perhatian).",
      },
      {
        level: "CPU nice time",
        description:
          "Menunjukkan waktu yang dihabiskan untuk menjalankan proses yang dinyatakan sebagai Nice.",
      },
      {
        level: "CPU iowait time",
        description:
          "Menunjukkan waktu yang dihabiskan CPU saat menunggu operasi I/O selesai.",
      },
      {
        level: "CPU guest time",
        description:
          "Mencerminkan waktu CPU yang dihabiskan dalam lingkungan virtualisasi untuk menjalankan mesin virtual lain.",
      },
      {
        level: "CPU guest nice time",
        description:
          "Waktu yang dihabiskan untuk menjalankan proses di dalam mesin virtual yang memiliki prioritas Nice.",
      },
      {
        level: "CPU interrupt time",
        description:
          "Waktu yang dihabiskan untuk menangani interrupt dari perangkat keras.",
      },
    ];

    return (
      <div>
        {desc.map((item, index) => (
          <div key={index}>
            <strong>{item.level}:</strong> {item.description}
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { loading, errorMessage } = this.state;
    return (
      <Fragment>
        <Container fluid>
          <Row>
            <Col className="col-12">
              {loading ? (
                <div className="text-center">
                  <div>
                    <Spinner
                      style={{
                        width: "3rem",
                        height: "3rem",
                        borderWidth: "0.4rem",
                      }}
                      color="dark"
                    />
                  </div>
                  <div>
                    <span style={{ fontWeight: "bold" }}>
                      Mengambil Data ...
                    </span>
                  </div>
                </div>
              ) : errorMessage ? (
                <Card className="text-center">
                  <br />
                  <CardBody>
                    <h5 className="text-dark">{errorMessage}</h5>
                    <br />
                    <Button color="danger" onClick={this.fetchData}>
                      Muat Ulang
                    </Button>
                  </CardBody>
                </Card>
              ) : (
                <div>
                  <Line data={this.createChart()} />
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <hr />
            <Col className="col-12">{this.renderCpuInfo()}</Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default withRouter(IndexView);
