import {
  apiRequest,
  getCronJob,
  getCronJobLog,
} from "../../../../../services/adapters/base";

class DataTableRepository {
  fetchAllCronJob = async (query, cancelToken) => {
    return await apiRequest(
      "get",
      getCronJob,
      {
        cancelToken: cancelToken,
        params: query,
      },
      true
    );
  };

  fetchDetailCronJob = async (query, cancelToken) => {
    return await apiRequest(
      "get",
      getCronJobLog,
      {
        cancelToken: cancelToken,
        params: query,
      },
      true
    );
  };
}

export default DataTableRepository;
