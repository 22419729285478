import { apiRequest, keywordUpdate, keywordDetail, getRedirectLinkList } from "../../../../services/adapters/base";

class UpdateRepository {
  fetchUpdate = async (query) => {
    return await apiRequest(
      "post",
      keywordUpdate,
      {
        body: query,
      },
      true
    );
  };

  fetchDetail = async (query) => {
    return await apiRequest(
      "get",
      keywordDetail,
      {
        params: query,
      },
      true
    );
  };

  fetchRedirectLink = async (query, cancelToken) => {
    return await apiRequest(
      "get",
      getRedirectLinkList,
      {
        cancelToken: cancelToken,
        params: query,
      },
      true
    );
  };
}

export default UpdateRepository;
