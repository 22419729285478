import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import TimeAgo from "react-timeago";

import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import idStrings from "react-timeago/lib/language-strings/id";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";

import DataTableBloc from '../../../pages/Home/Notification/Index/Blocs/DataTableBloc.jsx'

const NotificationDropdown = (props) => {
	const formatter = buildFormatter(idStrings);

	const dataTableBloc = new DataTableBloc();

	const notifCount = parseInt(props.notificationCount);
	const notifList = props.notificationList;
	const [menu, setMenu] = useState(false);

	return (
		<>
			<Dropdown
				isOpen={menu}
				toggle={() => setMenu(!menu)}
				className="dropdown d-inline-block text-dark"
				tag="li"
			>
				<DropdownToggle
					className="btn header-item noti-icon waves-effect"
					tag="button"
					id="page-header-notifications-dropdown"
				>
					<i className="uil-bell text-dark"></i>
					{notifCount > 0 ? (
						<span className="badge bg-danger rounded-pill">{notifCount}</span>
					) : null}
				</DropdownToggle>

				{notifCount > 0 ? (
					<>
						<DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
							<div className="p-3">
								<Row className="align-items-center">
									<Col>
										<h6 className="m-0 font-size-16">Notifikasi</h6>
									</Col>
								</Row>
							</div>

							<SimpleBar>
								{notifList.length > 0 ? (
									notifList.map((notif) => {
										return (
											<Fragment key={notif.notification_id}>
												<Link
													to={notif.notification_link}
													onClick={() => {
														if (notif.notification_read === 0) {
																dataTableBloc.fetchFlagRead({ 'notification_id': notif.notification_id })  
														} 
													}}
													className="text-reset notification-item"
												>
													<div className="d-flex align-items-start">
														<div className="avatar-xs me-3">
															<span className="avatar-title bg-primary rounded-circle font-size-16" style={{ width: "2rem", height: "2rem" }}>
																<i className="uil-align-center-v"></i>
															</span>
														</div>
														<div className="flex-1">
															<h6 className="mt-0 mb-1">
																{notif.notification_title}
															</h6>
															<div className="font-size-12 text-muted">
																<p className="mb-1">
																	{notif.notification_description}
																</p>
																<p className="mb-0">
																	<i className="mdi mdi-clock-outline"></i>{" "}
																	<TimeAgo
																		date={notif.notification_date}
																		formatter={formatter}
																	/>
																</p>
															</div>
														</div>
													</div>
												</Link>
											</Fragment>
										);
									})
								) : (
									<></>
								)}
							</SimpleBar>
							<div className="p-2 border-top d-grid">
								<Link
									className="btn btn-sm btn-link font-size-14 text-center"
									to="/admin/notification.html"
								>
									<i className="uil-arrow-circle-right me-1"></i>
									Lihat Semua Notifikasi
								</Link>
							</div>
						</DropdownMenu>
					</>
				) : null}
			</Dropdown>
		</>
	);
};

NotificationDropdown.propTypes = {
	notificationCount: PropTypes.number,
	notificationList: PropTypes.any,
};
const mapStatetoProps = (state) => {
	return {
		...state.Layout,
		...state.Notification,
	};
};
export default withRouter(connect(mapStatetoProps, {})(NotificationDropdown));

/* export default withTranslation()(NotificationDropdown)

NotificationDropdown.propTypes = {
  t: PropTypes.any
} */
