import React, { Component, Fragment } from "react";
import moment from 'moment'
import { Redirect } from "react-router-dom";
import { Tooltip } from "reactstrap";
import { accessRole } from "../../../../helpers/access_role";
import { config } from "../../../../helpers/config_global";
import { ConfirmAlert } from "../../../../helpers/ui/alert";
import MKIDatatable from "../../../../modules/mki-datatable/lib";
import { cancelToken } from "../../../../services/adapters/base";
import apiResponse from "../../../../services/apiResponse";
import DataTableBloc from "../Blocs/DataTableBloc";

class TableComponent extends Component {
  dataTableBloc = new DataTableBloc();

  source;
  historyTable = "historyMeetingRegistration";
  defaultOrder = "meeting_registration_id";
  defaultSize = 10;
  defaultSort = "desc";
  configDatatable = {
    ...config("datatable"),
    sort: {
      column: this.defaultOrder,
      order: this.defaultSort,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      tooltip: false,
      detailId: null,
      updateId: null,
      deleteId: null,
      notulenUuid: null,
      defaultSize: 10,
      rows: [],
      totalRow: 0,
      loadingTable: true,
      filter: {
            filter_value: "",
            page_number: 1,
            page_size: this.defaultSize,
            sort_order: {
              column: this.defaultOrder,
              order: this.defaultSort,
            },
          },
    };
  }
  toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

  loadData = async () => {
    /* history Datatable */
    sessionStorage.setItem(
      this.historyTable,
      JSON.stringify(this.state.filter)
    );

    /* kirim ke server */
    let offset =
      (this.state.filter.page_number - 1) * this.state.filter.page_size;
    if (this.state.defaultSize !== this.state.filter.page_size) {
      this.setState({
        defaultSize: this.state.filter.page_size,
      });
      offset = 0;
      this.configDatatable.page_number = 1;
    }
    const query = {
      search: this.state.filter.filter_value,
      limit: this.state.filter.page_size,
      offset: offset,
      order: this.state.filter.sort_order.column,
      sort: this.state.filter.sort_order.order,
    };
    await this.dataTableBloc.fetchRowTable(query, this.source.token);
  };

  setTokenAPI = () => {
    if (typeof this.source != typeof undefined) {
      this.source.cancel();
    }
    this.source = cancelToken();
    this.loadData();
  };

  deleteGuide = async () => {
    await this.dataTableBloc.fetchDeleteGuide({
      guide_id: this.state.deleteId,
    });
  };

  confirmResponse = (response) => {
    let forState = {};
    switch (response) {
      case "cancel":
        forState = {
          confirm: false,
        };
        break;
      case "confirm":
        forState = {
          loading: true,
          confirm: false
        };
        this.deleteGuide();
        break;
      case "success":
        forState = {
          success: false,
          redirect: true,
        };
        break;
      case "failed":
        forState = {
          failed: false,
        };
        break;
      default:
    }
    this.setState(forState);
  };

  confirmResponseCancel = (response) => {
    let forState = {};
    switch (response) {
      case "cancel":
        forState = {
          confirmCancel: false,
        };
        break;
      case "confirm":
        forState = {
          loadingCancel: true,
          confirmCancel: false
        };
        this.cancelMeetingRegistration();
        break;
      case "success":
        forState = {
          successCancel: false,
          redirectCancel: true,
        };
        break;
      case "failed":
        forState = {
          failedCancel: false,
        };
        break;
      default:
    }
    this.setState(forState);
  };

  cancelMeetingRegistration = async () => {
    await this.dataTableBloc.cancelMeetingRegistration({
        meeting_registration_uuid: this.state.cancelId,
    })
}

  componentDidMount() {
    this.setTokenAPI();

    this.dataTableBloc.rowtableChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.INITIAL:
          this.setState({
            loadingTable: false,
          });
          break;
        case apiResponse.LOADING:
          this.setState({
            loadingTable: true,
          });
          break;
        case apiResponse.COMPLETED:
          const response = result.data.response;
          this.setState({
            totalRow: response.total,
            rows: response.result,
            loadingTable: false,
          });
          this.props.cb(response.total, "open");
          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    }); 

    this.dataTableBloc.cancelChannel.subscribe((result) => {
      switch (result.status) {
          case apiResponse.COMPLETED:
              this.setState(
                  {
                      loadingCancel: false,
                      successCancel: true,
                      loadingTable: true,
                  },
                  function () {
                      this.setTokenAPI()
                      // this.props.handleInactiveSuccess()
                  }
              )
              break
          case apiResponse.ERROR:
              this.setState({
                  loadingCancel: false,
                  failedCancel: true,
              })
              break
          default:
              break
      }
  })
  }

  componentWillUnmount() {
    this.dataTableBloc.rowtableChannel.unsubscribe();
  }

  columns = [
    {
      key: "meeting_registration_name",
      text: "Nama Rapat",
      className: "address",
      sortable: true,
    },
    {
      key: "meeting_registration_type_name",
      text: "Tipe Rapat",
      className: "address",
      sortable: true,
    },
    {
      key: "meeting_registration_date",
      text: "Tanggal Rapat",
      className: "address",
      cell: (record) => {
        const date = record.meeting_registration_date
        return (
            <>
                <p>{moment(date).format('LL')}</p>
            </>
        )
    },
      width: '20%',
    },
    {
      key: "meeting_registration_time",
      text: "Jam Rapat",
      className: "address",
      cell: (record) => {
        const startTime = record.meeting_registration_start_time
        const endTime = record.meeting_registration_end_time
        return (
            <>
                <p>{startTime + " - " + endTime}</p>
            </>
        )
    },
      width: '20%',
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 150,
      sortable: false,
      align: "center",
      cell: (record) => {
        const thisId = record.meeting_registration_uuid;
        return (
          <Fragment>
            {accessRole("read") ? (
              <span>
                <button
                  className="btn btn-outline-secondary btn-sm"
                  id={"detail-" + thisId}
                  onClick={() => {
                    this.setState({
                      detailId: record.meeting_registration_uuid,
                    });
                  }}
                  style={{
                    marginRight: "2px",
                    marginBottom: "2px",
                    padding: "2px 5px",
                  }}
                >
                  <i className="uil-align-left"></i>
                </button>
                <Tooltip
                  style={{
                    marginBottom: 2,
                  }}
                  placement="top"
                  isOpen={this.state["detail" + thisId]}
                  target={"detail-" + thisId}
                  toggle={() => {
                    this.setState({
                      ["detail" + thisId]: !this.state["detail" + thisId],
                    });
                  }}
                >
                  Detail
                </Tooltip>
              </span>
            ) : null}
            {accessRole("read") ? (
              <span>
                <button
                  className="btn btn-outline-secondary btn-sm"
                  id={"cancel-" + thisId}
                  onClick={() => {
                    this.setState({
                      cancelId: record.meeting_registration_uuid,
                      confirmCancel: true,
                    });
                  }}
                  style={{
                    marginRight: "2px",
                    marginBottom: "2px",
                    padding: "2px 5px",
                  }}
                >
                  <i className="mdi mdi-close-thick"></i>
                </button>
                <Tooltip
                  style={{
                    marginBottom: 2,
                  }}
                  placement="top"
                  isOpen={this.state["cancel" + thisId]}
                  target={"cancel-" + thisId}
                  toggle={() => {
                    this.setState({
                      ["cancel" + thisId]: !this.state["cancel" + thisId],
                    });
                  }}
                >
                  Cancel
                </Tooltip>
              </span>
            ) : null}
            {accessRole("read") ? (
              <span>
                <button
                  className="btn btn-outline-secondary btn-sm"
                  id={"update-" + thisId}
                  onClick={() => {
                    this.setState({
                      updateId: record.meeting_registration_uuid,
                    });
                  }}
                  style={{
                    marginRight: "2px",
                    marginBottom: "2px",
                    padding: "2px 5px",
                  }}
                >
                  <i className="uil-pen"></i>
                </button>
                <Tooltip
                  style={{
                    marginBottom: 2,
                  }}
                  placement="top"
                  isOpen={this.state["update" + thisId]}
                  target={"update-" + thisId}
                  toggle={() => {
                    this.setState({
                      ["update" + thisId]: !this.state["update" + thisId],
                    });
                  }}
                >
                  Perbaharui
                </Tooltip>
              </span>
            ) : null}
            <span>
                <button
                  className="btn btn-outline-secondary btn-sm"
                  id={"generate-" + thisId}
                  onClick={() => {
                    this.props.generatePdf(record);
                    this.setState({
                      generateId: record.meeting_registration_uuid,
                    });
                  }}
                  style={{
                    marginRight: "2px",
                    marginBottom: "2px",
                    padding: "2px 5px",
                  }}
                >
                  <i className="uil-focus-target"></i>
                </button>
                <Tooltip
                  style={{
                    marginBottom: 2,
                  }}
                  placement="top"
                  isOpen={this.state["generate" + thisId]}
                  target={"generate-" + thisId}
                  toggle={() => {
                    this.setState({
                      ["generate" + thisId]: !this.state["generate" + thisId],
                    });
                  }}
                >
                  Generate Daftar Hadir
                </Tooltip>
              </span>
          </Fragment>
        );
      },
    },
  ];

  render() {
    return (
      <>
        <MKIDatatable
          className={this.configDatatable.tableClass}
          config={this.configDatatable}
          records={this.state.rows}
          columns={this.columns}
					minWidth={1000}
          dynamic={true}
          total_record={this.state.totalRow}
          initial={this.state.filter}
          onChange={(filter) => {
            this.setState(
              {
                loadingTable: true,
                filter: filter,
              },
              function () {
                this.setTokenAPI();
              }
            );
          }}
          loading={this.state.loadingTable}
        />
        <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan menghapus data ini?"
          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"
          successTitle="Berhasil!"
          successInfo="Data berhasil dihapus."
          failedTitle="Gagal!"
          failedInfo="Data gagal dihapus."
          showConfirm={this.state.confirm}
          showLoading={this.state.loading}
          showSuccess={this.state.success}
          showFailed={this.state.failed}
          response={this.confirmResponse}
        />
        <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan membatalkan data ini?"
          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"
          successTitle="Berhasil!"
          successInfo="Data berhasil dibatalkan."
          failedTitle="Gagal!"
          failedInfo="Data gagal dibatalkan."
          showConfirm={this.state.confirmCancel}
          showLoading={this.state.loadingCancel}
          showSuccess={this.state.successCancel}
          showFailed={this.state.failedCancel}
          response={this.confirmResponseCancel}
        />
        {this.state.detailId !== null ? (
          <Redirect to={"meeting_registration.html?detail&id=" + this.state.detailId} />
        ) : null}
        {this.state.updateId !== null ? (
          <Redirect to={"meeting_registration.html?update&id=" + this.state.updateId} />
        ) : null}
      </>
    );
  }
}

export default TableComponent;
