import {
  apiRequest,
  monitoringGetAllHosts,
  monitoringGetAllPeriods,
  getGraphNetworkTrafficAllNow,
  getGraphNetworkTrafficAll30Minute,
  getGraphNetworkTrafficAll1Hour,
  getGraphNetworkTrafficAll6Hour,
  getGraphNetworkTrafficAll1Day,
  getGraphNetworkTrafficAll1Week,
  getGraphNetworkTrafficAll1Month,
  getGraphNetworkTrafficAll6Month,
  getGraphNetworkTrafficAll1Year,
} from "../../../../../services/adapters/base";

class IndexRepository {
  fetchAllHosts = async (query) => {
    return await apiRequest(
      "get",
      monitoringGetAllHosts,
      {
        params: query,
      },
      true
    );
  };

  fetchAllPeriods = async (query) => {
    return await apiRequest(
      "get",
      monitoringGetAllPeriods,
      {
        params: query,
      },
      true
    );
  };

  fetchAllGraphNetworkTrafficAllNow = async (query) => {
    return await apiRequest(
      "get",
      getGraphNetworkTrafficAllNow,
      {
        params: query,
      },
      true
    );
  };

  fetchAllGraphNetworkTrafficAll30Min = async (query) => {
    return await apiRequest(
      "get",
      getGraphNetworkTrafficAll30Minute,
      {
        params: query,
      },
      true
    );
  };

  fetchAllGraphNetworkTrafficAll1Hour = async (query) => {
    return await apiRequest(
      "get",
      getGraphNetworkTrafficAll1Hour,
      {
        params: query,
      },
      true
    );
  };

  fetchAllGraphNetworkTrafficAll6Hour = async (query) => {
    return await apiRequest(
      "get",
      getGraphNetworkTrafficAll6Hour,
      {
        params: query,
      },
      true
    );
  };

  fetchAllGraphNetworkTrafficAll1Day = async (query) => {
    return await apiRequest(
      "get",
      getGraphNetworkTrafficAll1Day,
      {
        params: query,
      },
      true
    );
  };

  fetchAllGraphNetworkTrafficAll1Week = async (query) => {
    return await apiRequest(
      "get",
      getGraphNetworkTrafficAll1Week,
      {
        params: query,
      },
      true
    );
  };

  fetchAllGraphNetworkTrafficAll1Month = async (query) => {
    return await apiRequest(
      "get",
      getGraphNetworkTrafficAll1Month,
      {
        params: query,
      },
      true
    );
  };

  fetchAllGraphNetworkTrafficAll6Month = async (query) => {
    return await apiRequest(
      "get",
      getGraphNetworkTrafficAll6Month,
      {
        params: query,
      },
      true
    );
  };

  fetchAllGraphNetworkTrafficAll1Year = async (query) => {
    return await apiRequest(
      "get",
      getGraphNetworkTrafficAll1Year,
      {
        params: query,
      },
      true
    );
  };
}

export default IndexRepository;
