import { AvForm } from "availity-reactstrap-validation";
import moment from "moment";
import { Component } from "react";
import { withRouter } from "react-router";
import { Link, Redirect } from "react-router-dom";
import Shimmer from "react-shimmer-effect";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import { ConfirmAlert } from "../../../helpers/ui/alert";
import { FileInput, generateFormData } from "../../../helpers/ui/FileInput";
import apiResponse from "../../../services/apiResponse";
import { AgendaContext } from "../Context/AgendaContext";

import CreateBloc from "../Create/Blocs/CreateBloc";
import EmployeeBloc from "../Create/Blocs/EmployeeBloc";
import DetailBloc from "../Detail/Blocs/DetailBloc";
import UpdateBloc from "../Update/Blocs/UpdateBloc";
import MinioClient from "../../../services/minioClient";

class IndexView extends Component {
  static contextType = AgendaContext;

  minioClient = new MinioClient();

  createBloc = new CreateBloc();
  updateBloc = new UpdateBloc();
  employeeBloc = new EmployeeBloc();
  detailBloc = new DetailBloc();

  constructor(props) {
    super(props);

    this.state = {
      dataForm: {},
      detail: {},
      participants: [],
    };
  }

  submitData = async () => {
    const { dataForm } = this.context;
    const formData = generateFormData(dataForm, {
      field: "attachment_detail",
      name: "agendaAttachments/attachment",
    });
    // for testing
    // await this.minioClient.put("Agenda/attachment_detail", this.state.dataForm["attachment_detail"]);

    // Create new row for the attachment
    await this.createBloc.fetchNotulensi(formData);

    // If success creating new attachment then bind to `agendas` table `agenda_attachment` column
    // if (this.state.detail && this.state.detail.agenda_uuid) {
    //   await this.updateBloc.fetchUpdate({
    //     agenda_uuid: this.state.detail.agenda_uuid,
    //     agenda_attachment: this.state.detail.agenda_uuid,
    //   });
    // }
  };

  handlerOnSubmit = (e, values) => {
    const { dataForm, setDataForm } = this.context;
    const queryParams = new URLSearchParams(this.props.location.search);

    e.preventDefault();
    setDataForm({
      agenda_uuid: queryParams.get("uuid"),
      ...dataForm,
      ...values,
    });

    this.setState({
      confirm: true,
    });
  };

  confirmResponse = (response) => {
    let forState = {};
    switch (response) {
      case "cancel":
        forState = {
          confirm: false,
        };
        break;
      case "confirm":
        forState = {
          loading: true,
          confirm: false,
        };
        this.submitData();
        break;
      case "success":
        forState = {
          success: false,
          redirect: true,
        };
        break;
      case "failed":
        forState = {
          failed: false,
        };
        break;
      default:
    }
    this.setState(forState);
  };

  componentDidMount() {
    this.createBloc.notulensiChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          const response = result.data.response;
          this.setState({
            loading: false,
            success: true,
            detail: {
              ...this.state.detail,
              agenda_attachment: response.attachment_uuid,
            },
          });

          break;
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            failed: true,
          });
          break;
        default:
          break;
      }
    });

    this.employeeBloc.employeeChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState({
            loading: false,
            success: true,
          });
          break;
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            failed: true,
          });
          break;
        default:
          break;
      }
    });

    this.updateBloc.updateChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState({
            loading: false,
            success: true,
          });
          break;
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            failed: true,
          });
          break;
        default:
          break;
      }
    });

    this.detailBloc.detailChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          if (result.data.status) {
            let response = result.data.response.result;
            const { dataForm, setDataForm } = this.context;
            this.setState({
              detail: response,
              participants: response.agenda_participants.map((participant) => {
                return {
                  label: participant.employee_name,
                  value: participant.employee_nip,
                };
              }),
            });

            setDataForm({ ...dataForm, agenda_uuid: response.agenda_uuid });
          }
          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });
    const queryParams = new URLSearchParams(this.props.location.search);
    this.detailBloc.fetchDetail({ uuid: queryParams.get("uuid") });
  }

  render() {
    return (
      <>
        <Container fluid>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>Tambah Notulen Agenda</CardTitle>
                  <CardSubtitle className="mb-3">
                    Tambah notulen agenda dengan mengisi form di bawah ini
                  </CardSubtitle>
                  <hr />
                  <div className="container m-0 p-0">
                    <Row className="mb-3">
                      <label className="col-md-3">Nama Agenda</label>
                      <Col md="9">
                        {this.state.detail.agenda_uuid ? (
                          <>
                            {this.state.detail.agenda_name !== ""
                              ? this.state.detail.agenda_name
                              : "-"}
                          </>
                        ) : (
                          <Shimmer>
                            <div style={{ width: 68, height: 15 }}></div>
                          </Shimmer>
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-3">Mulai</label>
                      <Col md="9">
                        {this.state.detail.agenda_uuid ? (
                          <>
                            {this.state.detail.agenda_start_date !== ""
                              ? moment(
                                  `${this.state.detail.agenda_start_date}T${this.state.detail.agenda_start_time}`
                                ).format("LLLL")
                              : "-"}
                          </>
                        ) : (
                          <Shimmer>
                            <div style={{ width: "40%", height: 15 }}></div>
                          </Shimmer>
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <label className="col-md-3">Keterangan</label>
                      <Col md="9">
                        {this.state.detail.agenda_uuid ? (
                          <>
                            {this.state.detail.agenda_description !== ""
                              ? this.state.detail.agenda_description
                              : "-"}
                          </>
                        ) : (
                          <Shimmer>
                            <div style={{ width: "80%", height: 15 }}></div>
                          </Shimmer>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <AvForm
                    className="needs-validation"
                    onValidSubmit={(e, values) =>
                      this.handlerOnSubmit(e, values)
                    }
                  >
                    <label className="col-md-3">Upload file notulensi :</label>
                    <Row className="mb-3 p-2">
                      <FileInput
                        name="attachment_detail"
                        accept=".pdf,.doc,.docx,.xls,.xlsx,.txt"
                      />
                    </Row>

                    <Row className="mb-3">
                      <Col md="12" className="p-2">
                        <Link to="agenda.html">
                          <Button color="danger">
                            <i className="uil-arrow-left"></i> Kembali
                          </Button>
                        </Link>{" "}
                        <Button color="success" type="submit">
                          <i className="uil-save"></i> Simpan Perubahan
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan menyimpan data ini?"
          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"
          successTitle="Berhasil!"
          successInfo="Data berhasil disimpan"
          failedTitle="Gagal!"
          failedInfo="Data gagal disimpan"
          showConfirm={this.state.confirm}
          showLoading={this.state.loading}
          showSuccess={this.state.success}
          showFailed={this.state.failed}
          response={this.confirmResponse}
        />
        {this.state.redirect ? <Redirect to="/admin/agenda.html" /> : null}
      </>
    );
  }
}

export default withRouter(IndexView);
