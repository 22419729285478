import React from "react";
import { Redirect } from "react-router-dom";

import Logout from "../pages/Authentication/Logout";

//Home
import HomeDashboard from "../pages/Home/Dashboard/RouteView";
import HomeProfile from "../pages/Home/Profile/RouteView";
import HomeNotification from "../pages/Home/Notification/RouteView";

//Admin
import Application from "../pages/Application/RouteView";
import Agenda from "../pages/Agenda/RouteView";
import Helpdesk from "../pages/Helpdesk/RouteView";
import Announcement from "../pages/Announcement/RouteView";
import Panduan from "../pages/Panduan/RouteView";
import KategoriPanduan from "../pages/KategoriPanduan/RouteView";
import LayananPortal from "../pages/LayananPortal/RouteView";
import FiturLayananPortal from "../pages/FiturLayananPortal/RouteView";
import HelpdeskMappingPic from "../pages/HelpdeskMappingPic/RouteView";
import HelpdeskService from "../pages/HelpdeskService/RouteView";
import HelpdeskModule from "../pages/HelpdeskModule/RouteView";
import ResetDevice from "../pages/ResetDevice/RouteView";
import MeetingRegistration from "../pages/MeetingRegistration/RouteView";
import Keyword from "../pages/Keyword/RouteView";
import Rough from "../pages/Rough/RouteView";
import SystemPerformance from "../pages/Monitoring/SystemPerformance/RouteView";
import TrafficInterfaces from "../pages/Monitoring/NetworkInterfaces/RouteView";
import ConditionSystem from "../pages/Monitoring/ConditionSystem/RouteView";
import FileSystem from "../pages/Monitoring/FileSystem/RouteView";
import Scheduler from "../pages/Monitoring/Scheduler/RouteView";

//Setting
import SettingConfig from "../pages/Setting/Config/RouteView";
import SettingUser from "../pages/Setting/User/RouteView";
import SettingAccessRole from "../pages/Setting/AccessRole/RouteView";
import SettingActivity from "../pages/Setting/Activity/RouteView";

const userRoutes = [
  //base
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/admin/agenda.html" />,
  },
  { path: "/home/dashboard.html", component: HomeDashboard },
  { path: "/home/profile.html", component: HomeProfile },

  { path: "/admin/notification.html", component: HomeNotification },
  { path: "/admin/application.html", component: Application },
  { path: "/admin/agenda.html", component: Agenda },
  { path: "/admin/helpdesk.html", component: Helpdesk },
  { path: "/admin/pengumuman.html", component: Announcement },
  { path: "/admin/panduan.html", component: Panduan },
  { path: "/admin/kategori_panduan.html", component: KategoriPanduan },
  { path: "/admin/layanan_portal.html", component: LayananPortal },
  { path: "/admin/fitur_layanan_portal.html", component: FiturLayananPortal },
  { path: "/admin/helpdesk_mapping_pic.html", component: HelpdeskMappingPic },
  { path: "/admin/helpdesk_service.html", component: HelpdeskService },
  { path: "/admin/helpdesk_module.html", component: HelpdeskModule },
  { path: "/admin/reset_device.html", component: ResetDevice },
  { path: "/admin/meeting_registration.html", component: MeetingRegistration },
  { path: "/admin/keyword.html", component: Keyword },
  { path: "/admin/Rough.html", component: Rough },
  {
    path: "/admin/system_performance_monitoring.html",
    component: SystemPerformance,
  },
  {
    path: "/admin/network_interfaces_monitoring.html",
    component: TrafficInterfaces,
  },
  {
    path: "/admin/alert_condition_monitoring.html",
    component: ConditionSystem,
  },
  {
    path: "/admin/file_system_monitoring.html",
    component: FileSystem,
  },
  {
    path: "/admin/scheduler.html",
    component: Scheduler,
  },

  { path: "/setting/config.html", component: SettingConfig },
  { path: "/setting/user.html", component: SettingUser },
  { path: "/setting/access-role.html", component: SettingAccessRole },
  { path: "/setting/access-role.html", component: SettingActivity },
];

const authRoutes = [{ path: "/logout.html", component: Logout }];

export { userRoutes, authRoutes };
