import { Subject } from "rxjs";
import apiResponse from "../../../../../services/apiResponse";
import IndexRepository from "../Repositories/IndexRepository";

class IndexBloc {
  getallHostChannel = new Subject();
  getAllPeriodChannel = new Subject();
  getAllNetworkTrafficNowChannel = new Subject();
  getAllNetworkTraffic30MinChannel = new Subject();
  getAllNetworkTraffic1HourChannel = new Subject();
  getAllNetworkTraffic6HourChannel = new Subject();
  getAllNetworkTraffic1DayChannel = new Subject();
  getAllNetworkTraffic1WeekChannel = new Subject();
  getAllNetworkTraffic1MonthChannel = new Subject();
  getAllNetworkTraffic6MonthChannel = new Subject();
  getAllNetworkTraffic1YearChannel = new Subject();
  repository = new IndexRepository();

  constructor() {
    this.getallHostChannel.next({ status: apiResponse.INITIAL });
    this.getAllPeriodChannel.next({ status: apiResponse.INITIAL });
    this.getAllNetworkTrafficNowChannel.next({ status: apiResponse.INITIAL });
    this.getAllNetworkTraffic30MinChannel.next({ status: apiResponse.INITIAL });
    this.getAllNetworkTraffic1HourChannel.next({ status: apiResponse.INITIAL });
    this.getAllNetworkTraffic6HourChannel.next({ status: apiResponse.INITIAL });
    this.getAllNetworkTraffic1DayChannel.next({ status: apiResponse.INITIAL });
    this.getAllNetworkTraffic1WeekChannel.next({ status: apiResponse.INITIAL });
    this.getAllNetworkTraffic1MonthChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getAllNetworkTraffic6MonthChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getAllNetworkTraffic1YearChannel.next({
      status: apiResponse.INITIAL,
    });
  }

  fetchAllHosts = async (query) => {
    this.getallHostChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchAllHosts(query)
        .then((result) => {
          this.getallHostChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getallHostChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchAllPeriods = async (query) => {
    this.getAllPeriodChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchAllPeriods(query)
        .then((result) => {
          this.getAllPeriodChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getAllPeriodChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchAllGraphNetworkTrafficNowAll = async (query) => {
    this.getAllNetworkTrafficNowChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchAllGraphNetworkTrafficAllNow(query)
        .then((result) => {
          this.getAllNetworkTrafficNowChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getAllNetworkTrafficNowChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchAllGraphNetworkTrafficNow30Min = async (query) => {
    this.getAllNetworkTraffic30MinChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchAllGraphNetworkTrafficAll30Min(query)
        .then((result) => {
          this.getAllNetworkTraffic30MinChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getAllNetworkTraffic30MinChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchAllGraphNetworkTrafficNow1Hour = async (query) => {
    this.getAllNetworkTraffic1HourChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchAllGraphNetworkTrafficAll1Hour(query)
        .then((result) => {
          this.getAllNetworkTraffic1HourChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getAllNetworkTraffic1HourChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchAllGraphNetworkTrafficNow6Hour = async (query) => {
    this.getAllNetworkTraffic6HourChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchAllGraphNetworkTrafficAll6Hour(query)
        .then((result) => {
          this.getAllNetworkTraffic6HourChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getAllNetworkTraffic6HourChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchAllGraphNetworkTrafficNow1Day = async (query) => {
    this.getAllNetworkTraffic1DayChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchAllGraphNetworkTrafficAll1Day(query)
        .then((result) => {
          this.getAllNetworkTraffic1DayChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getAllNetworkTraffic1DayChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchAllGraphNetworkTrafficNow1Week = async (query) => {
    this.getAllNetworkTraffic1WeekChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchAllGraphNetworkTrafficAll1Week(query)
        .then((result) => {
          this.getAllNetworkTraffic1WeekChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getAllNetworkTraffic1WeekChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchAllGraphNetworkTrafficNow1Month = async (query) => {
    this.getAllNetworkTraffic1MonthChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchAllGraphNetworkTrafficAll1Month(query)
        .then((result) => {
          this.getAllNetworkTraffic1MonthChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getAllNetworkTraffic1MonthChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchAllGraphNetworkTrafficNow6Month = async (query) => {
    this.getAllNetworkTraffic6MonthChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchAllGraphNetworkTrafficAll6Month(query)
        .then((result) => {
          this.getAllNetworkTraffic6MonthChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getAllNetworkTraffic6MonthChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchAllGraphNetworkTrafficNow1Year = async (query) => {
    this.getAllNetworkTraffic1YearChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchAllGraphNetworkTrafficAll1Year(query)
        .then((result) => {
          this.getAllNetworkTraffic1YearChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getAllNetworkTraffic1YearChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };
}

export default IndexBloc;
