import { apiWrapper, apiCancel } from "../wrapper";

export const initial = "base";
const baseUrl = process.env.REACT_APP_SERVICE_BASE_URL;
const appCode = process.env.REACT_APP_SERVICE_BASE_APPCODE;
const outputType = "json";

export const authConnect = "auth/connect/";
export const notificationCheck = "notification/check/";
export const authGetAccessRole = "auth/getAccessRole/";
export const authGetAccessMenus = "auth/getAccessMenus/";

export const userGetData = "user/getData/";
export const userList = "user/getUsers/";
export const deviceList = "user/getDevices/";
export const resetDevice = "user/resetDevice/";

export const accessCreate = "access/create/";
export const menuList = "menu/list/";

//dashboard->notification
export const notificationList = "pages/notification/list/";
export const notificationFlagRead = "pages/notification/flag/";

//agenda
export const agendaList = "agenda/getAgendas/";
export const agendaDetail = "agenda/getAgenda/";
export const agendaCreate = "agenda/createAgenda/";
export const agendaUpdate = "agenda/updateAgenda/";
export const agendaActive = "agenda/activeAgenda/";
export const agendaInactive = "agenda/inactiveAgenda/";
export const agendaNotulensi = "agenda/notulensiAgenda/";

//ticket
export const ticketList = "ticket/getTickets/";
export const ticketDetail = "ticket/getTicket/";
export const ticketCreate = "ticket/createTicket/";
export const ticketUpdate = "ticket/updateTicket/";
export const ticketFetchPriority = "ticket/fetchPriority/";
export const ticketUpdateClassification = "ticket/updateClassification/";
export const ticketClose = "ticket/closeTicket/";
export const ticketReopen = "ticket/reopenTicket/";
export const ticketReply = "ticket/replyTicket/";
export const ticketReplies = "ticket/getTicketReplies/";
export const ticketEscalation = "ticket/escalationTicket/";
export const ticketBackToPic = "ticket/backToPic/";
export const ticketExport = "ticket/exportExcel/";

//announcement
export const announcementList = "announcement/getAnnouncements/";
export const announcementDetail = "announcement/getAnnouncement/";
export const announcementCreate = "announcement/createAnnouncement/";
export const announcementUpdate = "announcement/updateAnnouncement/";
export const announcementActive = "announcement/activeAnnouncement/";
export const announcementInactive = "announcement/inactiveAnnouncement/";

//guide
export const guideList = "guide/getGuides/";
export const guideDetail = "guide/getGuide/";
export const guideCreate = "guide/createGuide/";
export const guideUpdate = "guide/updateGuide/";
export const guideDelete = "guide/deleteGuide/";

//guide category
export const guideCategoryList = "guide/getGuideCategories/";
export const guideCategoryDetail = "guide/getGuideCategory/";
export const guideCategoryCreate = "guide/createGuideCategory/";
export const guideCategoryUpdate = "guide/updateGuideCategory/";
export const guideCategoryDelete = "guide/deleteGuideCategory/";

//portal service
export const serviceList = "portalService/getPortalServices/";
export const serviceDetail = "portalService/getPortalService/";
export const serviceCreate = "portalService/createPortalService/";
export const serviceUpdate = "portalService/updatePortalService/";
export const serviceDelete = "portalService/deletePortalService/";

//portal service feature
export const serviceFeatureList = "portalService/getPortalServiceFeatures/";
export const serviceFeatureDetail = "portalService/getPortalServiceFeature/";
export const serviceFeatureCreate = "portalService/createPortalServiceFeature/";
export const serviceFeatureUpdate = "portalService/updatePortalServiceFeature/";
export const serviceFeatureDelete = "portalService/deletePortalServiceFeature/";

//helpdesk PIC
export const helpdeskPicList = "helpdeskPic/getHelpdeskPicList/";
export const helpdeskPicDetail = "helpdeskPic/getHelpdeskPic/";
export const helpdeskPicCreate = "helpdeskPic/createHelpdeskPic/";
export const helpdeskPicUpdate = "helpdeskPic/updateHelpdeskPic/";
export const helpdeskPicDelete = "helpdeskPic/deleteHelpdeskPic/";

//Helpdesk Service
export const helpdeskServiceList = "helpdesk/getHelpdeskServices/";
export const helpdeskServices = "helpdeskService/getHelpdeskServices/";
export const helpdeskServiceDetail = "helpdeskService/getHelpdeskService/";
export const helpdeskServiceCreate = "helpdeskService/createHelpdeskService/";
export const helpdeskServiceUpdate = "helpdeskService/updateHelpdeskService/";
export const helpdeskServiceDelete = "helpdeskService/deleteHelpdeskService/";

//Helpdesk Modules
export const helpdeskModuleList = "helpdeskModule/getHelpdeskModules/";
export const helpdeskModuleDetail = "helpdeskModule/getHelpdeskModule/";
export const helpdeskModuleCreate = "helpdeskModule/createHelpdeskModule/";
export const helpdeskModuleUpdate = "helpdeskModule/updateHelpdeskModule/";
export const helpdeskModuleDelete = "helpdeskModule/deleteHelpdeskModule/";

//Meeting Registration
export const meetingRegistrationList =
  "meetingRegistration/getMeetingRegistrations/";
export const meetingRegistrationDetail =
  "meetingRegistration/getMeetingRegistration/";
export const meetingRegistrationCreate =
  "meetingRegistration/createMeetingRegistration/";
export const meetingRegistrationUpdate =
  "meetingRegistration/updateMeetingRegistration/";
export const meetingRegistrationDelete =
  "meetingRegistration/deleteMeetingRegistration/";
export const meetingRegistrationCancel =
  "meetingRegistration/cancelMeetingRegistration/";

//NLP Keyword
export const keywordList = "keyword/getListKeyword/";
export const keywordDetail = "keyword/getKeyword/";
export const keywordCreate = "keyword/createKeyword/";
export const keywordUpdate = "keyword/updateKeyword/";
export const keywordDelete = "keyword/deleteKeyword/";
export const keywordDeleteAll = "keyword/deleteAllKeyword/";
export const getRedirectLinkList = "keyword/getRedirectLinkList/";

//NLP Roughs
export const roughList = "rough/getListRough/";
export const roughDetail = "rough/getRough/";
export const roughCreate = "rough/createRough/";
export const roughUpdate = "rough/updateRough/";
export const roughDelete = "rough/deleteRough/";

//Monitoring
export const monitoringGetAllHosts = "monitoring/getAllHosts/";
export const monitoringGetAllPeriods = "monitoring/getAllPeriod/";

//Monitoring CPU Usage
export const getGraphCpuUsage5Minute =
  "monitoringHost/CpuUsage/getGraphCpuUsage5Minute/";
export const getGraphCpuUsage30Minute =
  "monitoringHost/CpuUsage/getGraphCpuUsage30Minute/";
export const getGraphCpuUsage1Hour =
  "monitoringHost/CpuUsage/getGraphCpuUsage1Hour/";
export const getGraphCpuUsage6Hour =
  "monitoringHost/CpuUsage/getGraphCpuUsage6Hour/";
export const getGraphCpuUsage1Day =
  "monitoringHost/CpuUsage/getGraphCpuUsage1Day/";
export const getGraphCpuUsage1Week =
  "monitoringHost/CpuUsage/getGraphCpuUsage1Week/";
export const getGraphCpuUsage1Month =
  "monitoringHost/CpuUsage/getGraphCpuUsage1Month/";
export const getGraphCpuUsage6Month =
  "monitoringHost/CpuUsage/getGraphCpuUsage6Month/";
export const getGraphCpuUsage1Year =
  "monitoringHost/CpuUsage/getGraphCpuUsage1Year/";

//Monitoring Space Utilization
export const getGraphSpaceUtilizationAllNow =
  "monitoringHost/SpaceUtilization/getGraphSpaceUtilizationAllNow/";

//Monitoring Traffic Network
export const getGraphNetworkTrafficAllNow =
  "monitoringHost/NetworkTraffic/getGraphNetworkTrafficAllNow/";
export const getGraphNetworkTrafficAll30Minute =
  "monitoringHost/NetworkTraffic/getGraphNetworkTrafficAll30Minute/";
export const getGraphNetworkTrafficAll1Hour =
  "monitoringHost/NetworkTraffic/getGraphNetworkTrafficAll1Hour/";
export const getGraphNetworkTrafficAll6Hour =
  "monitoringHost/NetworkTraffic/getGraphNetworkTrafficAll6Hour/";
export const getGraphNetworkTrafficAll1Day =
  "monitoringHost/NetworkTraffic/getGraphNetworkTrafficAll1Day/";
export const getGraphNetworkTrafficAll1Week =
  "monitoringHost/NetworkTraffic/getGraphNetworkTrafficAll1Week/";
export const getGraphNetworkTrafficAll1Month =
  "monitoringHost/NetworkTraffic/getGraphNetworkTrafficAll1Month/";
export const getGraphNetworkTrafficAll6Month =
  "monitoringHost/NetworkTraffic/getGraphNetworkTrafficAll6Month/";
export const getGraphNetworkTrafficAll1Year =
  "monitoringHost/NetworkTraffic/getGraphNetworkTrafficAll1Year/";

//Monitoring System Load
export const getGraphSystemLoad5Minute =
  "monitoringHost/SystemLoad/getGraphSystemLoad5Minute/";
export const getGraphSystemLoad30Minute =
  "monitoringHost/SystemLoad/getGraphSystemLoad30Minute/";
export const getGraphSystemLoad1Hour =
  "monitoringHost/SystemLoad/getGraphSystemLoad1Hour/";
export const getGraphSystemLoad6Hour =
  "monitoringHost/SystemLoad/getGraphSystemLoad6Hour/";
export const getGraphSystemLoad1Day =
  "monitoringHost/SystemLoad/getGraphSystemLoad1Day/";
export const getGraphSystemLoad1Week =
  "monitoringHost/SystemLoad/getGraphSystemLoad1Week/";
export const getGraphSystemLoad1Month =
  "monitoringHost/SystemLoad/getGraphSystemLoad1Month/";
export const getGraphSystemLoad6Month =
  "monitoringHost/SystemLoad/getGraphSystemLoad6Month/";
export const getGraphSystemLoad1Year =
  "monitoringHost/SystemLoad/getGraphSystemLoad1Year/";

//Monitoring Memory usage
export const getGraphMemoryUsage5Minute =
  "monitoringHost/MemoryUsage/getGraphMemoryUsage5Minute/";
export const getGraphMemoryUsage30Minute =
  "monitoringHost/MemoryUsage/getGraphMemoryUsage30Minute/";
export const getGraphMemoryUsage1Hour =
  "monitoringHost/MemoryUsage/getGraphMemoryUsage1Hour/";
export const getGraphMemoryUsage6Hour =
  "monitoringHost/MemoryUsage/getGraphMemoryUsage6Hour/";
export const getGraphMemoryUsage1Day =
  "monitoringHost/MemoryUsage/getGraphMemoryUsage1Day/";
export const getGraphMemoryUsage1Week =
  "monitoringHost/MemoryUsage/getGraphMemoryUsage1Week/";
export const getGraphMemoryUsage1Month =
  "monitoringHost/MemoryUsage/getGraphMemoryUsage1Month/";
export const getGraphMemoryUsage6Month =
  "monitoringHost/MemoryUsage/getGraphMemoryUsage6Month/";
export const getGraphMemoryUsage1Year =
  "monitoringHost/MemoryUsage/getGraphMemoryUsage1Year/";

//Monitoring Memory utilization
export const getGraphMemoryUtilization5Minute =
  "monitoringHost/MemoryUtilization/getGraphMemoryUtilization5Minute/";
export const getGraphMemoryUtilization30Minute =
  "monitoringHost/MemoryUtilization/getGraphMemoryUtilization30Minute/";
export const getGraphMemoryUtilization1Hour =
  "monitoringHost/MemoryUtilization/getGraphMemoryUtilization1Hour/";
export const getGraphMemoryUtilization6Hour =
  "monitoringHost/MemoryUtilization/getGraphMemoryUtilization6Hour/";
export const getGraphMemoryUtilization1Day =
  "monitoringHost/MemoryUtilization/getGraphMemoryUtilization1Day/";
export const getGraphMemoryUtilization1Week =
  "monitoringHost/MemoryUtilization/getGraphMemoryUtilization1Week/";
export const getGraphMemoryUtilization1Month =
  "monitoringHost/MemoryUtilization/getGraphMemoryUtilization1Month/";
export const getGraphMemoryUtilization6Month =
  "monitoringHost/MemoryUtilization/getGraphMemoryUtilization6Month/";
export const getGraphMemoryUtilization1Year =
  "monitoringHost/MemoryUtilization/getGraphMemoryUtilization1Year/";

//Monitoring Disk utilization
export const getGraphDiskUtilization5Minute =
  "monitoringHost/DiskUtilization/getGraphDiskUtilization5Minute/";
export const getGraphDiskUtilization30Minute =
  "monitoringHost/DiskUtilization/getGraphDiskUtilization30Minute/";
export const getGraphDiskUtilization1Hour =
  "monitoringHost/DiskUtilization/getGraphDiskUtilization1Hour/";
export const getGraphDiskUtilization6Hour =
  "monitoringHost/DiskUtilization/getGraphDiskUtilization6Hour/";
export const getGraphDiskUtilization1Day =
  "monitoringHost/DiskUtilization/getGraphDiskUtilization1Day/";
export const getGraphDiskUtilization1Week =
  "monitoringHost/DiskUtilization/getGraphDiskUtilization1Week/";
export const getGraphDiskUtilization1Month =
  "monitoringHost/DiskUtilization/getGraphDiskUtilization1Month/";
export const getGraphDiskUtilization6Month =
  "monitoringHost/DiskUtilization/getGraphDiskUtilization6Month/";
export const getGraphDiskUtilization1Year =
  "monitoringHost/DiskUtilization/getGraphDiskUtilization1Year/";

//Monitoring Scheduler
export const getCronJob = "monitoring/getCronJob/";
export const getCronJobLog = "monitoring/getCronJobLog/";
//employee
export const searchEmployee = "user/searchUser/";

//dashboard->activity
export const activityList = "pages/activity/list/";

//export const cancelToken = () => apiCancel
export const cancelToken = () => {
  return apiCancel();
};
export const apiRequest = async (
  method,
  route,
  data = { params: null, body: null, cancelToken: null },
  needToken = true
) => {
  if (needToken) {
    const thisTime = Math.floor(Date.now() / 1000);
    const expire = localStorage.getItem(initial + "accessExpired");
    if (expire < thisTime) {
      await apiWrapper(
        baseUrl,
        initial,
        appCode,
        outputType,
        "post",
        "auth/getAccessToken/",
        data
      )
        .then((result) => {
          localStorage.setItem(
            initial + "accessToken",
            result.response.access_token
          );
          return apiWrapper(
            baseUrl,
            initial,
            appCode,
            outputType,
            method,
            route,
            data
          );
        })
        .catch((result) => {
          return Promise.reject("Tidak dapat mengambil accessToken");
        });
    } else {
      return apiWrapper(
        baseUrl,
        initial,
        appCode,
        outputType,
        method,
        route,
        data
      );
    }
  }
  return apiWrapper(baseUrl, initial, appCode, outputType, method, route, data);
};
