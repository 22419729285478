import { apiRequest, helpdeskPicList, helpdeskPicDelete } from "../../../../services/adapters/base";

class DataTableRepository {
  fetchRowTable = async (query, cancelToken) => {
    return await apiRequest(
      "get",
      helpdeskPicList,
      {
        cancelToken: cancelToken,
        params: query,
      },
      true
    );
  };

  fetchDeleteHelpdeskPic = async (query, cancelToken) => {
    return await apiRequest(
      "post",
      helpdeskPicDelete,
      {
        cancelToken: cancelToken,
        body: query,
      },
      true
    );
  };
}

export default DataTableRepository;
