import Shimmer from "react-shimmer-effect"
import React, { Fragment, Component } from "react"
// import { Link } from "react-router-dom"
import DataTableComponent from './Components/DataTableComponent'

import avatarMale from '../../../../assets/images/avatar_male.png'
import avatarFemale from '../../../../assets/images/avatar_female.png'

import {
    Container,
    Row, Col,
    CardBody, Card, CardTitle, CardSubtitle
} from "reactstrap"

class IndexView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataLoaded: false,
            employeeNip: '',
            employeeNipOld: '',
            employeeName: '',
            employeeFrontTitle: '',
            employeeEndTitle: '',
            employeeEmailInternal: '',
            employeeCurrentWorkUnitUuid: '',
            employeeCurrentWorkUnitName: '',
            employeeCurrentPositionUuid: '',
            employeeCurrentPositionName: '',
            employeeImageFileBaseurl: '',
            employeeImageFileType: '',
            employeeImageFilePath: '',
            employeeGender: '',
        }
    }

    componentDidMount() {
        this.loadData()
    }

    loadData = () => {
        const userdata = JSON.parse(localStorage.getItem('userdata'))
        this.setState({
            dataLoaded: true,
            employeeNip: userdata.employee_nip,
            employeeNipOld: userdata.employee_nip_old,
            employeeName: userdata.employee_name,
            employeeFrontTitle: userdata.employee_front_title,
            employeeEndTitle: userdata.employee_end_title,
            employeeEmailInternal: userdata.employee_email_internal,
            employeeCurrentWorkUnitUuid: userdata.employee_current_work_unit_uuid,
            employeeCurrentWorkUnitName: userdata.employee_current_work_unit_name,
            employeeCurrentPositionUuid: userdata.employee_current_position_uuid,
            employeeCurrentPositionName: userdata.employee_current_position_name,
            employeeImageFileBaseurl: userdata.employee_image_file_baseurl,
            employeeImageFileType: userdata.employee_image_file_type,
            employeeImageFilePath: userdata.employee_image_file_path,
            employeeGender: userdata.employee_gender,
        });
    }

    addDefaultPhoto = (ev) => {
        ev.target.src = this.state.employeeGender === 'P' ? avatarFemale : avatarMale
    }

    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row>
                        <Col xl="4">
                            <Card className="card h-100">
                                <CardBody>
                                    <div className="text-center">
                                        <div className="clearfix"></div>
                                        <div>
                                            {
                                                this.state.dataLoaded ?
                                                    <>
                                                        <img
                                                            onError={this.addDefaultPhoto}
                                                            src={this.state.employeeImageFileBaseurl + this.state.employeeImageFilePath}
                                                            alt=""
                                                            className="avatar-lg rounded-circle img-thumbnail"
                                                        />
                                                    </>
                                                    :
                                                    <Shimmer>
                                                        <div
                                                            className="avatar-lg rounded-circle img-thumbnail"
                                                            style={{
                                                                verticalAlign: 'middle'
                                                            }}
                                                        />
                                                    </Shimmer>
                                            }
                                        </div>
                                        <h5 className="mt-3 mb-1">
                                            {
                                                this.state.dataLoaded ?
                                                    <>{this.state.employeeName !== "" ? this.state.employeeName : '-'}</>
                                                    : <Shimmer><div className="shimmer" style={{ width: 140, height: 16 }}></div></Shimmer>
                                            }
                                        </h5>
                                        <p className="text-muted">
                                            {
                                                this.state.dataLoaded ?
                                                    <>{this.state.employeeCurrentWorkUnitName !== "" ? this.state.employeeCurrentWorkUnitName : '-'}</>
                                                    : <Shimmer><div className="shimmer" style={{ width: 220, height: 13 }}></div></Shimmer>
                                            }
                                        </p>
                                    </div>

                                    <hr className="my-4" />

                                    <div className="text-muted">
                                        <h5 className="font-size-16">Data Profil</h5>
                                        <p>Informasi diri, data ini berubah sewaktu-waktu sesuai dengan perubahan yang dilakukan di data kepegawaian</p>
                                        <div className="table-responsive mt-4">
                                            <div>
                                                <p className="mb-1">NIP :</p>
                                                <h5 className="font-size-16">
                                                    {
                                                        this.state.dataLoaded ?
                                                            <>{this.state.employeeNip !== "" ? this.state.employeeNip : '-'}</>
                                                            : <Shimmer><div className="shimmer" style={{ width: 168, height: 16 }}></div></Shimmer>
                                                    }
                                                </h5>
                                            </div>
                                            <div className="mt-4">
                                                <p className="mb-1">NIP Lama :</p>
                                                <h5 className="font-size-16">
                                                    {
                                                        this.state.dataLoaded ?
                                                            <>{this.state.employeeNipOld !== "" ? this.state.employeeNipOld : '-'}</>
                                                            : <Shimmer><div className="shimmer" style={{ width: 100, height: 16 }}></div></Shimmer>
                                                    }
                                                </h5>
                                            </div>
                                            <div className="mt-4">
                                                <p className="mb-1">Jenis Kelamin :</p>
                                                <h5 className="font-size-16">
                                                    {
                                                        this.state.dataLoaded ?
                                                            <>{this.state.employeeGender !== "" ? this.state.employeeGender === "P" ? "Perempuan" : "Laki-laki" : '-'}</>
                                                            : <Shimmer><div className="shimmer" style={{ width: 90, height: 16 }}></div></Shimmer>
                                                    }
                                                </h5>
                                            </div>
                                            <div className="mt-4">
                                                <p className="mb-1">Email :</p>
                                                <h5 className="font-size-16">
                                                    {
                                                        this.state.dataLoaded ?
                                                            <>{this.state.employeeEmailInternal !== "" ? this.state.employeeEmailInternal : '-'}</>
                                                            : <Shimmer><div className="shimmer" style={{ width: 168, height: 16 }}></div></Shimmer>
                                                    }
                                                </h5>
                                            </div>
                                            <div className="mt-4">
                                                <p className="mb-1">Nama Unit :</p>
                                                <h5 className="font-size-16">
                                                    {
                                                        this.state.dataLoaded ?
                                                            <>{this.state.employeeCurrentWorkUnitName !== "" ? this.state.employeeCurrentWorkUnitName : '-'}</>
                                                            : <Shimmer><div className="shimmer" style={{ width: 188, height: 16 }}></div></Shimmer>
                                                    }
                                                </h5>
                                            </div>
                                            <div className="mt-4">
                                                <p className="mb-1">Nama Jabatan :</p>
                                                <h5 className="font-size-16">
                                                    {
                                                        this.state.dataLoaded ?
                                                            <>{this.state.employeeCurrentPositionName !== "" ? this.state.employeeCurrentPositionName : '-'}</>
                                                            : <Shimmer><div className="shimmer" style={{ width: 148, height: 16 }}></div></Shimmer>
                                                    }
                                                </h5>
                                            </div>





                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl="8">
                            <Card>
                                <CardBody>
                                    <CardTitle>Aktifitas Aplikasi</CardTitle>
                                    <CardSubtitle className="mb-3">
                                        Daftar aktifitas saat mengakses aplikasi
                                    </CardSubtitle>
                                    <hr />
                                    <DataTableComponent />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    }
}

export default IndexView