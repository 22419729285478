import { Subject } from "rxjs";
import apiResponse from "../../../../services/apiResponse";
import DataTableRepository from "../Repositories/DataTableRepository";

class DataTableBloc {
  rowtableChannel = new Subject();
  deleteChannel = new Subject();
  deleteAllChannel = new Subject();

  repository = new DataTableRepository();

  constructor() {
    this.rowtableChannel.next({ status: apiResponse.INITIAL });
    this.deleteChannel.next({ status: apiResponse.INITIAL });
    this.deleteAllChannel.next({ status: apiResponse.INITIAL });
  }

  fetchRowTable = async (query, cancelToken) => {
    this.rowtableChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchRowTable(query, cancelToken)
        .then((result) => {
          this.rowtableChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.rowtableChannel.next({ status: apiResponse.ERROR, data: error });
        });
    } catch (error) {}
  };

  fetchDeleteService = async (query, cancelToken) => {
    this.deleteChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchDeleteKeyword(query, cancelToken)
        .then((result) => {
          this.deleteChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.deleteChannel.next({ status: apiResponse.ERROR, data: error });
        });
    } catch (error) {}
  };

  fetchDeleteAllService = async (query, cancelToken) => {
    this.deleteAllChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchDeleteAllKeyword(query, cancelToken)
        .then((result) => {
          this.deleteAllChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.deleteAllChannel.next({ status: apiResponse.ERROR, data: error });
        });
    } catch (error) {}
  };
}

export default DataTableBloc
