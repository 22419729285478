import { apiRequest, guideCategoryList, guideCategoryDelete } from "../../../../services/adapters/base";

class DataTableRepository {
  fetchRowTable = async (query, cancelToken) => {
    return await apiRequest(
      "get",
      guideCategoryList,
      {
        cancelToken: cancelToken,
        params: query,
      },
      true
    );
  };

  fetchDeleteGuideCategory = async (query, cancelToken) => {
    return await apiRequest(
      "post",
      guideCategoryDelete,
      {
        cancelToken: cancelToken,
        body: query,
      },
      true
    );
  };
}

export default DataTableRepository;
