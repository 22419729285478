import { apiRequest, keywordCreate, getRedirectLinkList } from "../../../../services/adapters/base";

class CreateRepository {
  fetchCreate = async (query) => {
    return await apiRequest(
      "post",
      keywordCreate,
      {
        body: query,
      },
      true
    );
  };

  fetchRedirectLink = async (query, cancelToken) => {
    return await apiRequest(
      "get",
      getRedirectLinkList,
      {
        cancelToken: cancelToken,
        params: query,
      },
      true
    );
  };
}

export default CreateRepository;
