import { AvCheckbox, AvCheckboxGroup, AvForm } from "availity-reactstrap-validation";
import React, { Component, Fragment } from 'react';
import { Link, Redirect } from "react-router-dom";
import {
  Button, Card, CardBody, CardSubtitle, CardTitle, Col, Container,
  Row
} from "reactstrap";
import { ConfirmAlert } from '../../../helpers/ui/alert';
import CustomSelect from "../../../helpers/ui/CustomSelect";
import { cancelToken } from "../../../services/adapters/base";
import apiResponse from '../../../services/apiResponse';
import CreateBloc from './Blocs/CreateBloc';

let reformatTypeHelpdeskService = function (data) {
  return data.map(function (service) {
    let newObj = {};
    newObj["label"] = service.helpdesk_service_name;
    newObj["value"] = service.helpdesk_service_id;
    return newObj;
  });
}

let reformatTypeHelpdeskModule = function (data) {
  return data.map(function (module) {
    let newObj = {};
    newObj["label"] = module.helpdesk_module_name;
    newObj["value"] = module.helpdesk_module_id;
    newObj["disabled"] = module.helpdesk_pic.some(item => item.helpdesk_layer === 1) && module.helpdesk_pic.some(item => item.helpdesk_layer === 2);
    newObj["helpdesk_pic"] = module.helpdesk_pic    
    return newObj;
  });
}

let reformatTypeUser = function (data) {
  return data.map(function (user) {
    let newObj = {};
    newObj["label"] = `${user.employee_nip} | ${user.employee_name}`;
    newObj["value"] = user.employee_uuid;
    return newObj;
  });
}

class IndexView extends Component {

  createBloc = new CreateBloc();

  constructor(props) {
    super(props)
    this.state = {
      dataForm: {},
      optLayer: [
        {
          label: "PIC",
          value: "1"
        },
        {
          label: "Pusdatin",
          value: "2"
        }
      ],
      selected_helpdesk_layer: null
    }
  }

  handleServiceChange = (e) => {
    this.setState({
      dataForm: {
        ...this.state.dataForm,
        ["helpdesk_service_id"]: e.value,
      },
      optModules: reformatTypeHelpdeskModule(this.state.services.filter((helpdeskService) => helpdeskService.helpdesk_service_id == e.value)[0].modules),
      selected_helpdesk_layer: null
    });
  };

  handleModuleChange = (e) => {
    this.setState({
      dataForm: {
        ...this.state.dataForm,
        ["helpdesk_module_id"]: e.value,        
      },
      optLayer: [
        {
          label: "PIC",
          value: "1",
          disabled: e.helpdesk_pic.some(item => item.helpdesk_layer === 1)
        },
        {
          label: "Pusdatin",
          value: "2",
          disabled: e.helpdesk_pic.some(item => item.helpdesk_layer === 2)
        }
      ],
      selected_helpdesk_layer: null
    });
  };

  handlePicChange = (e) => {
    this.setState({
      dataForm: {
        ...this.state.dataForm,
        ["helpdes_pic_employee_uuid"]: e.value,        
      },
    });
  };

  handleLayerChange = (e) => {
    this.setState({
      dataForm: {
        ...this.state.dataForm,
        ["helpdesk_layer"]: e.value,     
      },
      selected_helpdesk_layer: e.value
    });
  };

  submitData = async () => {
    await this.createBloc.fetchCreate(this.state.dataForm)
  }

  handlerOnSubmit = (e, values) => {
    e.preventDefault()
    this.setState({
      dataForm: values,
      confirm: true
    })
  }

  confirmResponse = (response) => {
    let forState = {}
    switch (response) {
      case 'cancel':
        forState = {
          confirm: false
        }
        break;
      case 'confirm':
        forState = {
          loading: true,
          confirm: false
        }
        this.submitData()
        break;
      case 'success':
        forState = {
          success: false,
          redirect: true
        }
        break;
      case 'failed':
        forState = {
          failed: false
        }
        break;
      default:
    }
    this.setState(forState)
  }

  componentDidMount() {
    this.setTokenAPI();
    this.createBloc.createChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          this.setState({
            loading: false,
            success: true,
          });
          break
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            failed: true,
          });
          break
        default:
          break
      }
    })

    this.createBloc.servicesChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          const response = result.data.response;
          this.setState({
            services: response.result,
            optServices: reformatTypeHelpdeskService(response.result),
          });
          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });

    this.createBloc.usersChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          const response = result.data.response;
          this.setState({
            optUsers: reformatTypeUser(response),
          });
          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });
  }

  setTokenAPI = () => {
    if (typeof this.source != typeof undefined) {
      this.source.cancel();
    }
    this.source = cancelToken();
    this.loadData();
  };

  loadData = async () => {
    const query = {
      search: null,
      limit: null,
      offset: null,
      order: null,
      sort: "asc",
    };

    await this.createBloc.fetchServices(query, this.source.token);
    await this.createBloc.fetchUsers(query, this.source.token);
  };


  componentWillUnmount() {
    this.createBloc.createChannel.unsubscribe();
    this.createBloc.usersChannel.unsubscribe();
    this.createBloc.servicesChannel.unsubscribe();
  }

  render() {
    return (
      <Fragment>
        <Container fluid>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>Buat Data Mapping PIC</CardTitle>
                  <CardSubtitle className="mb-3">
                    Buat data mapping pic baru dengan mengisi form di bawah ini
                  </CardSubtitle>
                  <hr />
                  <AvForm
                    className="needs-validation"
                    onValidSubmit={(e, values) => this.handlerOnSubmit(e, values)}
                  >
                    <Row className="mb-3">
                      <label
                        htmlFor="fielCode"
                        className="col-md-3 col-form-label"
                      >
                        Layanan
                      </label>
                      <Col md="9">
                        <CustomSelect
                          name="helpdesk_service"
                          id="helpdesk_service"
                          errorMessage="Silahkan pilih layanan"
                          options={this.state.optServices}
                          validate={{ required: { value: true } }}
                          onChange={this.handleServiceChange}
                          placeholder="Pilih layanan" />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="fielCode"
                        className="col-md-3 col-form-label"
                      >
                        Modul
                      </label>
                      <Col md="9">
                        <CustomSelect
                          name="helpdesk_module_id"
                          id="helpdesk_module_id"
                          errorMessage="Silahkan pilih modul"
                          options={this.state.optModules}
                          validate={{ required: { value: true } }}
                          onChange={this.handleModuleChange}
                          placeholder={this.state.optModules == null ? "Silahkan pilih layanan terlebih dahulu" : "Pilih modul"} />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="fielCode"
                        className="col-md-3 col-form-label"
                      >
                        PIC
                      </label>
                      <Col md="9">
                        <CustomSelect
                          name="helpdesk_pic_employee_uuid"
                          id="helpdesk_pic_employee_uuid"
                          errorMessage="Silahkan pilih PIC"
                          options={this.state.optUsers}
                          validate={{ required: { value: true } }}
                          onChange={this.handlePicChange}
                          placeholder="Pilih PIC" />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <label
                        htmlFor="fielCode"
                        className="col-md-3 col-form-label"
                      >
                        Layer
                      </label>
                      <Col md="9">
                        {this.state.optLayer && (
                          <CustomSelect
                            name="helpdesk_layer"
                            id="helpdesk_layer"
                            errorMessage="Silahkan pilih layer"
                            options={this.state.optLayer}
                            defaultValue={this.state.selected_helpdesk_layer}
                            validate={{ required: { value: true } }}
                            onChange={this.handleLayerChange}
                            placeholder="Pilih layer" />
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md="3"></Col>
                      <Col md="9">
                        <div className="form-check form-switch mb-3">
                          <AvCheckboxGroup name="checkboxExample" required errorMessage="Agreement harus di centang">
                            <AvCheckbox label="Saya yakin dan bertanggung jawab atas data ini" value="1" />
                          </AvCheckboxGroup>

                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md="3"></Col>
                      <Col md="9">
                        <Link to="/admin/helpdesk_mapping_pic.html">
                          <Button color="danger"><i className="uil-arrow-left"></i> Kembali</Button>
                        </Link>
                        {' '}
                        <Button color="success" type="submit">
                          <i className="uil-arrow-up-right"></i> Simpan
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan menyimpan data ini?"

          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"

          successTitle="Berhasil!"
          successInfo="Data berhasil disimpan"

          failedTitle="Gagal!"
          failedInfo="Data gagal disimpan"

          showConfirm={this.state.confirm}
          showLoading={this.state.loading}
          showSuccess={this.state.success}
          showFailed={this.state.failed}

          response={this.confirmResponse}
        />
        {this.state.redirect ?
          <Redirect to='/admin/helpdesk_mapping_pic.html' />
          : null}
      </Fragment>
    )
  }

}

export default IndexView