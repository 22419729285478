import {
    apiRequest,
    agendaList,
    agendaActive,
    agendaInactive
} from "../../../../services/adapters/base"

class DataTableRepository {

    fetchRowTable = async (query, cancelToken) => {
        return await apiRequest("get", agendaList, {
            cancelToken: cancelToken,
            params: query
        }, true)
    }

    fetchInactive = async (query) => {
        return await apiRequest("post", agendaInactive, {
            body: query
        }, true)
    }

    fetchActive = async (query) => {
        return await apiRequest("post", agendaActive, {
            body: query
        }, true)
    }
}

export default DataTableRepository
