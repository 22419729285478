import { apiRequest, serviceFeatureDetail } from "../../../../services/adapters/base";

class DetailRepository {
	fetchDetail = async (query) => {
		return await apiRequest(
			"get",
			serviceFeatureDetail,
			{
				params: query,
			},
			true
		);
	};
}

export default DetailRepository;
