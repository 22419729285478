import React, { Fragment, Component } from "react";
import { withRouter } from "react-router";
import {
  Col,
  Container,
  Row,
  Spinner,
  Card,
  CardBody,
  Button,
} from "reactstrap";
import IndexBloc from "../Blocs/IndexBloc";
import { Bar } from "react-chartjs-2";
import apiResponse from "../../../../../services/apiResponse";

class IndexView extends Component {
  indexBloc = new IndexBloc();

  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.state = {
      data: null,
      loading: false,
      hoveredDesc: false,
      errorMessage: null,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    this.setState({ loading: true, errorMessage: null });

    this.indexBloc.getAllNetworkTraffic1MonthChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          let response = result.data.response;
          let errormessage = result.data.message;
          this.setState({
            data: response,
            loading: false,
            errorMessage: errormessage,
          });
          break;
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            errorMessage: "Failed to load data. Please try again.",
          });
          break;
        default:
          break;
      }
    });

    this.indexBloc.fetchAllGraphNetworkTrafficNow1Month({});
  };

  createChart = () => {
    const { selectedHost } = this.props;
    const { data } = this.state;

    const defaultChartData = {
      labels: [],
      datasets: [],
    };

    if (!selectedHost) {
      return defaultChartData;
    }

    const value = selectedHost.value;

    const specificHost = Array.isArray(data)
      ? data.find((item) => item.host.hostid === value)
      : null;

    const graphId = specificHost ? specificHost.graph.graphid : null;

    const specificGraph = Array.isArray(this.state.data)
      ? this.state.data.find((item) => item.graph.graphid === graphId)
      : null;

    const items =
      specificGraph && Array.isArray(specificGraph.graph.items)
        ? specificGraph.graph.items
        : [];

    const convertToKbps = (value) => {
      if (value.includes("Mbps")) {
        return parseFloat(value.replace(" Mbps", "")) * 1000;
      } else if (value.includes("Kbps")) {
        return parseFloat(value.replace(" Kbps", ""));
      }
      return parseFloat(value.replace(" bps", "")) / 1000;
    };

    const predefinedColors = [
      "rgba(0, 130, 0, 0.8)",
      "rgba(214, 68, 0, 0.8)",
      "rgba(0, 238, 0, 0.8)",
      "rgba(255, 176, 0, 0.8)",
      "rgba(255, 24, 169, 0.8)",
      "rgba(137, 8, 241, 0.8)",
    ];

    const labels =
      items.length > 0 ? items[0].values.map((v) => v.timestamp) : [];

    const datasets = items.map((item, index) => {
      const color = predefinedColors[index % predefinedColors.length];
      const data =
        Array.isArray(item.values) && item.values.length > 0
          ? item.values.map((v) => convertToKbps(v.last))
          : [];
      return {
        label: item.name,
        data: data,
        backgroundColor: color,
        borderColor: color.replace("0.8", "1"),
        borderWidth: 1,
      };
    });

    const graph = {
      labels,
      datasets,
    };

    const options = {
      responsive: true,
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: "Kbps",
          },
        },
      },
    };

    return { graph, options };
  };

  renderNetworkInfo = () => {
    const { selectedHost } = this.props;
    const { data } = this.state;

    if (!selectedHost) {
      return <div>Tidak ada host yang dipilih.</div>;
    }

    const value = selectedHost.value;

    const specificHost = Array.isArray(data)
      ? data.find((item) => item.host.hostid === value)
      : null;

    const graphId = specificHost ? specificHost.graph.graphid : null;

    const specificGraph = Array.isArray(this.state.data)
      ? this.state.data.find((item) => item.graph.graphid === graphId)
      : null;

    const items =
      specificGraph && Array.isArray(specificGraph.graph.items)
        ? specificGraph.graph.items
        : [];

    if (items.length === 0) {
      return <div>Tidak ada informasi tersedia untuk host yang dipilih.</div>;
    }

    return (
      <div>
        <div>
          <div style={{ fontWeight: "bold", fontSize: "20px" }}>
            Informasi{" "}
            <span
              className="uil-info-circle"
              onMouseEnter={() => this.setState({ hoveredDesc: true })}
              onMouseLeave={() => this.setState({ hoveredDesc: false })}
              style={{ cursor: "pointer" }}
            />
          </div>
          <br />
        </div>
        {this.state.hoveredDesc && (
          <div
            style={{
              position: "absolute",
              background: "#f4f4f4",
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "10px",
              zIndex: 1000,
              width: "1000px",
              boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
            }}
          >
            {/* {this.renderNetworkDescriptions()} */}
            {items.length === 2
              ? this.renderDescriptionsTwo()
              : this.renderNetworkDescriptions()}
          </div>
        )}

        {items.map((item, index) => (
          <div key={index}>
            <strong>{item.name}</strong> : <strong>Last:</strong>{" "}
            {item.last !== null ? item.last : "0.00 bps"} <strong>|</strong>{" "}
            <strong>Min:</strong> {item.min !== null ? item.min : "0.00 bps"}{" "}
            <strong>|</strong> <strong>Average:</strong>{" "}
            {item.average !== null ? item.average : "0.00 bps"}{" "}
            <strong>|</strong> <strong>Max:</strong>{" "}
            {item.max !== null ? item.max : "0.00 bps"}
          </div>
        ))}
      </div>
    );
  };

  renderNetworkDescriptions = () => {
    const { selectedHost } = this.props;
    const { data } = this.state;

    const value = selectedHost.value;

    const specificHost = Array.isArray(data)
      ? data.find((item) => item.host.hostid === value)
      : null;

    const graphId = specificHost ? specificHost.graph.graphid : null;

    const specificGraph = Array.isArray(this.state.data)
      ? this.state.data.find((item) => item.graph.graphid === graphId)
      : null;

    const items =
      specificGraph && Array.isArray(specificGraph.graph.items)
        ? specificGraph.graph.items
        : [];

    const desc = [
      {
        description:
          "Metrik ini menunjukkan jumlah paket data yang diterima oleh antarmuka jaringan namun dibuang sebelum diproses lebih lanjut.",
      },
      {
        description:
          "Metrik ini mengukur jumlah paket yang masuk ke antarmuka yang mengalami kesalahan, seperti bit error, framing error, atau masalah lainnya yang membuat paket tidak dapat diproses dengan benar.",
      },
      {
        description:
          "Metrik ini memberikan gambaran tentang seberapa banyak data yang masuk melalui antarmuka jaringan dalam suatu periode waktu tertentu.",
      },
      {
        description:
          "Metrik ini menunjukkan jumlah paket yang dikirim melalui antarmuka yang dibuang sebelum benar-benar dikirim ke tujuan.",
      },
      {
        description:
          "Ini mengukur jumlah paket yang dikirim melalui antarmuka yang mengalami kesalahan dalam proses pengiriman, seperti kesalahan sinyal atau masalah dalam lapisan fisik jaringan, sehingga paket tersebut tidak dapat dikirim dengan benar.",
      },
      {
        description:
          "Metrik yang menunjukkan jumlah data yang dikirim melalui antarmuka dalam bentuk bit per detik (bps).",
      },
    ];

    return (
      <div>
        {items.map((item, index) => (
          <div key={index}>
            <strong>{item.name}:</strong> {desc[index]?.description}
          </div>
        ))}
      </div>
    );
  };

  //JIKA DATANYA ADA 2
  renderDescriptionsTwo = () => {
    const { selectedHost } = this.props;
    const { data } = this.state;

    const value = selectedHost.value;

    const specificHost = Array.isArray(data)
      ? data.find((item) => item.host.hostid === value)
      : null;

    const graphId = specificHost ? specificHost.graph.graphid : null;

    const specificGraph = Array.isArray(this.state.data)
      ? this.state.data.find((item) => item.graph.graphid === graphId)
      : null;

    const items =
      specificGraph && Array.isArray(specificGraph.graph.items)
        ? specificGraph.graph.items
        : [];

    const desc = [
      {
        description:
          "Metrik yang menunjukkan jumlah data yang dikirim melalui antarmuka dalam bentuk bit per detik (bps).",
      },
      {
        description:
          "Metrik ini memberikan gambaran tentang seberapa banyak data yang masuk melalui antarmuka jaringan dalam suatu periode waktu tertentu.",
      },
    ];

    return (
      <div>
        {items.map((item, index) => (
          <div key={index}>
            <strong>{item.name}:</strong> {desc[index]?.description}
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { loading, errorMessage } = this.state;

    return (
      <Fragment>
        <Container fluid>
          <Row>
            <Col className="col-12">
              {loading ? (
                <div className="text-center">
                  <div>
                    <Spinner
                      style={{
                        width: "3rem",
                        height: "3rem",
                        borderWidth: "0.4rem",
                      }}
                      color="dark"
                    />
                  </div>
                  <div>
                    <span style={{ fontWeight: "bold" }}>
                      Mengambil Data ...
                    </span>
                  </div>
                </div>
              ) : errorMessage ? (
                <Card className="text-center">
                  <br />
                  <CardBody>
                    <h5 className="text-dark">{errorMessage}</h5>
                    <br />
                    <Button color="danger" onClick={this.fetchData}>
                      Muat Ulang
                    </Button>
                  </CardBody>
                </Card>
              ) : (
                <div>
                  <Row>
                    <Col className="col-12">
                      <Bar
                        data={this.createChart().graph}
                        options={this.createChart().options}
                      />
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <hr />
                    <Col className="col-12">{this.renderNetworkInfo()}</Col>
                  </Row>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default withRouter(IndexView);
