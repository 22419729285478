import { apiRequest, guideCategoryUpdate, guideCategoryDetail } from "../../../../services/adapters/base";

class UpdateRepository {
  fetchUpdate = async (query) => {
    return await apiRequest(
      "post",
      guideCategoryUpdate,
      {
        body: query,
      },
      true
    );
  };

  fetchDetail = async (query) => {
    return await apiRequest(
      "get",
      guideCategoryDetail,
      {
        params: query,
      },
      true
    );
  };
}

export default UpdateRepository;
