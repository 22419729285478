import {
    apiRequest,
    agendaDetail
} from "../../../../services/adapters/base"

class DetailRepository {

    fetchDetail = async (query) => {
        return await apiRequest("get", agendaDetail, {
            params: query
        }, true)
    }
}

export default DetailRepository
