import React, { Component, Fragment } from "react";
import { config } from "../../../../../helpers/config_global";
import MKIDatatable from "../../../../../modules/mki-datatable/lib";
import { cancelToken } from "../../../../../services/adapters/base";
import apiResponse from "../../../../../services/apiResponse";
import DataTableBloc from "../Blocs/DataTableBloc";
import { Alert } from "reactstrap";

const formatDateTime = (timestamp) => {
  const optionsDate = { year: "numeric", month: "long", day: "numeric" };
  const date = new Date(timestamp);

  const indonesianDate = new Date(date.getTime() + 7 * 60 * 60 * 1000);

  const formattedDate = indonesianDate.toLocaleDateString("id-ID", optionsDate);
  const hours = String(indonesianDate.getHours()).padStart(2, "0");
  const minutes = String(indonesianDate.getMinutes()).padStart(2, "0");

  return `${formattedDate} - ${hours}:${minutes}`;
};

class TableComponent extends Component {
  dataTableBloc = new DataTableBloc();

  source;
  historyTable = "historySchedulerDetailCategory";
  defaultOrder = "cron_job_id";
  defaultSize = 10;
  defaultSort = "asc";
  configDatatable = {
    ...config("datatable"),
    sort: {
      column: this.defaultOrder,
      order: this.defaultSort,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      tooltip: false,
      detailId: null,
      updateId: null,
      deleteId: null,
      notulenUuid: null,
      defaultSize: 10,
      rows: [],
      totalRow: 0,
      data: [],
      showDetail: false,
      currentAlertIndex: 0,
      hoveredRow: null,
      loadingTable: true,
      filter: {
        filter_value: "",
        page_number: 1,
        page_size: this.defaultSize,
        sort_order: {
          column: this.defaultOrder,
          order: this.defaultSort,
        },
      },
    };
  }
  toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

  loadData = async () => {
    /* history Datatable */
    sessionStorage.setItem(
      this.historyTable,
      JSON.stringify(this.state.filter)
    );

    /* kirim ke server */
    let offset =
      (this.state.filter.page_number - 1) * this.state.filter.page_size;
    if (this.state.defaultSize !== this.state.filter.page_size) {
      this.setState({
        defaultSize: this.state.filter.page_size,
      });
      offset = 0;
      this.configDatatable.page_number = 1;
    }
    const query = {
      search: this.state.filter.filter_value,
      limit: this.state.filter.page_size,
      offset: offset,
      order: this.state.filter.sort_order.column,
      sort: this.state.filter.sort_order.order,
      status: 1,
      cronid: this.props.id,
    };
    await this.dataTableBloc.fetchDetailCronJob(query, this.source.token);
  };

  setTokenAPI = () => {
    if (typeof this.source != typeof undefined) {
      this.source.cancel();
    }
    this.source = cancelToken();
    this.loadData();
  };

  confirmResponse = (response) => {
    let forState = {};
    switch (response) {
      case "confirm":
        forState = {
          loading: true,
          confirm: false,
        };
        this.deleteService();
        break;
      case "success":
        forState = {
          success: false,
          redirect: true,
        };
        break;
      case "failed":
        forState = {
          failed: false,
        };
        break;
      case "cancel":
        forState = {
          confirm: false,
        };
        break;
      default:
    }
    this.setState(forState);
  };

  componentDidMount() {
    this.setTokenAPI();

    this.dataTableBloc.getDetailCronJobChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.INITIAL:
          this.setState({
            loadingTable: false,
            alerts: [],
          });
          break;
        case apiResponse.LOADING:
          this.setState({
            loadingTable: true,
          });
          break;
        case apiResponse.COMPLETED:
          const response = result.data.response;
          const rows = response.logs.map((log) => {
            return {
              cronJobId: log.cron_job_id,
              waktuSelesai: log.done_time,
              kesalahan: log.error,
              httpCode: log.http_code,
              waktuPemicu: log.fired_time,
              waktuJadwal: log.scheduled_time,
              totalWaktu: log.total_time,
            };
          });

          this.setState({
            data: rows,
            loadingTable: false,
          });

          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });
  }

  componentWillUnmount() {
    this.dataTableBloc.getDetailCronJobChannel.unsubscribe();
  }

  columns = [
    {
      key: "waktuSelesai",
      text: "Waktu Selesai",
      className: "waktuSelesai",
      sortable: true,
      cell: (record) => {
        return (
          <div>
            <span>{formatDateTime(record.waktuSelesai)}</span>
          </div>
        );
      },
    },
    {
      key: "kesalahan",
      text: "Pesan Error",
      className: "kesalahan",
      sortable: true,
    },
    {
      key: "waktuPemicu",
      text: "Waktu Pemicu",
      className: "waktuPemicu",
      sortable: true,
      cell: (record) => {
        return (
          <div>
            <span>{formatDateTime(record.waktuPemicu)}</span>
          </div>
        );
      },
    },
    {
      key: "waktuJadwal",
      text: "Waktu yang Dijadwalkan",
      className: "waktuJadwal",
      sortable: true,
      cell: (record) => {
        return (
          <div>
            <span>{formatDateTime(record.waktuJadwal)}</span>
          </div>
        );
      },
    },
    {
      key: "totalWaktu",
      text: "Total Waktu",
      className: "totalWaktu",
      sortable: true,
    },
    {
      key: "httpCode",
      text: "HTTP Code",
      className: "httpCode",
      sortable: true,
    },
    {
      key: "httpCode",
      text: "Status",
      className: "httpCode",
      sortable: true,
      cell: (record) => {
        if (record.httpCode === 200) {
          return (
            <div
              className="badge"
              style={{
                backgroundColor: "#06D001",
                padding: 5,
                marginBottom: "5px",
                marginRight: "5px",
                color: "white",
              }}
            >
              <span style={{ fontSize: 12 }}>Succeeded</span>
            </div>
          );
        } else if (record.httpCode !== 200) {
          return (
            <div
              className="badge"
              style={{
                backgroundColor: "#ff0000",
                padding: 5,
                marginBottom: "5px",
                marginRight: "5px",
                color: "white",
              }}
            >
              <span style={{ fontSize: 12 }}>Failed</span>
            </div>
          );
        }
      },
    },
  ];

  render() {
    return (
      <>
        <Alert color="primary">
          <div style={{ fontWeight: "bold", fontSize: "18px" }}>Informasi</div>
          <br />
          <ul style={{ fontSize: "15px", paddingLeft: "20px" }}>
            <li>
              <strong>Waktu Selesai : </strong>
              Waktu ketika pekerjaan cron selesai dijalankan.
            </li>
            <li>
              <strong>Kesalahan : </strong>
              Pesan kesalahan jika ada (kosong berarti tidak ada kesalahan).
            </li>
            <li>
              <strong>Waktu Eksekusi : </strong>
              Lama waktu yang dibutuhkan untuk mengeksekusi pekerjaan (Dihitung
              dalam Detik).
            </li>
            <li>
              <strong>Waktu Pemicu : </strong>
              Waktu ketika pekerjaan cron dijadwalkan untuk dijalankan.
            </li>
            <li>
              <strong>Waktu yang Dijadwalkan : </strong>
              Waktu ketika pekerjaan dijadwalkan untuk dijalankan.
            </li>
            <li>
              <strong>Total Waktu : </strong>
              Total waktu yang dihabiskan untuk menjalankan pekerjaan (Dihitung
              dalam Detik).
            </li>
          </ul>
        </Alert>
        <MKIDatatable
          className={this.configDatatable.tableClass}
          records={this.state.data}
          columns={this.columns}
          withNumber={false}
          minWidth={1000}
          dynamic={false}
          total_record={this.state.totalRow}
          initial={this.state.filter}
          config={{
            ...MKIDatatable.defaultProps.config,
            ...this.configDatatable,
            show_filter: false,
          }}
          onChange={(filter) => {
            this.setState(
              {
                loadingTable: true,
                filter: filter,
              },
              function () {
                this.setTokenAPI();
              }
            );
          }}
          loading={this.state.loadingTable}
        />
      </>
    );
  }
}

export default TableComponent;
