import { apiRequest, keywordList, keywordDelete, keywordDeleteAll } from "../../../../services/adapters/base";

class DataTableRepository {
  fetchRowTable = async (query, cancelToken) => {
    return await apiRequest(
      "get",
      keywordList,
      {
        cancelToken: cancelToken,
        params: query,
      },
      true
    );
  };

  fetchDeleteKeyword = async (query, cancelToken) => {
    return await apiRequest(
      "post",
      keywordDelete,
      {
        cancelToken: cancelToken,
        body: query,
      },
      true
    );
  };

  fetchDeleteAllKeyword = async (query, cancelToken) => {
    return await apiRequest(
      "post",
      keywordDeleteAll,
      {
        cancelToken: cancelToken,
        body: query,
      },
      true
    );
  };
}

export default DataTableRepository;
