import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { Tooltip } from "reactstrap";
import { accessRole } from "../../../../helpers/access_role";
import { config } from "../../../../helpers/config_global";
import { ConfirmAlert } from "../../../../helpers/ui/alert";
import MKIDatatable from "../../../../modules/mki-datatable/lib";
import { cancelToken } from "../../../../services/adapters/base";
import apiResponse from "../../../../services/apiResponse";
import DataTableBloc from "../Blocs/DataTableBloc";

class TableActiveComponent extends Component {
  dataTableBloc = new DataTableBloc();

  source;
  historyTable = "historyGuideCategory";
  defaultOrder = "nlp_keyword_id";
  defaultSize = 10;
  defaultSort = "asc";
  configDatatable = {
    ...config("datatable"),
    sort: {
      column: this.defaultOrder,
      order: this.defaultSort,
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      tooltip: false,
      detailId: null,
      updateId: null,
      deleteId: null,
      notulenUuid: null,
      defaultSize: 10,
      rows: [],
      totalRow: 0,
      loadingTable: true,
      filter: {
        filter_value: "",
        page_number: 1,
        page_size: this.defaultSize,
        sort_order: {
          column: this.defaultOrder,
          order: this.defaultSort,
        },
      },
    };
  }
  toggleTooltip = () => this.setState({ tooltip: !this.state.tooltip });

  loadData = async () => {
    /* history Datatable */
    sessionStorage.setItem(
      this.historyTable,
      JSON.stringify(this.state.filter)
    );

    /* kirim ke server */
    let offset =
      (this.state.filter.page_number - 1) * this.state.filter.page_size;
    if (this.state.defaultSize !== this.state.filter.page_size) {
      this.setState({
        defaultSize: this.state.filter.page_size,
      });
      offset = 0;
      this.configDatatable.page_number = 1;
    }
    const query = {
      search: this.state.filter.filter_value,
      limit: this.state.filter.page_size,
      offset: offset,
      order: this.state.filter.sort_order.column,
      sort: this.state.filter.sort_order.order,
      status: 1
    };
    await this.dataTableBloc.fetchRowTable(query, this.source.token);
  };

  setTokenAPI = () => {
    if (typeof this.source != typeof undefined) {
      this.source.cancel();
    }
    this.source = cancelToken();
    this.loadData();
  };

  deleteService = async () => {
    await this.dataTableBloc.fetchDeleteService({
      nlp_keyword_id: this.state.deleteId,
    });
  };

  confirmResponse = (response) => {
    let forState = {};
    switch (response) {
      case "confirm":
        forState = {
          loading: true,
          confirm: false
        };
        this.deleteService();
        break;
      case "success":
        forState = {
          success: false,
          redirect: true,
        };
        break;
      case "failed":
        forState = {
          failed: false,
        };
        break;
      case "cancel":
        forState = {
          confirm: false
        };
        break;
      default:
    }
    this.setState(forState);
  };

  componentDidMount() {
    this.setTokenAPI();

    this.dataTableBloc.rowtableChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.INITIAL:
          this.setState({
            loadingTable: false,
          });
          break;
        case apiResponse.LOADING:
          this.setState({
            loadingTable: true,
          });
          break;
        case apiResponse.COMPLETED:
          const response = result.data.response;
          this.setState({
            totalRow: response.total,
            rows: response.result,
            loadingTable: false,
          });
          
          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });

    this.dataTableBloc.deleteChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          const response = result.data.response;
          this.setState({
            loading: false,
            success: true,
            loadingTable: true,
          }, function () {
            this.setTokenAPI()
          });
          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });
  }

  componentWillUnmount() {
    this.dataTableBloc.rowtableChannel.unsubscribe();
    this.dataTableBloc.deleteChannel.unsubscribe();
  }

  columns = [
    {
      key: "nlp_keyword_value",
      text: "Nama Keyword",
      className: "nlp_keyword_value",
      sortable: true,
    },
    {
      key: "nlp_keyword_type",
      text: "Jenis Keyword",
      className: "nlp_keyword_type",
      sortable: true,
      cell: (record) => {
        return record["nlp_keyword_type"] == 1 ? "Redirect Internal" : "Redirect External";
      }
    },
    {
      key: "nlp_keyword_redirect_link",
      text: "Url",
      className: "nlp_keyword_redirect_link",
      sortable: false,
    },
    {
      key: "action",
      text: "Action",
      className: "action",
      width: 150,
      sortable: false,
      align: "center",
      cell: (record) => {
        const thisId = record.portal_service_id;
        return (
          <Fragment>
            {/* <span>
              <button
                className="btn btn-primary btn-sm"
                id={'detail-' + thisId}
                onClick={() => {
                  this.setState({
                    detailId: record.nlp_keyword_id,
                  })
                }}
                style={{
                  marginRight: '2px',
                  marginBottom: '2px',
                  padding: '2px 5px',
                }}
              >
                <i className="uil-align-left"></i>
              </button>
              <Tooltip
                style={{
                  marginBottom: 2,
                }}
                placement="top"
                isOpen={this.state['detail' + thisId]}
                target={'detail-' + thisId}
                toggle={() => {
                  this.setState({
                    ['detail' + thisId]:
                      !this.state['detail' + thisId],
                  })
                }}
              >
                Detail
              </Tooltip>
            </span> */}
            {accessRole("update") ? (
              <span>
                <button
                  className="btn btn-primary btn-sm"
                  id={"update-" + thisId}
                  onClick={() => {
                    this.setState({
                      updateId: record.nlp_keyword_id,
                    });
                  }}
                  style={{
                    marginRight: "2px",
                    marginBottom: "2px",
                    padding: "2px 5px",
                  }}
                >
                  <i className="uil-pen"></i>
                </button>
                <Tooltip
                  style={{
                    marginBottom: 2,
                  }}
                  placement="top"
                  isOpen={this.state["update" + thisId]}
                  target={"update-" + thisId}
                  toggle={() => {
                    this.setState({
                      ["update" + thisId]: !this.state["update" + thisId],
                    });
                  }}
                >
                  Update
                </Tooltip>
              </span>
            ) : null}
            {accessRole("delete") ? (
              <span>
                <button
                  className="btn btn-danger btn-sm"
                  id={"delete-" + thisId}
                  onClick={() => {
                    this.setState({
                      deleteId: record.nlp_keyword_id,
                      confirm: true,
                    });
                  }}
                  style={{
                    padding: "2px 5px",
                    marginRight: "2px",
                    marginBottom: "2px",
                  }}
                >
                  <i className="uil-trash-alt"></i>
                </button>
                <Tooltip
                  style={{
                    marginBottom: 2,
                  }}
                  placement="top"
                  isOpen={this.state["delete" + thisId]}
                  target={"delete-" + thisId}
                  toggle={() => {
                    this.setState({
                      ["delete" + thisId]: !this.state["delete" + thisId],
                    });
                  }}
                >
                  Hapus
                </Tooltip>
              </span>
            ) : null}
          </Fragment>
        );
      },
    },
  ];

  render() {
    return (
      <>
        <MKIDatatable
          className={this.configDatatable.tableClass}
          config={this.configDatatable}
          records={this.state.rows}
          columns={this.columns}
					minWidth={1000}
          dynamic={true}
          total_record={this.state.totalRow}
          initial={this.state.filter}
          onChange={(filter) => {
            this.setState(
              {
                loadingTable: true,
                filter: filter,
              },
              function () {
                this.setTokenAPI();
              }
            );
          }}
          loading={this.state.loadingTable}
        />
        <ConfirmAlert
          confirmTitle="Konfirmasi!"
          confirmInfo="Apakah anda yakin akan menghapus data ini?"
          loadingTitle="Mengirim data..."
          loadingInfo="Tunggu beberapa saat"
          successTitle="Berhasil!"
          successInfo="Data berhasil dihapus."
          failedTitle="Gagal!"
          failedInfo="Data gagal dihapus."
          showConfirm={this.state.confirm}
          showLoading={this.state.loading}
          showSuccess={this.state.success}
          showFailed={this.state.failed}
          response={this.confirmResponse}
        />
        {this.state.detailId !== null ? (
          <Redirect to={"keyword.html?detail&id=" + this.state.detailId} />
        ) : null}
        {this.state.updateId !== null ? (
          <Redirect to={"keyword.html?update&id=" + this.state.updateId} />
        ) : null}
      </>
    );
  }
}

export default TableActiveComponent;
