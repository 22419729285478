import {
  apiRequest,
  agendaCreate,
  agendaNotulensi,
} from "../../../../services/adapters/base";

class CreateRepository {
  fetchCreate = async (query) => {
    return await apiRequest(
      "post",
      agendaCreate,
      {
        body: query,
      },
      true
    );
  };

  fetchNotulensi = async (query) => {
    return await apiRequest(
      "post",
      agendaNotulensi,
      {
        body: query,
      },
      true
    );
  };
}

export default CreateRepository;
