import { apiRequest, guideUpdate, guideDetail, guideCategoryList } from "../../../../services/adapters/base";

class UpdateRepository {
  fetchUpdate = async (query) => {
    return await apiRequest(
      "post",
      guideUpdate,
      {
        body: query,
      },
      true
    );
  };

  fetchDetail = async (query) => {
    return await apiRequest(
      "get",
      guideDetail,
      {
        params: query,
      },
      true
    );
  };

  fetchGuideCategories = async (query) => {
    return await apiRequest(
      "get",
      guideCategoryList,
      {
        body: query,
      },
      true
    );
  };
}

export default UpdateRepository;
