import React, { Fragment, Component } from "react";
import { withRouter } from "react-router";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import IndexBloc from "./Blocs/IndexBloc";
import Select from "react-select";
import apiResponse from "../../../../services/apiResponse";
import IndexComponent from "./Components/IndexComponent";
import { Alert } from "reactstrap";

class IndexView extends Component {
  indexBloc = new IndexBloc();

  constructor(props) {
    super(props);
    this.state = {
      detail: null,
      listNameHost: [],
      activeTab: "1",
      selectedHost: null,
      isLoading: true,
    };

    this.activeRef = React.createRef();
    this.inactiveRef = React.createRef();
  }

  reloadActiveRef = () => this.activeRef.current.setTokenAPI();
  reloadInactiveRef = () => this.inactiveRef.current.setTokenAPI();

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  handleServerChange = (selectedOption) => {
    this.setState({ selectedHost: selectedOption });
  };

  componentDidMount() {
    this.indexBloc.getallHostChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          let response = result.data.response;
          const hostOptions = response.map((item) => ({
            value: item.hostid,
            label: item.name,
          }));

          // const defaultHost = hostOptions.find(
          //   (option) => option.label === "server-rancher-master"
          // );
          const defaultHost = hostOptions.length > 0 ? hostOptions[0] : null;

          this.setState({
            listNameHost: hostOptions,
            selectedHost: defaultHost || null,
            isLoading: false,
          });
          break;
        case apiResponse.ERROR:
          this.setState({ isLoading: false });
          break;
        default:
          break;
      }
    });

    this.indexBloc.fetchAllHosts({});
  }

  render() {
    const { isLoading } = this.state;
    const renderTableView = () => {
      return (
        <>
          <Nav tabs>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: this.state.activeTab === "1",
                })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-home"></i>
                </span>
                <span className="d-none d-sm-block">
                  <span className="uil-cloud-block"></span> Grafik Total
                  Penyimpanan Disk
                </span>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent
            activeTab={this.state.activeTab}
            className="p-3 text-muted"
          >
            <TabPane tabId="1">
              <IndexComponent
                ref={this.activeRef}
                handleInactiveSuccess={this.reloadInactiveRef}
                selectedHost={this.state.selectedHost}
              />
            </TabPane>
          </TabContent>
        </>
      );
    };

    const renderView = () => {
      if (isLoading) {
        return (
          <div className="text-center">
            <div>
              <Spinner
                style={{ width: "3rem", height: "3rem", borderWidth: "0.4rem" }}
                color="dark"
              />
            </div>
            <div>
              <span style={{ fontWeight: "bold" }}>Mengambil Data ...</span>
            </div>
          </div>
        );
      } else {
        return renderTableView();
      }
    };
    return (
      <Fragment>
        <Container fluid>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Alert color="info">
                    <CardTitle>File Sistem</CardTitle>
                    <CardSubtitle>
                      Memantau ruang penyimpanan yang tersedia dan digunakan
                      pada sistem, memastikan bahwa kapasitas penyimpanan
                      dikelola dengan efisien dan mencegah potensi masalah
                      terkait ruang disk yang terbatas.
                    </CardSubtitle>
                  </Alert>
                  <hr />
                  <Row>
                    <Col md="3">
                      <label>Pilih Host : </label>
                      <br />
                      <Select
                        options={this.state.listNameHost}
                        placeholder="Pilih Host"
                        onChange={this.handleServerChange}
                        value={this.state.selectedHost}
                      />
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Card>
                      <CardBody>{renderView()}</CardBody>
                    </Card>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default withRouter(IndexView);
