import { apiRequest, helpdeskModuleUpdate, helpdeskServiceList, helpdeskModuleDetail } from "../../../../services/adapters/base";

class UpdateRepository {
  fetchUpdate = async (query) => {
    return await apiRequest(
      "post",
      helpdeskModuleUpdate,
      {
        body: query,
      },
      true
    );
  };

  fetchDetail = async (query) => {
    return await apiRequest(
      "get",
      helpdeskModuleDetail,
      {
        params: query,
      },
      true
    );
  };

  fetchServices = async (query, cancelToken) => {
    return await apiRequest(
      "get",
      helpdeskServiceList,
      {
        cancelToken: cancelToken,
        params: query,
      },
      true
    );
  };

}

export default UpdateRepository;
