import React, { Component, Fragment } from 'react'
import MinioClient from '../../services/minioClient'
import { Modal } from "reactstrap"

import Viewer, { Worker } from '@phuocng/react-pdf-viewer'
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css'

export default class PdfViewer extends Component {

	minioClient = new MinioClient()

	constructor(props) {
		super(props)
		this.state = {
            showModal: this.props.showModal,
            isMinio: this.props.minio,
            url: null
		}
	}

    componentWillReceiveProps(nextProps) {
        if(this.state.showModal !== nextProps.isOpen) {
            if(this.props.path !== nextProps.path || this.state.url === null) {
                this.setState({
                    url: null,
                    showModal: nextProps.isOpen
                }, () => {
                    this.checkUrl()
                })
            } else {
                this.setState({
                    showModal: nextProps.isOpen
                })
            }
        }
    }

    checkUrl = () => {
        if(this.state.isMinio) {
            this.minioClient.get(this.props.path).then((url) => {
                this.setState({
                    url: url
                })
            })
        } else {
            this.setState({
                url: this.props.path
            }, () => {
                alert(this.state.url)
            })
        }
    }

	render() {
		return (
			<Fragment>
				<Modal
                    className="modal-90w"
                    size="lg"
                    isOpen={this.state.showModal}
                    toggle={() => {
                        this.setState({
                            showModal: this.state.showModal
                        })
                    }}
                >
                    <div className="modal-header">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabel"
                        >
                            { this.props.title }
                        </h5>
                        <button
                            onClick={() => {
                                this.setState({
                                    showModal: false
                                })
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {
                            this.state.url != null ?
                                <>
                                    <Worker workerUrl={ process.env.PUBLIC_URL + "/pdf.worker.js"}>
                                        <div style={{ height: window.innerHeight-140 }}>
                                            <Viewer
                                                fileUrl={this.state.url}
                                            />
                                        </div>
                                    </Worker>
                                </>
                            :
                            'Mengambil file...'
                        }
                    </div>
                </Modal>
			</Fragment>
		)
	}
} PdfViewer.defaultProps = { 
	showModal: false,
	minio: false,
    title: 'Viewer',
	path: ''
}