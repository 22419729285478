import { accessRole } from "../../helpers/access_role";
import ErrorAccess from "../../components/Error/Content/Access";

import IndexView from "./Index/View";

const RouteView = (props) => {
  const queryParams = new URLSearchParams(props.location.search);
  const _delete = queryParams.get("delete");

  if (_delete != null) {
    return null;
  }
  return accessRole("read") ? <IndexView /> : <ErrorAccess />;
};
export default RouteView;
