import classnames from "classnames";
import React, { Fragment, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { accessRole } from "../../../helpers/access_role";
import CalendarView from "./Components/CalendarView";
import TableActiveComponent from "./Components/TableActiveComponent";
import TableInactiveComponent from "./Components/TableInactiveComponent";

const IndexView = (props) => {
  const [activeTab, setactiveTab] = useState("1");
  const [isCalendarView, setIsCalendarView] = useState(false);
  const activeRef = useRef();
  const inactiveRef = useRef();

  const reloadActiveRef = () => activeRef.current.setTokenAPI();
  const reloadInactiveRef = () => inactiveRef.current.setTokenAPI();

  function toggle(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  }

  const calendarSwitcher = (e) => {
    if (e.currentTarget.dataset.type === "calendar-view") {
      setIsCalendarView(true);
      renderView();
    } else {
      setIsCalendarView(false);
      renderView();
    }
  };

  const renderCalendarView = () => {
    return <CalendarView />;
  };

  const renderTableView = () => {
    return (
      <>
        <Nav tabs>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "1",
              })}
              onClick={() => {
                toggle("1");
              }}
            >
              <span className="d-block d-sm-none">
                <i className="fas fa-home"></i>
              </span>
              <span className="d-none d-sm-block">Aktif</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "2",
              })}
              onClick={() => {
                toggle("2");
              }}
            >
              <span className="d-block d-sm-none">
                <i className="far fa-envelope"></i>
              </span>
              <span className="d-none d-sm-block">Tidak Aktif</span>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="p-3 text-muted">
          <TabPane tabId="1">
            <TableActiveComponent
              ref={activeRef}
              handleInactiveSuccess={reloadInactiveRef}
            />
          </TabPane>
          <TabPane tabId="2">
            <TableInactiveComponent
              ref={inactiveRef}
              handleActiveSuccess={reloadActiveRef}
            />
          </TabPane>
        </TabContent>
      </>
    );
  };

  const renderView = () => {
    return isCalendarView ? renderCalendarView() : renderTableView();
  };

  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="float-end">
                  <div className="d-flex" style={{ columnGap: "1rem" }}>
                    <div
                      className="view-container d-flex"
                      style={{ columnGap: ".5rem" }}
                    >
                      <Button
                        outline={isCalendarView ? true : false}
                        color="primary"
                        title="Tampilan Tabel"
                        id="calendar-view-btn"
                        onClick={calendarSwitcher}
                        data-type="table-view"
                      >
                        <i className="uil-grid"></i>
                      </Button>
                      <Button
                        outline={isCalendarView ? false : true}
                        color="primary"
                        title="Tampilan Kalender"
                        id="table-view-btn"
                        onClick={calendarSwitcher}
                        data-type="calendar-view"
                      >
                        <i className="uil-calendar-alt"></i>
                      </Button>
                    </div>
                    {/* {accessRole("create") ? (
                      <Link to="agenda.html?create">
                        <Button color="primary">Buat Agenda</Button>
                      </Link>
                    ) : null} */}
                  </div>
                </div>
                <CardTitle>Agenda</CardTitle>
                <CardSubtitle className="mb-3">Data - data agenda</CardSubtitle>
                <hr />
                {renderView()}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default IndexView;
