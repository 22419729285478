import { Subject } from "rxjs";
import apiResponse from "../../../../services/apiResponse";
import DataTableRepository from "../Repositories/DataTableRepository";

class DataTableBloc {
  rowtableChannel = new Subject();
  closetableChannel = new Subject();
  closeTicketChannel = new Subject();
  escalationTicketChannel = new Subject();
  backToPicTicketChannel = new Subject();
  helpdeskExportChannel = new Subject();

  repository = new DataTableRepository();

  constructor() {
    this.rowtableChannel.next({ status: apiResponse.INITIAL });
    this.closetableChannel.next({ status: apiResponse.INITIAL });
    this.closeTicketChannel.next({ status: apiResponse.INITIAL });
    this.escalationTicketChannel.next({ status: apiResponse.INITIAL });
    this.backToPicTicketChannel.next({ status: apiResponse.INITIAL });
    this.helpdeskExportChannel.next({status: apiResponse.INITIAL});
  }

  fetchRowTable = async (query, cancelToken) => {
    this.rowtableChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchRowTable(query, cancelToken)
        .then((result) => {
          this.rowtableChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.rowtableChannel.next({ status: apiResponse.ERROR, data: error });
        });
    } catch (error) {}
  };

  fetchCloseTable = async (query, cancelToken) => {
    this.closetableChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchCloseTable(query, cancelToken)
        .then((result) => {
          this.closetableChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.closetableChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchCloseTicket = async (query) => {
    this.closeTicketChannel.next({ status: apiResponse.LOADING });
    await this.repository
      .fetchCloseTicket(query)
      .then((result) => {
        this.closeTicketChannel.next({
          status: apiResponse.COMPLETED,
          data: result,
        });
      })
      .catch((error) => {
        this.closeTicketChannel.next({
          status: apiResponse.ERROR,
          data: error,
        });
      });
  };

  fetchEscalationTicket = async (query) => {
    this.escalationTicketChannel.next({ status: apiResponse.LOADING });
    await this.repository
      .fetchEscalationTicket(query)
      .then((result) => {
        this.escalationTicketChannel.next({
          status: apiResponse.COMPLETED,
          data: result,
        });
      })
      .catch((error) => {
        this.escalationTicketChannel.next({
          status: apiResponse.ERROR,
          data: error,
        });
      });
  };

  fetchBackToPicTicket = async (query) => {
    this.backToPicTicketChannel.next({ status: apiResponse.LOADING });
    await this.repository
      .fetchBackToPicTicket(query)
      .then((result) => {
        this.backToPicTicketChannel.next({
          status: apiResponse.COMPLETED,
          data: result,
        });
      })
      .catch((error) => {
        this.backToPicTicketChannel.next({
          status: apiResponse.ERROR,
          data: error,
        });
      });
  };

  fetchHelpdeskExport = async (query) => {
    this.helpdeskExportChannel.next({ status: apiResponse.LOADING });
    await this.repository
      .fetchHelpdeskExport(query)
      .then((result) => {
        this.helpdeskExportChannel.next({
          status: apiResponse.COMPLETED,
          data: result,
        });
      })
      .catch((error) => {
        this.helpdeskExportChannel.next({
          status: apiResponse.ERROR,
          data: error,
        });
      });
  };
}

export default DataTableBloc;
