import { Fragment, useRef, useState } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { accessRole } from "../../../helpers/access_role";
import TableActiveComponent from "./Components/TableActiveComponent";
import TableInactiveComponent from "./Components/TableInactiveComponent";
import "./style.scss";

const IndexView = (props) => {
  const activeRef = useRef();
  const inactiveRef = useRef();
  const [activeTab, setactiveTab] = useState("1");

  const [agendasCount, setAgendasCount] = useState(0);

  const reloadActiveRef = () => activeRef.current.setTokenAPI();
  const reloadInactiveRef = () => inactiveRef.current.setTokenAPI();
  
  function setCountCb(res, type) {
      setAgendasCount(res);
  }

  function toggle(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  }

  const renderTableView = () => {
    return (
      <>
        <Nav tabs>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "1",
              })}
              onClick={() => {
                toggle("1");
              }}
            >
              <span className="d-block d-sm-none">
                <i className="fas fa-home"></i>
              </span>
              <span className="d-none d-sm-block">Aktif</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: activeTab === "2",
              })}
              onClick={() => {
                toggle("2");
              }}
            >
              <span className="d-block d-sm-none">
                <i className="far fa-envelope"></i>
              </span>
              <span className="d-none d-sm-block">Tidak Aktif</span>
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="p-3 text-muted">
          <TabPane tabId="1">
            <TableActiveComponent
              ref={activeRef}
              handleInactiveSuccess={reloadInactiveRef}
            />
          </TabPane>
          <TabPane tabId="2">
            <TableInactiveComponent
              ref={inactiveRef}
              handleActiveSuccess={reloadActiveRef}
            />
          </TabPane>
        </TabContent>
      </>
    );
  };

  const renderView = () => {
    return renderTableView();
  };

  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <div className="float-end">
                  <div className="d-flex" style={{ columnGap: "1rem" }}>
                    {accessRole("create") ? (
                      <Link to="rough.html?create">
                        <Button color="primary">Tambah Kata Kasar</Button>
                      </Link>
                    ) : null}
                  </div>
                </div>
                <CardTitle>Kata Kasar</CardTitle>
                <CardSubtitle className="mb-3">Data - Data Kata Kasar</CardSubtitle>
                <hr />
                {renderView()}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default IndexView;
