import { apiRequest, helpdeskPicUpdate, helpdeskPicDetail, helpdeskServiceList, userList } from "../../../../services/adapters/base";

class UpdateRepository {
  fetchUpdate = async (query) => {
    return await apiRequest(
      "post",
      helpdeskPicUpdate,
      {
        body: query,
      },
      true
    );
  };

  fetchDetail = async (query) => {
    return await apiRequest(
      "get",
      helpdeskPicDetail,
      {
        params: query,
      },
      true
    );
  };

  fetchUsers = async (query, cancelToken) => {
    return await apiRequest(
      "get",
      userList,
      {
        cancelToken: cancelToken,
        params: query,
      },
      true
    );
  };

  fetchServices = async (query, cancelToken) => {
    return await apiRequest(
      "get",
      helpdeskServiceList,
      {
        cancelToken: cancelToken,
        params: query,
      },
      true
    );
  };

}

export default UpdateRepository;
