import { apiRequest, helpdeskServices, helpdeskServiceDelete } from "../../../../services/adapters/base";

class DataTableRepository {
  fetchRowTable = async (query, cancelToken) => {
    return await apiRequest(
      "get",
      helpdeskServices,
      {
        cancelToken: cancelToken,
        params: query,
      },
      true
    );
  };

  fetchDeleteHelpdeskService = async (query, cancelToken) => {
    return await apiRequest(
      "post",
      helpdeskServiceDelete,
      {
        cancelToken: cancelToken,
        body: query,
      },
      true
    );
  };
}

export default DataTableRepository;
