import {
    apiRequest,
    agendaCreate
} from "../../../../services/adapters/base"

class CreateRepository {

    fetchCreate = async (query) => {
        return await apiRequest("post", agendaCreate, {
            body: query
        }, true)
    }
}

export default CreateRepository
