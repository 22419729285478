import {
    apiRequest,
    agendaDetail,
    agendaUpdate
} from "../../../../services/adapters/base"

class UpdateRepository {

    fetchDetail = async (query) => {
        return await apiRequest("get", agendaDetail, {
            params: query
        }, true)
    }

    fetchUpdate = async (query) => {
        return await apiRequest("post", agendaUpdate, {
            body: query
        }, true)
    }
}

export default UpdateRepository
