import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';
import { endpoint } from "./endpoint"

export const adalConfig = endpoint('adal')

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) => {
  return adalFetch(authContext, "https://graph.microsoft.com", fetch, url, options);
}

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);

export const getAccessToken = () => {
    return authContext.acquireToken('https://graph.microsoft.com', function (error, token) {
      if (error || !token) {
        console.log('Token acquisition failed:', error);
      } else  {
        return token
      }
    });
}