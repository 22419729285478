import { apiRequest, serviceUpdate, serviceDetail } from "../../../../services/adapters/base";

class UpdateRepository {
  fetchUpdate = async (query) => {
    return await apiRequest(
      "post",
      serviceUpdate,
      {
        body: query,
      },
      true
    );
  };

  fetchDetail = async (query) => {
    return await apiRequest(
      "get",
      serviceDetail,
      {
        params: query,
      },
      true
    );
  };
}

export default UpdateRepository;
