import {
  apiRequest,
  monitoringGetAllHosts,
} from "../../../../../services/adapters/base";

class DataTableRepository {
  fetchAllHosts = async (query, cancelToken) => {
    return await apiRequest(
      "get",
      monitoringGetAllHosts,
      {
        cancelToken: cancelToken,
        params: query,
      },
      true
    );
  };
}

export default DataTableRepository;
