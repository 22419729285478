import { Subject } from "rxjs";
import apiResponse from "../../../../../services/apiResponse";
import IndexRepository from "../Repositories/IndexRepository";

class IndexBloc {
  //CPU USAGE
  getGraphCpuUsage5MinuteChannel = new Subject();
  getGraphCpuUsage30MinuteChannel = new Subject();
  getGraphCpuUsage1HourChannel = new Subject();
  getGraphCpuUsage6HourChannel = new Subject();
  getGraphCpuUsage1DayChannel = new Subject();
  getGraphCpuUsage1WeekChannel = new Subject();
  getGraphCpuUsage1MonthChannel = new Subject();
  getGraphCpuUsage6MonthChannel = new Subject();
  getGraphCpuUsage1YearChannel = new Subject();

  //SYSTEM LOAD
  getGraphSystemLoad5MinuteChannel = new Subject();
  getGraphSystemLoad30MinuteChannel = new Subject();
  getGraphSystemLoad1HourChannel = new Subject();
  getGraphSystemLoad6HourChannel = new Subject();
  getGraphSystemLoad1DayChannel = new Subject();
  getGraphSystemLoad1WeekChannel = new Subject();
  getGraphSystemLoad1MonthChannel = new Subject();
  getGraphSystemLoad6MonthChannel = new Subject();
  getGraphSystemLoad1YearChannel = new Subject();

  //MEMORY USAGE
  getGraphMemoryUsage5MinuteChannel = new Subject();
  getGraphMemoryUsage30MinuteChannel = new Subject();
  getGraphMemoryUsage1HourChannel = new Subject();
  getGraphMemoryUsage6HourChannel = new Subject();
  getGraphMemoryUsage1DayChannel = new Subject();
  getGraphMemoryUsage1WeekChannel = new Subject();
  getGraphMemoryUsage1MonthChannel = new Subject();
  getGraphMemoryUsage6MonthChannel = new Subject();
  getGraphMemoryUsage1YearChannel = new Subject();

  //MEMORY UTILIZATION
  getGraphMemoryUtilization5MinuteChannel = new Subject();
  getGraphMemoryUtilization30MinuteChannel = new Subject();
  getGraphMemoryUtilization1HourChannel = new Subject();
  getGraphMemoryUtilization6HourChannel = new Subject();
  getGraphMemoryUtilization1DayChannel = new Subject();
  getGraphMemoryUtilization1WeekChannel = new Subject();
  getGraphMemoryUtilization1MonthChannel = new Subject();
  getGraphMemoryUtilization6MonthChannel = new Subject();
  getGraphMemoryUtilization1YearChannel = new Subject();

  //DISK UTILIZATION
  getGraphDiskUtilization5MinuteChannel = new Subject();
  getGraphDiskUtilization30MinuteChannel = new Subject();
  getGraphDiskUtilization1HourChannel = new Subject();
  getGraphDiskUtilization6HourChannel = new Subject();
  getGraphDiskUtilization1DayChannel = new Subject();
  getGraphDiskUtilization1WeekChannel = new Subject();
  getGraphDiskUtilization1MonthChannel = new Subject();
  getGraphDiskUtilization6MonthChannel = new Subject();
  getGraphDiskUtilization1YearChannel = new Subject();

  //LAIN2
  getallHostChannel = new Subject();
  getAllPeriodChannel = new Subject();
  repository = new IndexRepository();

  constructor() {
    //CPU USAGE
    this.getGraphCpuUsage5MinuteChannel.next({ status: apiResponse.INITIAL });
    this.getGraphCpuUsage30MinuteChannel.next({ status: apiResponse.INITIAL });
    this.getGraphCpuUsage1HourChannel.next({ status: apiResponse.INITIAL });
    this.getGraphCpuUsage6HourChannel.next({ status: apiResponse.INITIAL });
    this.getGraphCpuUsage1DayChannel.next({ status: apiResponse.INITIAL });
    this.getGraphCpuUsage1WeekChannel.next({ status: apiResponse.INITIAL });
    this.getGraphCpuUsage1MonthChannel.next({ status: apiResponse.INITIAL });
    this.getGraphCpuUsage6MonthChannel.next({ status: apiResponse.INITIAL });
    this.getGraphCpuUsage1YearChannel.next({ status: apiResponse.INITIAL });

    //SYSTEM LOAD
    this.getGraphSystemLoad5MinuteChannel.next({ status: apiResponse.INITIAL });
    this.getGraphSystemLoad30MinuteChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getGraphSystemLoad1HourChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getGraphSystemLoad6HourChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getGraphSystemLoad1DayChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getGraphSystemLoad1WeekChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getGraphSystemLoad1MonthChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getGraphSystemLoad6MonthChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getGraphSystemLoad1YearChannel.next({
      status: apiResponse.INITIAL,
    });

    //MEMORY USAGE
    this.getGraphMemoryUsage5MinuteChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getGraphMemoryUsage30MinuteChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getGraphMemoryUsage1HourChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getGraphMemoryUsage6HourChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getGraphMemoryUsage1DayChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getGraphMemoryUsage1WeekChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getGraphMemoryUsage1MonthChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getGraphMemoryUsage6MonthChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getGraphMemoryUsage1YearChannel.next({
      status: apiResponse.INITIAL,
    });

    //MEMORY UTILIZATION
    this.getGraphMemoryUtilization5MinuteChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getGraphMemoryUtilization30MinuteChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getGraphMemoryUtilization1HourChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getGraphMemoryUtilization6HourChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getGraphMemoryUtilization1DayChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getGraphMemoryUtilization1WeekChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getGraphMemoryUtilization1MonthChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getGraphMemoryUtilization6MonthChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getGraphMemoryUtilization1YearChannel.next({
      status: apiResponse.INITIAL,
    });

    //DISK UTILIZATION
    this.getGraphDiskUtilization5MinuteChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getGraphDiskUtilization30MinuteChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getGraphDiskUtilization1HourChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getGraphDiskUtilization6HourChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getGraphDiskUtilization1DayChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getGraphDiskUtilization1WeekChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getGraphDiskUtilization1MonthChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getGraphDiskUtilization6MonthChannel.next({
      status: apiResponse.INITIAL,
    });
    this.getGraphDiskUtilization1YearChannel.next({
      status: apiResponse.INITIAL,
    });

    //LAIN2
    this.getallHostChannel.next({ status: apiResponse.INITIAL });
    this.getAllPeriodChannel.next({ status: apiResponse.INITIAL });
  }

  //CPU USAGE
  fetchGraphCpuUsage5Minute = async (query) => {
    this.getGraphCpuUsage5MinuteChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchGraphCpuUsage5Minute(query)
        .then((result) => {
          this.getGraphCpuUsage5MinuteChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphCpuUsage5MinuteChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphCpuUsage30Minute = async (query) => {
    this.getGraphCpuUsage30MinuteChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchGraphCpuUsage30Minute(query)
        .then((result) => {
          this.getGraphCpuUsage30MinuteChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphCpuUsage30MinuteChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphCpuUsage1Hour = async (query) => {
    this.getGraphCpuUsage1HourChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchGraphCpuUsage1Hour(query)
        .then((result) => {
          this.getGraphCpuUsage1HourChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphCpuUsage1HourChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphCpuUsage6Hour = async (query) => {
    this.getGraphCpuUsage6HourChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchGraphCpuUsage6Hour(query)
        .then((result) => {
          this.getGraphCpuUsage6HourChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphCpuUsage6HourChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphCpuUsage1Day = async (query) => {
    this.getGraphCpuUsage1DayChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchGraphCpuUsage1Day(query)
        .then((result) => {
          this.getGraphCpuUsage1DayChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphCpuUsage1DayChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphCpuUsage1Week = async (query) => {
    this.getGraphCpuUsage1WeekChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchGraphCpuUsage1Week(query)
        .then((result) => {
          this.getGraphCpuUsage1WeekChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphCpuUsage1WeekChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphCpuUsage1Month = async (query) => {
    this.getGraphCpuUsage1MonthChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchGraphCpuUsage1Month(query)
        .then((result) => {
          this.getGraphCpuUsage1MonthChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphCpuUsage1MonthChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphCpuUsage6Month = async (query) => {
    this.getGraphCpuUsage6MonthChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchGraphCpuUsage6Month(query)
        .then((result) => {
          this.getGraphCpuUsage6MonthChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphCpuUsage6MonthChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphCpuUsage1Year = async (query) => {
    this.getGraphCpuUsage1YearChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchGraphCpuUsage1Year(query)
        .then((result) => {
          this.getGraphCpuUsage1YearChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphCpuUsage1YearChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  //SYSTEM LOAD
  fetchGraphSystemLoad5Minute = async (query) => {
    this.getGraphSystemLoad5MinuteChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchGraphSystemLoad5Minute(query)
        .then((result) => {
          this.getGraphSystemLoad5MinuteChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphSystemLoad5MinuteChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphSystemLoad30Minute = async (query) => {
    this.getGraphSystemLoad30MinuteChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphSystemLoad30Minute(query)
        .then((result) => {
          this.getGraphSystemLoad30MinuteChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphSystemLoad30MinuteChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphSystemLoad1Hour = async (query) => {
    this.getGraphSystemLoad1HourChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphSystemLoad1Hour(query)
        .then((result) => {
          this.getGraphSystemLoad1HourChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphSystemLoad1HourChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphSystemLoad6Hour = async (query) => {
    this.getGraphSystemLoad6HourChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphSystemLoad6Hour(query)
        .then((result) => {
          this.getGraphSystemLoad6HourChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphSystemLoad6HourChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphSystemLoad1Day = async (query) => {
    this.getGraphSystemLoad1DayChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphSystemLoad1Day(query)
        .then((result) => {
          this.getGraphSystemLoad1DayChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphSystemLoad1DayChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphSystemLoad1Week = async (query) => {
    this.getGraphSystemLoad1WeekChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphSystemLoad1Week(query)
        .then((result) => {
          this.getGraphSystemLoad1WeekChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphSystemLoad1WeekChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphSystemLoad1Month = async (query) => {
    this.getGraphSystemLoad1MonthChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphSystemLoad1Month(query)
        .then((result) => {
          this.getGraphSystemLoad1MonthChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphSystemLoad1MonthChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphSystemLoad6Month = async (query) => {
    this.getGraphSystemLoad6MonthChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphSystemLoad6Month(query)
        .then((result) => {
          this.getGraphSystemLoad6MonthChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphSystemLoad6MonthChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphSystemLoad1Year = async (query) => {
    this.getGraphSystemLoad1YearChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphSystemLoad1Year(query)
        .then((result) => {
          this.getGraphSystemLoad1YearChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphSystemLoad1YearChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  //MEMORY USAGE
  fetchGraphMemoryUsage5Minute = async (query) => {
    this.getGraphMemoryUsage5MinuteChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphMemoryUsage5Minute(query)
        .then((result) => {
          this.getGraphMemoryUsage5MinuteChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphMemoryUsage5MinuteChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphMemoryUsage30Minute = async (query) => {
    this.getGraphMemoryUsage30MinuteChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphMemoryUsage30Minute(query)
        .then((result) => {
          this.getGraphMemoryUsage30MinuteChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphMemoryUsage30MinuteChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphMemoryUsage1Hour = async (query) => {
    this.getGraphMemoryUsage1HourChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphMemoryUsage1Hour(query)
        .then((result) => {
          this.getGraphMemoryUsage1HourChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphMemoryUsage1HourChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphMemoryUsage6Hour = async (query) => {
    this.getGraphMemoryUsage6HourChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphMemoryUsage6Hour(query)
        .then((result) => {
          this.getGraphMemoryUsage6HourChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphMemoryUsage6HourChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphMemoryUsage1Day = async (query) => {
    this.getGraphMemoryUsage1DayChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphMemoryUsage1Day(query)
        .then((result) => {
          this.getGraphMemoryUsage1DayChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphMemoryUsage1DayChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphMemoryUsage1Week = async (query) => {
    this.getGraphMemoryUsage1WeekChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphMemoryUsage1Week(query)
        .then((result) => {
          this.getGraphMemoryUsage1WeekChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphMemoryUsage1WeekChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphMemoryUsage1Month = async (query) => {
    this.getGraphMemoryUsage1MonthChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphMemoryUsage1Month(query)
        .then((result) => {
          this.getGraphMemoryUsage1MonthChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphMemoryUsage1MonthChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphMemoryUsage6Month = async (query) => {
    this.getGraphMemoryUsage6MonthChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphMemoryUsage6Month(query)
        .then((result) => {
          this.getGraphMemoryUsage6MonthChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphMemoryUsage6MonthChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphMemoryUsage1Year = async (query) => {
    this.getGraphMemoryUsage1YearChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphMemoryUsage1Year(query)
        .then((result) => {
          this.getGraphMemoryUsage1YearChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphMemoryUsage1YearChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  //MEMORY UTILIZATION
  fetchGraphMemoryUtilization5Minute = async (query) => {
    this.getGraphMemoryUtilization5MinuteChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphMemoryUtilization5Minute(query)
        .then((result) => {
          this.getGraphMemoryUtilization5MinuteChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphMemoryUtilization5MinuteChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphMemoryUtilization30Minute = async (query) => {
    this.getGraphMemoryUtilization30MinuteChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphMemoryUtilization30Minute(query)
        .then((result) => {
          this.getGraphMemoryUtilization30MinuteChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphMemoryUtilization30MinuteChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphMemoryUtilization1Hour = async (query) => {
    this.getGraphMemoryUtilization1HourChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphMemoryUtilization1Hour(query)
        .then((result) => {
          this.getGraphMemoryUtilization1HourChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphMemoryUtilization1HourChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphMemoryUtilization6Hour = async (query) => {
    this.getGraphMemoryUtilization6HourChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphMemoryUtilization6Hour(query)
        .then((result) => {
          this.getGraphMemoryUtilization6HourChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphMemoryUtilization6HourChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphMemoryUtilization1Day = async (query) => {
    this.getGraphMemoryUtilization1DayChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphMemoryUtilization1Day(query)
        .then((result) => {
          this.getGraphMemoryUtilization1DayChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphMemoryUtilization1DayChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphMemoryUtilization1Week = async (query) => {
    this.getGraphMemoryUtilization1WeekChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphMemoryUtilization1Week(query)
        .then((result) => {
          this.getGraphMemoryUtilization1WeekChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphMemoryUtilization1WeekChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphMemoryUtilization1Month = async (query) => {
    this.getGraphMemoryUtilization1MonthChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphMemoryUtilization1Month(query)
        .then((result) => {
          this.getGraphMemoryUtilization1MonthChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphMemoryUtilization1MonthChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphMemoryUtilization6Month = async (query) => {
    this.getGraphMemoryUtilization6MonthChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphMemoryUtilization6Month(query)
        .then((result) => {
          this.getGraphMemoryUtilization6MonthChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphMemoryUtilization6MonthChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphMemoryUtilization1Year = async (query) => {
    this.getGraphMemoryUtilization1YearChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphMemoryUtilization1Year(query)
        .then((result) => {
          this.getGraphMemoryUtilization1YearChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphMemoryUtilization1YearChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  //DISK UTILIZATION
  fetchGraphDiskUtilization5Minute = async (query) => {
    this.getGraphDiskUtilization5MinuteChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphDiskUtilization5Minute(query)
        .then((result) => {
          this.getGraphDiskUtilization5MinuteChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphDiskUtilization5MinuteChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphDiskUtilization30Minute = async (query) => {
    this.getGraphDiskUtilization30MinuteChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphDiskUtilization30Minute(query)
        .then((result) => {
          this.getGraphDiskUtilization30MinuteChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphDiskUtilization30MinuteChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphDiskUtilization1Hour = async (query) => {
    this.getGraphDiskUtilization1HourChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphDiskUtilization1Hour(query)
        .then((result) => {
          this.getGraphDiskUtilization1HourChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphDiskUtilization1HourChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphDiskUtilization6Hour = async (query) => {
    this.getGraphDiskUtilization6HourChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphDiskUtilization6Hour(query)
        .then((result) => {
          this.getGraphDiskUtilization6HourChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphDiskUtilization6HourChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphDiskUtilization1Day = async (query) => {
    this.getGraphDiskUtilization1DayChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphDiskUtilization1Day(query)
        .then((result) => {
          this.getGraphDiskUtilization1DayChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphDiskUtilization1DayChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphDiskUtilization1Week = async (query) => {
    this.getGraphDiskUtilization1WeekChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphDiskUtilization1Week(query)
        .then((result) => {
          this.getGraphDiskUtilization1WeekChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphDiskUtilization1WeekChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphDiskUtilization1Month = async (query) => {
    this.getGraphDiskUtilization1MonthChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphDiskUtilization1Month(query)
        .then((result) => {
          this.getGraphDiskUtilization1MonthChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphDiskUtilization1MonthChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphDiskUtilization6Month = async (query) => {
    this.getGraphDiskUtilization6MonthChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphDiskUtilization6Month(query)
        .then((result) => {
          this.getGraphDiskUtilization6MonthChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphDiskUtilization6MonthChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchGraphDiskUtilization1Year = async (query) => {
    this.getGraphDiskUtilization1YearChannel.next({
      status: apiResponse.LOADING,
    });
    try {
      await this.repository
        .fetchGraphDiskUtilization1Year(query)
        .then((result) => {
          this.getGraphDiskUtilization1YearChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getGraphDiskUtilization1YearChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchAllHosts = async (query) => {
    this.getallHostChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchAllHosts(query)
        .then((result) => {
          this.getallHostChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getallHostChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchAllPeriods = async (query) => {
    this.getAllPeriodChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchAllPeriods(query)
        .then((result) => {
          this.getAllPeriodChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getAllPeriodChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };
}

export default IndexBloc;
