import moment from "moment";
import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Shimmer from "react-shimmer-effect";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import { isValidHttpUrl } from "../../../helpers/utility";
import apiResponse from "../../../services/apiResponse";
import DetailBloc from "./Blocs/DetailBloc";
import MinioClient from "../../../services/minioClient";
import { Icon } from "@iconify/react";

class IndexView extends Component {
  detailBloc = new DetailBloc();
  minio = new MinioClient();

  constructor(props) {
    super(props);
    this.state = {};
  }

  fetchAttachment = async (path) => {
    const attachment = await this.minio.get(path).then(
      (res) => res,
      (err) => err
    );

    this.setState({
      detail: {
        ...this.state.detail,
        attachment_uri: attachment,
      },
    });
  };

  componentDidMount() {
    this.detailBloc.detailChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          if (result.data.status) {
            let response = result.data.response;
            console.log("response: ", response);
            this.setState({
              detail: response,
            });
            // this.fetchAttachment(this.state.detail.attachment.attachment_path);
          }
          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });
    const queryParams = new URLSearchParams(this.props.location.search);
    this.detailBloc.fetchDetail({ portal_service_id: queryParams.get("id") });
  }

  render() {
    return (
      <Fragment>
        <Container fluid>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <CardTitle>Detail Panduan</CardTitle>
                  <CardSubtitle className="mb-3">
                    Keterangan detail mengenai layanan portal
                  </CardSubtitle>
                  <hr />
                  <Row className="mb-3">
                    <label className="col-md-3">Judul Layanan</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <>
                          {this.state.detail.service_title !== ""
                            ? this.state.detail.service_title
                            : "-"}
                        </>
                      ) : (
                        <Shimmer>
                          <div style={{ width: "80%", height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-3">Warna Background</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <>
                          {this.state.detail.service_color !== ""
                            ? this.state.detail.service_color
                            : "-"}
                        </>
                      ) : (
                        <Shimmer>
                          <div style={{ width: "80%", height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-3">Warna Background Fitur</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <>
                          {this.state.detail.service_features_color !== ""
                            ? this.state.detail.service_features_color
                            : "-"}
                        </>
                      ) : (
                        <Shimmer>
                          <div style={{ width: "80%", height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <label className="col-md-3">Link Layanan</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <>
                          {this.state.detail.service_link !== ""
                            ? this.state.detail.service_link
                            : "-"}
                        </>
                      ) : (
                        <Shimmer>
                          <div style={{ width: "80%", height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <label className="col-md-3">Status</label>
                    <Col md="9">
                      {this.state.detail ? (
                        <>
                          {this.state.detail.is_active !== ""
                            ? this.state.detail.is_active == 1 ? "Aktif" : "Tidak Aktif"
                            : "-"}
                        </>
                      ) : (
                        <Shimmer>
                          <div style={{ width: 68, height: 15 }}></div>
                        </Shimmer>
                      )}
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md="3"></Col>
                    <Col md="9">
                      <Link to="/admin/keyword.html" className="waves-effect">
                        <Button color="danger">
                          <i className="uil-arrow-left"></i> Kembali
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

//export default IndexView
export default withRouter(IndexView);
