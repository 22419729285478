import { Subject } from "rxjs";
import apiResponse from "../../../../services/apiResponse";
import UpdateRepository from "../Repositories/UpdateRepository";

class UpdateBloc {
  createChannel = new Subject();
  detailChannel = new Subject();
  rowtableChannel = new Subject();
  repository = new UpdateRepository();

  constructor() {
    this.rowtableChannel.next({ status: apiResponse.INITIAL });
    this.createChannel.next({ status: apiResponse.INITIAL });
    this.detailChannel.next({ status: apiResponse.INITIAL });
  }

  fetchRedirectLink = async (query, cancelToken) => {
    this.rowtableChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchRedirectLink(query, cancelToken)
        .then((result) => {
          this.rowtableChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.rowtableChannel.next({ status: apiResponse.ERROR, data: error });
        });
    } catch (error) {}
  };


  fetchUpdate = async (query) => {
    this.createChannel.next({ status: apiResponse.LOADING });
    await this.repository
      .fetchUpdate(query)
      .then((result) => {
        this.createChannel.next({
          status: apiResponse.COMPLETED,
          data: result,
        });
      })
      .catch((error) => {
        this.createChannel.next({ status: apiResponse.ERROR, data: error });
      });
  };

  fetchDetail = async (query) => {
    await this.repository
      .fetchDetail(query)
      .then((result) => {
        this.detailChannel.next({
          status: apiResponse.COMPLETED,
          data: result,
        });
      })
      .catch((error) => {
        this.detailChannel.next({ status: apiResponse.ERROR, data: error });
      });
  };
}

export default UpdateBloc;
