import {
    apiRequest as baseApi,
    authConnect as baseAuthConnect,
    authGetAccessRole as baseAuthGetAccessRole,
    authGetAccessMenus as baseAuthGetAccessMenus,
    userGetData
} from "./services/adapters/base"

import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import Preload from './components/Preload'

import ErrorNetwork from './components/Error/Network'
import ErrorAccess from './components/Error/Access'
import ErrorUser from './components/Error/User'
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"


import store from "./store"

import NotificationService from "./services/notification"

/* sessionStorage.clear()
localStorage.clear() */


sessionStorage.getItem('preload', '')

testConnection()

async function testConnection() {
  
  ReactDOM.render(<Preload text="Memeriksa koneksi..." />, document.getElementById("root"))
  await baseApi("get", baseAuthConnect, {}, false)
  .then(async result => {
    if(result.serverTime) {
        localStorage.setItem('localTime', new Date().getTime())
        localStorage.setItem('serverTime', result.serverTime)
    }

    /* var lastSync = await localStorage.getItem('preload')
    const thisTime = (Math.floor(Date.now()/1000))

    if(lastSync !== null && thisTime <= lastSync+6000 ) {
      ReactDOM.render(<Preload text="Mempersiapkan sistem..." />, document.getElementById("root"))
      gotoRoot()
    } else {
      getUserdata()
      await localStorage.setItem('preload', thisTime)
    } */
    getUserdata()
  })
  .catch(result => {
    ReactDOM.render(<ErrorNetwork />, document.getElementById("root"))
  })
}

async function getUserdata() {
  ReactDOM.render(<Preload text="Mengambil detail login..." />, document.getElementById("root"))
  await baseApi("post", userGetData, {}, true)
  .then(result => {
    if(result.status) {
      localStorage.setItem('tokenId', result.response.tokenId)
      localStorage.setItem('userdata', JSON.stringify(result.response.userdata))
      getAccessRole()
    } else {
      ReactDOM.render(<ErrorUser />, document.getElementById("root"))
    }
  })
  .catch(result => {
    ReactDOM.render(<ErrorNetwork />, document.getElementById("root"))
  })
}

async function getAccessRole() {
  ReactDOM.render(<Preload text="Verifikasi Role Access..." />, document.getElementById("root"))
  await baseApi("get", baseAuthGetAccessRole)
  .then(result => {
    const roleList = result.response
      if(roleList.length>0) {
        if(!localStorage.getItem('currentRoleId')) {
          localStorage.setItem('currentRoleId', roleList[0].access_id)
          localStorage.setItem('currentRoleName', roleList[0].access_name)
        }
        sessionStorage.setItem('allRole', JSON.stringify(roleList))
        getAccessMenu()
      } else {
        ReactDOM.render(<ErrorAccess />, document.getElementById("root"))
      }
  })
  .catch(result => {
    ReactDOM.render(<ErrorNetwork />, document.getElementById("root"))
  })
}

async function getAccessMenu() {
  ReactDOM.render(<Preload text="Mempersiapkan sistem..." />, document.getElementById("root"))
  await baseApi("get", baseAuthGetAccessMenus)
  .then(result => {
    const dataAccessKey = []
    const dataAccessValue = []
    const dataBreadcrumb = []
    var key = 0
    result.response.map((level1) => {
      if(level1.child.length>0) {
        level1.child.map((level2) => {
          if(level2.child.length>0) {
            level2.child.map((level3) => {
              if(level3.child.length===0) {
                dataAccessKey[key] = level3.link
                dataAccessValue[key] = level3.access
                dataBreadcrumb[key] = level3.breadcrumb
                ++key
              }
              return null
            })
          } else {
            dataAccessKey[key] = level2.link
            dataAccessValue[key] = level2.access
            dataBreadcrumb[key] = level2.breadcrumb
            ++key
          }
          return null
        })
      } else {
        dataAccessKey[key] = level1.link
        dataAccessValue[key] = level1.access
        dataBreadcrumb[key] = level1.breadcrumb
        ++key
      }
      return null
    })

    sessionStorage.setItem('jsonMenu', JSON.stringify(result.response))
    sessionStorage.setItem('accessPath', JSON.stringify(dataAccessKey))
    sessionStorage.setItem('accessValue', JSON.stringify(dataAccessValue))
    sessionStorage.setItem('accessBreadcrumb', JSON.stringify(dataBreadcrumb))

    /* const app = (
      <Provider store={store}>
        <NotificationService />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    )
    ReactDOM.render(app, document.getElementById("root"))
    serviceWorker.unregister()
    serviceWorker.register() */
    gotoRoot()
  })
  .catch(result => {
    ReactDOM.render(<ErrorNetwork />, document.getElementById("root"))
  })
}

function gotoRoot() {
  const app = (
    <Provider store={store}>
      <NotificationService />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  )
  ReactDOM.render(app, document.getElementById("root"))
  serviceWorker.unregister()
  //serviceWorker.register()
}