import { Subject } from "rxjs";
import apiResponse from "../../../../services/apiResponse";
import CreateRepository from "../Repositories/CreateRepository";

class CreateBloc {
  createChannel = new Subject();
  usersChannel = new Subject();
  servicesChannel = new Subject();
  repository = new CreateRepository();

  constructor() {
    this.createChannel.next({ status: apiResponse.INITIAL });
    this.usersChannel.next({ status: apiResponse.INITIAL });
    this.servicesChannel.next({ status: apiResponse.INITIAL });
  }

  fetchCreate = async (query) => {
    this.createChannel.next({ status: apiResponse.LOADING });
    await this.repository
      .fetchCreate(query)
      .then((result) => {
        this.createChannel.next({
          status: apiResponse.COMPLETED,
          data: result,
        });
      })
      .catch((error) => {
        this.createChannel.next({ status: apiResponse.ERROR, data: error });
      });
  };

  fetchUsers = async (query, cancelToken) => {
    this.usersChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchUsers(query, cancelToken)
        .then((result) => {
          this.usersChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.usersChannel.next({ status: apiResponse.ERROR, data: error });
        });
    } catch (error) {}
  };

  fetchServices = async (query, cancelToken) => {
    this.servicesChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchServices(query, cancelToken)
        .then((result) => {
          this.servicesChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.servicesChannel.next({ status: apiResponse.ERROR, data: error });
        });
    } catch (error) {}
  };
}

export default CreateBloc;
