import React, { Fragment, Component } from "react";
import { withRouter } from "react-router";
import {
  Col,
  Container,
  Row,
  Spinner,
  Card,
  CardBody,
  Button,
} from "reactstrap";
import IndexBloc from "../../Blocs/IndexBloc";
import { Pie } from "react-chartjs-2";
import apiResponse from "../../../../../../services/apiResponse";

class IndexView extends Component {
  indexBloc = new IndexBloc();

  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.state = {
      data: null,
      loading: false,
      hoveredDesc: false,
      errorMessage: null,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    this.setState({ loading: true, errorMessage: null });

    this.indexBloc.getGraphMemoryUsage30MinuteChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          let response = result.data.response;
          let errormessage = result.data.message;
          // console.log(response);
          this.setState({
            data: response,
            loading: false,
            errorMessage: errormessage,
          });
          break;
        case apiResponse.ERROR:
          this.setState({
            loading: false,
            errorMessage: "Failed to load data. Please try again.",
          });
          break;
        default:
          break;
      }
    });

    this.indexBloc.fetchGraphMemoryUsage30Minute({});
  };

  createChart = () => {
    const { selectedHost } = this.props;
    const { data } = this.state;

    const defaultChartData = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
        },
      ],
    };

    if (!selectedHost) {
      return defaultChartData;
    }

    const value = selectedHost.value;

    const specificHost = Array.isArray(data)
      ? data.find((item) => item.host.hostid === value)
      : null;

    const graphId = specificHost ? specificHost.graph.graphid : null;

    const specificGraph = Array.isArray(this.state.data)
      ? this.state.data.find((item) => item.graph.graphid === graphId)
      : null;

    const items =
      specificGraph && Array.isArray(specificGraph.graph.items)
        ? specificGraph.graph.items
        : [];

    const labels = items.map((item) => item.name);

    const dataPoints = items.map((item) => {
      const numericValue = parseFloat(item.last.split(" ")[0]);
      return numericValue;
    });

    const backgroundColors = items.map((item, index) =>
      this.getColorForItem(index)
    );

    return {
      labels: labels,
      datasets: [
        {
          data: dataPoints,
          backgroundColor: backgroundColors,
        },
      ],
    };
  };

  // getColorForItem = (name) => {
  //   switch (name) {
  //     case "Total memory":
  //       return "rgba(0, 130, 0, 0.8)";
  //     default:
  //       return "rgba(245, 152, 145, 0.8)";
  //   }
  // };

  getColorForItem = (index) => {
    const colors = ["rgba(0, 130, 0, 0.8)", "rgba(214, 68, 0, 0.8)"];
    return colors[index % colors.length];
  };

  renderMemoryUsageInfo = () => {
    const { selectedHost } = this.props;
    const { data } = this.state;

    if (!selectedHost) {
      return <div>Tidak ada host yang dipilih.</div>;
    }

    const value = selectedHost.value;

    const specificHost = Array.isArray(data)
      ? data.find((item) => item.host.hostid === value)
      : null;

    const graphId = specificHost ? specificHost.graph.graphid : null;

    const specificGraph = Array.isArray(this.state.data)
      ? this.state.data.find((item) => item.graph.graphid === graphId)
      : null;

    const items =
      specificGraph && Array.isArray(specificGraph.graph.items)
        ? specificGraph.graph.items
        : [];

    if (items.length === 0) {
      return <div>Tidak ada informasi tersedia untuk host yang dipilih.</div>;
    }

    return (
      <div>
        <div>
          <div style={{ fontWeight: "bold", fontSize: "20px" }}>
            Informasi{" "}
            <span
              className="uil-info-circle"
              onMouseEnter={() => this.setState({ hoveredDesc: true })}
              onMouseLeave={() => this.setState({ hoveredDesc: false })}
              style={{ cursor: "pointer" }}
            />
          </div>
          <br />
        </div>
        {this.state.hoveredDesc && (
          <div
            style={{
              position: "absolute",
              background: "#f4f4f4",
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "10px",
              zIndex: 1000,
              width: "800px",
              boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
            }}
          >
            {this.renderDescDescriptions()}
          </div>
        )}

        {items.map((item, index) => (
          <div key={index}>
            <strong>{item.name}</strong> : <strong>Last:</strong>{" "}
            {item.last !== null ? item.last : "0.000"} <strong>|</strong>{" "}
            <strong>Min:</strong> {item.min !== null ? item.min : "0.000"}{" "}
            <strong>|</strong> <strong>Average:</strong>{" "}
            {item.average !== null ? item.average : "0.000"} <strong>|</strong>{" "}
            <strong>Max:</strong> {item.max !== null ? item.max : "0.000"}
          </div>
        ))}
      </div>
    );
  };

  renderDescDescriptions = () => {
    const desc = [
      {
        level: "Total Memory",
        description:
          "Kapasitas penyimpanan keseluruhan atau ukuran total dari memori.",
      },
      {
        level: "Available Memory",
        description:
          "Jumlah kapasitas memori yang saat ini masih kosong dan belum terpakai.",
      },
    ];

    return (
      <div>
        {desc.map((item, index) => (
          <div key={index}>
            <strong>{item.level}:</strong> {item.description}
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { loading, errorMessage } = this.state;
    return (
      <Fragment>
        <Container fluid>
          <Row>
            <Col className="col-12">
              {loading ? (
                <div className="text-center">
                  <div>
                    <Spinner
                      style={{
                        width: "3rem",
                        height: "3rem",
                        borderWidth: "0.4rem",
                      }}
                      color="dark"
                    />
                  </div>
                  <div>
                    <span style={{ fontWeight: "bold" }}>
                      Mengambil Data ...
                    </span>
                  </div>
                </div>
              ) : errorMessage ? (
                <Card className="text-center">
                  <br />
                  <CardBody>
                    <h5 className="text-dark">{errorMessage}</h5>
                    <br />
                    <Button color="danger" onClick={this.fetchData}>
                      Muat Ulang
                    </Button>
                  </CardBody>
                </Card>
              ) : (
                <div>
                  <Row>
                    <Col>
                      <Pie data={this.createChart()} />
                    </Col>
                  </Row>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <hr />
            <Col className="col-12">{this.renderMemoryUsageInfo()}</Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default withRouter(IndexView);
