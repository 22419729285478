import { apiRequest, guideCreate, guideCategoryList } from "../../../../services/adapters/base";

class CreateRepository {
  fetchCreate = async (query) => {
    return await apiRequest(
      "post",
      guideCreate,
      {
        body: query,
      },
      true
    );
  };

  fetchGuideCategories = async (query) => {
    return await apiRequest(
      "get",
      guideCategoryList,
      {
        body: query,
      },
      true
    );
  };
}

export default CreateRepository;
