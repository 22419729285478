import {
  apiRequest,
  monitoringGetAllHosts,
  monitoringGetAllPeriods,

  //CPU USAGE
  getGraphCpuUsage5Minute,
  getGraphCpuUsage30Minute,
  getGraphCpuUsage1Hour,
  getGraphCpuUsage6Hour,
  getGraphCpuUsage1Day,
  getGraphCpuUsage1Week,
  getGraphCpuUsage1Month,
  getGraphCpuUsage6Month,
  getGraphCpuUsage1Year,

  //SYSTEM LOAD
  getGraphSystemLoad5Minute,
  getGraphSystemLoad30Minute,
  getGraphSystemLoad1Hour,
  getGraphSystemLoad6Hour,
  getGraphSystemLoad1Day,
  getGraphSystemLoad1Week,
  getGraphSystemLoad1Month,
  getGraphSystemLoad6Month,
  getGraphSystemLoad1Year,

  //MEMORY USAGE
  getGraphMemoryUsage5Minute,
  getGraphMemoryUsage30Minute,
  getGraphMemoryUsage1Hour,
  getGraphMemoryUsage6Hour,
  getGraphMemoryUsage1Day,
  getGraphMemoryUsage1Week,
  getGraphMemoryUsage1Month,
  getGraphMemoryUsage6Month,
  getGraphMemoryUsage1Year,

  //MEMORY UTILIZATION
  getGraphMemoryUtilization5Minute,
  getGraphMemoryUtilization30Minute,
  getGraphMemoryUtilization1Hour,
  getGraphMemoryUtilization6Hour,
  getGraphMemoryUtilization1Day,
  getGraphMemoryUtilization1Week,
  getGraphMemoryUtilization1Month,
  getGraphMemoryUtilization6Month,
  getGraphMemoryUtilization1Year,

  //DISK UTILIZATION
  getGraphDiskUtilization5Minute,
  getGraphDiskUtilization30Minute,
  getGraphDiskUtilization1Hour,
  getGraphDiskUtilization6Hour,
  getGraphDiskUtilization1Day,
  getGraphDiskUtilization1Week,
  getGraphDiskUtilization1Month,
  getGraphDiskUtilization6Month,
  getGraphDiskUtilization1Year,
} from "../../../../../services/adapters/base";

class IndexRepository {
  //CPU USAGE
  fetchGraphCpuUsage5Minute = async (query) => {
    return await apiRequest(
      "get",
      getGraphCpuUsage5Minute,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphCpuUsage30Minute = async (query) => {
    return await apiRequest(
      "get",
      getGraphCpuUsage30Minute,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphCpuUsage1Hour = async (query) => {
    return await apiRequest(
      "get",
      getGraphCpuUsage1Hour,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphCpuUsage6Hour = async (query) => {
    return await apiRequest(
      "get",
      getGraphCpuUsage6Hour,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphCpuUsage1Day = async (query) => {
    return await apiRequest(
      "get",
      getGraphCpuUsage1Day,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphCpuUsage1Week = async (query) => {
    try {
      const response = await apiRequest(
        "get",
        getGraphCpuUsage1Week,
        {
          params: query,
        },
        true
      );
      return response;
    } catch (error) {
      console.error("Error fetching graph CPU usage for 1 week:", error);
      throw error;
    }
  };

  fetchGraphCpuUsage1Month = async (query) => {
    return await apiRequest(
      "get",
      getGraphCpuUsage1Month,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphCpuUsage6Month = async (query) => {
    return await apiRequest(
      "get",
      getGraphCpuUsage6Month,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphCpuUsage1Year = async (query) => {
    return await apiRequest(
      "get",
      getGraphCpuUsage1Year,
      {
        params: query,
      },
      true
    );
  };

  //SYSTEM LOAD
  fetchGraphSystemLoad5Minute = async (query) => {
    return await apiRequest(
      "get",
      getGraphSystemLoad5Minute,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphSystemLoad30Minute = async (query) => {
    return await apiRequest(
      "get",
      getGraphSystemLoad30Minute,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphSystemLoad1Hour = async (query) => {
    return await apiRequest(
      "get",
      getGraphSystemLoad1Hour,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphSystemLoad6Hour = async (query) => {
    return await apiRequest(
      "get",
      getGraphSystemLoad6Hour,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphSystemLoad1Day = async (query) => {
    return await apiRequest(
      "get",
      getGraphSystemLoad1Day,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphSystemLoad1Week = async (query) => {
    return await apiRequest(
      "get",
      getGraphSystemLoad1Week,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphSystemLoad1Month = async (query) => {
    return await apiRequest(
      "get",
      getGraphSystemLoad1Month,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphSystemLoad6Month = async (query) => {
    return await apiRequest(
      "get",
      getGraphSystemLoad6Month,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphSystemLoad1Year = async (query) => {
    return await apiRequest(
      "get",
      getGraphSystemLoad1Year,
      {
        params: query,
      },
      true
    );
  };

  //MEMORY USAGE
  fetchGraphMemoryUsage5Minute = async (query) => {
    return await apiRequest(
      "get",
      getGraphMemoryUsage5Minute,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphMemoryUsage30Minute = async (query) => {
    return await apiRequest(
      "get",
      getGraphMemoryUsage30Minute,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphMemoryUsage1Hour = async (query) => {
    return await apiRequest(
      "get",
      getGraphMemoryUsage1Hour,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphMemoryUsage6Hour = async (query) => {
    return await apiRequest(
      "get",
      getGraphMemoryUsage6Hour,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphMemoryUsage1Day = async (query) => {
    return await apiRequest(
      "get",
      getGraphMemoryUsage1Day,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphMemoryUsage1Week = async (query) => {
    return await apiRequest(
      "get",
      getGraphMemoryUsage1Week,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphMemoryUsage1Month = async (query) => {
    return await apiRequest(
      "get",
      getGraphMemoryUsage1Month,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphMemoryUsage6Month = async (query) => {
    return await apiRequest(
      "get",
      getGraphMemoryUsage6Month,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphMemoryUsage1Year = async (query) => {
    return await apiRequest(
      "get",
      getGraphMemoryUsage1Year,
      {
        params: query,
      },
      true
    );
  };

  //MEMORY UTILIZATION
  fetchGraphMemoryUtilization5Minute = async (query) => {
    return await apiRequest(
      "get",
      getGraphMemoryUtilization5Minute,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphMemoryUtilization30Minute = async (query) => {
    return await apiRequest(
      "get",
      getGraphMemoryUtilization30Minute,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphMemoryUtilization1Hour = async (query) => {
    return await apiRequest(
      "get",
      getGraphMemoryUtilization1Hour,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphMemoryUtilization6Hour = async (query) => {
    return await apiRequest(
      "get",
      getGraphMemoryUtilization6Hour,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphMemoryUtilization1Day = async (query) => {
    return await apiRequest(
      "get",
      getGraphMemoryUtilization1Day,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphMemoryUtilization1Week = async (query) => {
    return await apiRequest(
      "get",
      getGraphMemoryUtilization1Week,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphMemoryUtilization1Month = async (query) => {
    return await apiRequest(
      "get",
      getGraphMemoryUtilization1Month,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphMemoryUtilization6Month = async (query) => {
    return await apiRequest(
      "get",
      getGraphMemoryUtilization6Month,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphMemoryUtilization1Year = async (query) => {
    return await apiRequest(
      "get",
      getGraphMemoryUtilization1Year,
      {
        params: query,
      },
      true
    );
  };

  //DISK UTILIZATION
  fetchGraphDiskUtilization5Minute = async (query) => {
    return await apiRequest(
      "get",
      getGraphDiskUtilization5Minute,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphDiskUtilization30Minute = async (query) => {
    return await apiRequest(
      "get",
      getGraphDiskUtilization30Minute,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphDiskUtilization1Hour = async (query) => {
    return await apiRequest(
      "get",
      getGraphDiskUtilization1Hour,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphDiskUtilization6Hour = async (query) => {
    return await apiRequest(
      "get",
      getGraphDiskUtilization6Hour,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphDiskUtilization1Day = async (query) => {
    return await apiRequest(
      "get",
      getGraphDiskUtilization1Day,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphDiskUtilization1Week = async (query) => {
    return await apiRequest(
      "get",
      getGraphDiskUtilization1Week,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphDiskUtilization1Month = async (query) => {
    return await apiRequest(
      "get",
      getGraphDiskUtilization1Month,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphDiskUtilization6Month = async (query) => {
    return await apiRequest(
      "get",
      getGraphDiskUtilization6Month,
      {
        params: query,
      },
      true
    );
  };

  fetchGraphDiskUtilization1Year = async (query) => {
    return await apiRequest(
      "get",
      getGraphDiskUtilization1Year,
      {
        params: query,
      },
      true
    );
  };

  fetchAllHosts = async (query) => {
    return await apiRequest(
      "get",
      monitoringGetAllHosts,
      {
        params: query,
      },
      true
    );
  };

  fetchAllPeriods = async (query) => {
    return await apiRequest(
      "get",
      monitoringGetAllPeriods,
      {
        params: query,
      },
      true
    );
  };
}

export default IndexRepository;
