export function config(type) {
  switch (type) {
    case "datatable":
      return {
        null: "-",
        length_menu: [5, 10, 20, 50, 100],
        show_filter: true,
        show_pagination: true,
        pagination: "advance",
        language: {
          loading_text: "Sedang mengambil data...",
          no_data_text: "Data tidak ditemukan",
          length_menu: "Tampilkan _MENU_ data",
          filter: "Cari data...",
          info: "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
          pagination: {
            first: "Pertama",
            previous: "Sebelumnya",
            next: "Berikutnya",
            last: "Terakhir",
          },
        },
        tableClass: "table table-bordered table-striped table-hover",
      };
    case "appName":
      return {
        long: "Portal Admin",
        short: "Portal Admin",
      };
    case "appOwner":
      return {
        parent: "Kementerian Investasi dan Hilirisasi",
        child: "BKPM",
      };
    default:
      return null;
  }
}
