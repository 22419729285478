import { Subject } from "rxjs";
import apiResponse from "../../../../../services/apiResponse";
import DataTableRepository from "../Repositories/DataTableRepository";

class DataTableBloc {
  getAllCronJobChannel = new Subject();
  getDetailCronJobChannel = new Subject();
  repository = new DataTableRepository();

  constructor() {
    this.getAllCronJobChannel.next({ status: apiResponse.INITIAL });
    this.getDetailCronJobChannel.next({ status: apiResponse.INITIAL });
  }

  fetchAllCronJob = async (query) => {
    this.getAllCronJobChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchAllCronJob(query)
        .then((result) => {
          this.getAllCronJobChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getAllCronJobChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };

  fetchDetailCronJob = async (query) => {
    this.getDetailCronJobChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchDetailCronJob(query)
        .then((result) => {
          this.getDetailCronJobChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getDetailCronJobChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };
}

export default DataTableBloc;
