import { Subject } from "rxjs";
import apiResponse from "../../../../services/apiResponse";
import CreateRepository from "../Repositories/CreateRepository";

class CreateBloc {
  createChannel = new Subject();
  rowtableChannel = new Subject();
  repository = new CreateRepository();

  constructor() {
    this.rowtableChannel.next({ status: apiResponse.INITIAL });
    this.createChannel.next({ status: apiResponse.INITIAL });
  }

  fetchRedirectLink = async (query, cancelToken) => {
    this.rowtableChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchRedirectLink(query, cancelToken)
        .then((result) => {
          this.rowtableChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.rowtableChannel.next({ status: apiResponse.ERROR, data: error });
        });
    } catch (error) {}
  };

  fetchCreate = async (query) => {
    this.createChannel.next({ status: apiResponse.LOADING });
    await this.repository
      .fetchCreate(query)
      .then((result) => {
        this.createChannel.next({
          status: apiResponse.COMPLETED,
          data: result,
        });
      })
      .catch((error) => {
        this.createChannel.next({ status: apiResponse.ERROR, data: error });
      });
  };
}

export default CreateBloc;
