import { Subject } from "rxjs";
import apiResponse from "../../../../../services/apiResponse";
import DataTableRepository from "../Repositories/DataTableRepository";

class DataTableBloc {
  getAllHostsChannel = new Subject();
  repository = new DataTableRepository();

  constructor() {
    this.getAllHostsChannel.next({ status: apiResponse.INITIAL });
  }

  fetchAllHosts = async (query) => {
    this.getAllHostsChannel.next({ status: apiResponse.LOADING });
    try {
      await this.repository
        .fetchAllHosts(query)
        .then((result) => {
          this.getAllHostsChannel.next({
            status: apiResponse.COMPLETED,
            data: result,
          });
        })
        .catch((error) => {
          this.getAllHostsChannel.next({
            status: apiResponse.ERROR,
            data: error,
          });
        });
    } catch (error) {}
  };
}

export default DataTableBloc;
