import {
  apiRequest,
  monitoringGetAllHosts,
  getGraphSpaceUtilizationAllNow,
} from "../../../../../services/adapters/base";

class IndexRepository {
  fetchAllHosts = async (query) => {
    return await apiRequest(
      "get",
      monitoringGetAllHosts,
      {
        params: query,
      },
      true
    );
  };

  fetchAllGraphSpaceUtilizationAll = async (query) => {
    return await apiRequest(
      "get",
      getGraphSpaceUtilizationAllNow,
      {
        params: query,
      },
      true
    );
  };
}

export default IndexRepository;
