import { AgendaContext } from "../Context/AgendaContext";
import { Component } from "react";
import AsyncSelect from "react-select/async";
import { cancelToken } from "../../../services/adapters/base";
import apiResponse from "../../../services/apiResponse";
import EmployeeBloc from "../Create/Blocs/EmployeeBloc";

class EmployeeSelect extends Component {
  static contextType = AgendaContext;

  source;
  employeeBloc = new EmployeeBloc();

  constructor(props) {
    super(props);
    this.state = {
      loadingSearch: false,
      empResult: [],
      inputValue: "",
      selectedOptions: [],
      dataForm: {},
    };
  }

  fetchData = (inputValue, callback) => {
    this.setTokenAPI();

    const query = { name: inputValue };
    this.employeeBloc.fetchSearch(query, this.source.token, (res) => {
      const result = res.response.result;
      const tempArray = [];
      if (result) {
        if (result.length) {
          result.forEach((emp) => {
            tempArray.push({
              label: `${emp.employee_name}`,
              value: emp.employee_nip,
            });
          });
        } else {
          tempArray.length = 0;
        }
      }
      callback(tempArray);
    });
  };

  setTokenAPI = () => {
    if (typeof this.source != typeof undefined) {
      this.source.cancel();
    }
    this.source = cancelToken();
  };

  handleOnChange = (options) => {
    const { dataForm, setDataForm } = this.context;
    if (options) {
      this.setState({
        selectedOptions:
          options.length > 0 ? options.map((opt) => opt.value) : [],
      });

      setDataForm({
        ...dataForm,
        agenda_participants:
          options.length > 0 ? options.map((opt) => opt.value) : [],
      });
    }
  };

  componentDidMount() {
    this.setTokenAPI();
    this.employeeBloc.employeeChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.INITIAL:
          this.setState({
            loadingSearch: false,
          });
          break;
        case apiResponse.LOADING:
          this.setState({
            loadingSearch: true,
          });
          break;
        case apiResponse.COMPLETED:
          this.setState({
            loadingSearch: false,
          });
          break;
        case apiResponse.ERROR:
          break;
        default:
          break;
      }
    });

    if (this.props.defaultValue ? this.props.defaultValue.length > 0 : false) {
      const { dataForm, setDataForm } = this.context;
      this.setState({
        selectedOptions: this.props.defaultValue.map((opt) => opt.value),
      });

      setDataForm({
        ...dataForm,
        agenda_participants: this.props.defaultValue.map((opt) => opt.value),
      });
    }
  }

  componentWillUnmount() {
    this.employeeBloc.employeeChannel.unsubscribe();
  }

  render() {
    return (
      <AsyncSelect
        isMulti
        cacheOptions
        defaultOptions
        defaultValue={this.props.defaultValue ? this.props.defaultValue : null}
        onChange={this.handleOnChange}
        loadOptions={this.fetchData}
        placeholder="Cari..."
      />
    );
  }
}

export default EmployeeSelect;
