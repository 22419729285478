import { accessRole } from "../../../helpers/access_role";
import ErrorAccess from "../../../components/Error/Content/Access";

import IndexView from "./Index/View";

const RouteView = (props) => {
  const queryParams = new URLSearchParams(props.location.search);

  //   if (_create != null) {
  //     return accessRole("create") ? (
  //       renderWithProvider(<CreateView />)
  //     ) : (
  //       <ErrorAccess />
  //     );
  //   }
  //   else if (_detail != null) {
  //     return accessRole("create") ? (
  //       renderWithProvider(<DetailView />)
  //     ) : (
  //       <ErrorAccess />
  //     );
  //   } else if (_update != null) {
  //     return accessRole("create") ? (
  //       renderWithProvider(<UpdateView />)
  //     ) : (
  //       <ErrorAccess />
  //     );
  //   } else if (_notulen != null) {
  //     // return <NotulenView />;
  //     return accessRole("update") ? (
  //       renderWithProvider(<NotulenView />)
  //     ) : (
  //       <ErrorAccess />
  //     );
  //   } else if (_delete != null) {
  //     return null;
  //   } else if (_approve != null) {
  //     return null;
  //   } else if (_print != null) {
  //     return null;
  //   }
  // return <IndexView />;
  return accessRole("read") ? <IndexView /> : <ErrorAccess />;
};
export default RouteView;
