import React, { Fragment, Component } from "react";
import { withRouter } from "react-router";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import IndexBloc from "./Blocs/IndexBloc";
import Select from "react-select";
import apiResponse from "../../../../services/apiResponse";
import { Alert } from "reactstrap";

//COMPONENT
import IndexComponent from "./Components/IndexComponentNow";
import IndexComponent30Min from "./Components/IndexComponent30Min";
import IndexComponent1Hour from "./Components/IndexComponent1Hour";
import IndexComponent6Hour from "./Components/IndexComponent6Hour";
import IndexComponent1Day from "./Components/IndexComponent1Day";
import IndexComponent1Week from "./Components/IndexComponent1Week";
import IndexComponent1Month from "./Components/IndexComponent1Month";
import IndexComponent6Month from "./Components/IndexComponent6Month";
import IndexComponent1Year from "./Components/IndexComponent1Year";

class IndexView extends Component {
  indexBloc = new IndexBloc();

  constructor(props) {
    super(props);
    this.state = {
      detail: null,
      listNameHost: [],
      activeTab: "1",
      selectedHost: null,
      selectedPeriod: null,
      isLoading: true,
    };

    this.activeRef = React.createRef();
    this.inactiveRef = React.createRef();
  }

  reloadActiveRef = () => this.activeRef.current.setTokenAPI();
  reloadInactiveRef = () => this.inactiveRef.current.setTokenAPI();

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  handleServerChange = (selectedOption) => {
    this.setState({ selectedHost: selectedOption });
  };

  handlePeriodChange = (selectedOption) => {
    this.setState({ selectedPeriod: selectedOption });
  };

  componentDidMount() {
    this.indexBloc.getallHostChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          let response = result.data.response;
          const hostOptions = response.map((item) => ({
            value: item.hostid,
            label: item.name,
          }));

          // const defaultHost = hostOptions.find(
          //   (option) => option.label === "server-rancher-master"
          // );
          const defaultHost = hostOptions.length > 0 ? hostOptions[0] : null;
          this.setState({
            listNameHost: hostOptions,
            selectedHost: defaultHost || null,
            isLoading: false,
          });
          break;
        case apiResponse.ERROR:
          this.setState({ isLoading: false });
          break;
        default:
          break;
      }
    });

    this.indexBloc.getAllPeriodChannel.subscribe((result) => {
      switch (result.status) {
        case apiResponse.COMPLETED:
          let response = result.data.response;
          // console.log(response);

          const periodOptions = response.name.map((item) => ({
            value: item,
            label: item,
          }));

          const defaultPeriod = periodOptions.find(
            (option) => option.label === "5 Menit Terakhir"
          );

          this.setState({
            listNamePeriod: periodOptions,
            selectedPeriod: defaultPeriod || null,
            isLoading: false,
          });
          break;
        case apiResponse.ERROR:
          this.setState({ isLoading: false });
          break;
        default:
          break;
      }
    });

    this.indexBloc.fetchAllHosts({});
    this.indexBloc.fetchAllPeriods({});
  }

  render() {
    const { isLoading, selectedPeriod } = this.state;
    const renderTableView = () => {
      return (
        <>
          <Nav tabs>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: this.state.activeTab === "1",
                })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                <span className="d-block d-sm-none">
                  <i className="fas fa-home"></i>
                </span>
                <span className="d-none d-sm-block">
                  <span className="uil-wifi-router"></span> Lalu Lintas Jaringan
                </span>
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent
            activeTab={this.state.activeTab}
            className="p-3 text-muted"
          >
            {selectedPeriod && selectedPeriod.value === "5 Menit Terakhir" && (
              <TabPane tabId="1">
                <IndexComponent
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "30 Menit Terakhir" && (
              <TabPane tabId="1">
                <IndexComponent30Min
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "1 Jam Terakhir" && (
              <TabPane tabId="1">
                <IndexComponent1Hour
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "6 Jam Terakhir" && (
              <TabPane tabId="1">
                <IndexComponent6Hour
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "1 Hari Terakhir" && (
              <TabPane tabId="1">
                <IndexComponent1Day
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "1 Minggu Terakhir" && (
              <TabPane tabId="1">
                <IndexComponent1Week
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "1 Bulan Terakhir" && (
              <TabPane tabId="1">
                <IndexComponent1Month
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "6 Bulan Terakhir" && (
              <TabPane tabId="1">
                <IndexComponent6Month
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}

            {selectedPeriod && selectedPeriod.value === "1 Tahun Terakhir" && (
              <TabPane tabId="1">
                <IndexComponent1Year
                  ref={this.activeRef}
                  handleInactiveSuccess={this.reloadInactiveRef}
                  selectedHost={this.state.selectedHost}
                  selectedPeriod={this.state.selectedPeriod}
                />
              </TabPane>
            )}
          </TabContent>
        </>
      );
    };

    const renderView = () => {
      if (isLoading) {
        return (
          <div className="text-center">
            <div>
              <Spinner
                style={{ width: "3rem", height: "3rem", borderWidth: "0.4rem" }}
                color="dark"
              />
            </div>
            <div>
              <span style={{ fontWeight: "bold" }}>Mengambil Data ...</span>
            </div>
          </div>
        );
      } else {
        return renderTableView();
      }
    };
    return (
      <Fragment>
        <Container fluid>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Alert color="info">
                    <CardTitle>Antarmuka Jaringan</CardTitle>
                    <CardSubtitle>
                      Memastikan kinerja jaringan tetap optimal.
                      Mengidentifikasi pola penggunaan, mengukur kecepatan
                      transmisi, serta mendeteksi potensi kemacetan atau anomali
                      yang dapat mengganggu konektivitas atau menyebabkan
                      penurunan kinerja.
                    </CardSubtitle>
                  </Alert>
                  <hr />
                  <Row>
                    <Col md="3">
                      <label>Pilih Host : </label>
                      <br />
                      <Select
                        options={this.state.listNameHost}
                        placeholder="Pilih Host"
                        onChange={this.handleServerChange}
                        value={this.state.selectedHost}
                      />
                    </Col>

                    <Col md="3">
                      <label>Pilih Periode Waktu : </label>
                      <br />
                      <Select
                        options={this.state.listNamePeriod}
                        placeholder="Pilih Periode Waktu"
                        onChange={this.handlePeriodChange}
                        value={this.state.selectedPeriod}
                      />
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Card>
                      <CardBody>{renderView()}</CardBody>
                    </Card>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default withRouter(IndexView);
