import { apiRequest, roughUpdate, roughDetail } from "../../../../services/adapters/base";

class UpdateRepository {
  fetchUpdate = async (query) => {
    return await apiRequest(
      "post",
      roughUpdate,
      {
        body: query,
      },
      true
    );
  };

  fetchDetail = async (query) => {
    return await apiRequest(
      "get",
      roughDetail,
      {
        params: query,
      },
      true
    );
  };
}

export default UpdateRepository;
