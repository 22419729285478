import { apiRequest, helpdeskModuleList, helpdeskModuleDelete } from "../../../../services/adapters/base";

class DataTableRepository {
  fetchRowTable = async (query, cancelToken) => {
    return await apiRequest(
      "get",
      helpdeskModuleList,
      {
        cancelToken: cancelToken,
        params: query,
      },
      true
    );
  };

  fetchDeleteHelpdeskModule = async (query, cancelToken) => {
    return await apiRequest(
      "post",
      helpdeskModuleDelete,
      {
        cancelToken: cancelToken,
        body: query,
      },
      true
    );
  };
}

export default DataTableRepository;
