// import { accessRole } from "../../../../helpers/access_role"
import React, { Fragment } from "react"
// import { Link } from "react-router-dom"
import ErrorComingSoon from '../../../../components/Error/Content/ComengSoon'


import {
    Container,
    // Row, Col,
    // CardBody, Card, CardTitle, CardSubtitle,
    // Button
} from "reactstrap"


const IndexView = (props) => {

    return (
        <Fragment>
            <Container fluid>
                {/* <Row>
                    <Col md={12}>
                        <iframe style={{width:'100%',height:600}} sandbox="allow-same-origin" title="asa" src="https://bkpm.sharepoint.com/:x:/r/_layouts/15/Doc.aspx?sourcedoc=%7BA362A95B-193F-4A5B-ABB0-A30F5FA46D7A%7D&file=TPLN-202106.xlsx&action=default&mobileredirect=true" />
                    </Col>
                </Row> */}
                <ErrorComingSoon />
                {/* <Row>
                    <Col md={6} xl={3}>
                        <Card className="bg-success">
                            <CardBody>
                            <div>
                                <h4 className="text-white mb-1 mt-1"><span>Chart 1-2</span></h4>
                                <p className="text-white mb-0">-</p>
                            </div>
                            <p className="text-white mt-3 mb-0">-</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} xl={3}>
                        <Card className="bg-danger">
                            <CardBody>
                            <div>
                                <h4 className="text-white mb-1 mt-1"><span>Chart 1-2</span></h4>
                                <p className="text-white mb-0">-</p>
                            </div>
                            <p className="text-white mt-3 mb-0">-</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} xl={3}>
                        <Card>
                            <CardBody>
                            <div className="float-end mt-2">
                                
                            </div>
                            <div>
                                <h4 className="mb-1 mt-1"><span>Chart 2-3</span></h4>
                                <p className="text-muted mb-0">-</p>
                            </div>
                            <p className="text-muted mt-3 mb-0">-</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={6} xl={3}>
                        <Card>
                            <CardBody>
                            <div className="float-end mt-2">
                                
                            </div>
                            <div>
                                <h4 className="mb-1 mt-1"><span>Chart 2-4</span></h4>
                                <p className="text-muted mb-0">-</p>
                            </div>
                            <p className="text-muted mt-3 mb-0">-</p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} xl={8}>
                        <Card>
                            <CardBody>
                            <div>
                                <h4 className="mb-1 mt-1"><span>Chart 1-1</span></h4>
                                <p className="text-muted mb-0">-</p>
                            </div>
                            <p className="text-muted mt-3 mb-0">-</p>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={12} xl={4}>
                        <Card className="bg-primary">
                            <CardBody>
                            <div>
                                <h4 className="text-white mb-1 mt-1"><span>Chart 1-2</span></h4>
                                <p className="text-white mb-0">-</p>
                            </div>
                            <p className="text-white mt-3 mb-0">-</p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>  
                    <Col className="col-12">
                        <Card>
                            <CardBody>
                                <div className="float-end">
                                {
                                    (accessRole('create')) ?
                                        <Link to="/sample.html?create">
                                            <Button color="primary">New Sample</Button>
                                        </Link>
                                    : null
                                }
                                </div>
                                <CardTitle>Index Sample</CardTitle>
                                <CardSubtitle className="mb-3">
                                    Keterangan menu-menu. Lorem ipsum dolor sit amet...
                                </CardSubtitle>
                                <hr />
                                Hai
                            </CardBody>
                        </Card>
                    </Col>
                </Row> */}
            </Container>
        </Fragment>
    )
}

export default IndexView