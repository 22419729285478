import { apiRequest, serviceFeatureCreate, serviceList } from "../../../../services/adapters/base";

class CreateRepository {
  fetchCreate = async (query) => {
    return await apiRequest(
      "post",
      serviceFeatureCreate,
      {
        body: query,
      },
      true
    );
  };

  fetchPortalServices = async (query) => {
    return await apiRequest(
      "get",
      serviceList,
      {
        body: query,
      },
      true
    );
  };
}

export default CreateRepository;
